import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BonosModel } from '../models/Bonos.model';
import { map} from 'rxjs/operators' ;
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { JsonPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { ClientesModel } from '../models/Clientes.model';


@Injectable({
  providedIn: 'root'
})
export class BonosService {
  userToken: string;
  idUsuario: string;
  url: string;
  apiurl: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.apiurl = environment.apiurl;
  }


  getBono(id) {
    let endpoint =  '/bonos/'+id;
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(map( resp => {
      return Object.values(resp);
    } ));
  }

  getSaldoactual(id) {
    let endpoint =  '/bonos/saldoactual/'+id;
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(map( resp => {
      return Object.values(resp);
    } ));
  }

  getListaBonos() {
    let endpoint =  '/bonos';
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(map( resp => {
      return resp as BonosModel[];
    } ));
  }


  getBonoLocal(){

    var cantidad = "0";
    //pasar json de pedido a modelo Pedido
 
    if (localStorage.getItem('bono')) {
      cantidad = localStorage.getItem('bono');
    }
    
    return cantidad;
  }


  saveBonoLocal(bonos: BonosModel[]){
    //create update localstorage
    let saldo = 0;
    bonos.forEach( (el)=>{
      saldo += parseFloat(el.cantidad);  
    })
    let saldobono = saldo.toString();
    localStorage.setItem('bono', saldobono);

  }



  



}

