import { StringifyOptions } from 'querystring';
import { NodeCompatibleEventEmitter } from 'rxjs/internal/observable/fromEvent';

export class FilesModel {
    
    contenttype: string;
    firebase_id: string;
    firebase_path: string;
    firebase_url: string;
    id: number;
    originalname: string;
    pages: number;
    size: number;
    descargado: string;
    productospedidos_id: number;
    tipofiles_id: number;
    archivo: File;
  
}