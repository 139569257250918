import { Component, OnInit, OnDestroy, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ClientesModel } from 'src/app/models/Clientes.model';
import { FiltrosModel } from 'src/app/models/Filtros.model';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable } from 'rxjs';
import { ListasService } from '../../services/listas.service';
import { ClientesService } from '../../services/clientes.service';
import { CartService } from '../../services/cart.service';
import { ProductoModel } from 'src/app/models/Producto.model';
import { HistoricoModel } from 'src/app/models/Historico.model';
import { PedidosModel } from 'src/app/models/Pedidos.model';

@Component({
  selector: 'app-historicopedidos',
  templateUrl: './historicopedidos.component.html',
  styleUrls: ['./historicopedidos.component.css']
})
export class HistoricopedidosComponent implements OnInit {

  @Input() historicos: HistoricoModel[];

  ocultarH: string;
  pedidoscliente: HistoricoModel[] = [];
  udsx = [];

  constructor(
      private router: Router,
      private cartService: CartService,
      private clientesService: ClientesService,

  ) 
  { 
    //
  }

  ngOnInit() {
    this.ocultarH = ""
    this.getpedidoscliente();
   
  }
    

  ngOnDestroy() {
    //
  }


  ocultarmenuH() {
    this.ocultarH = "";
  }
 
  menuH() {
    this.ocultarH == "" ? this.ocultarH = "active" :  this.ocultarH = "" ;
  }


  getpedidoscliente() {
    
    let clientelocal = this.clientesService.getClienteLocal();
    if(clientelocal != null){
      
      this.cartService.getHistoricopedidos(clientelocal.id)
      .subscribe( (resp: HistoricoModel[]) => {
          this.pedidoscliente = resp ;
      });
    }
  }

  


 




  
}
