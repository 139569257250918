import { Component, OnInit } from '@angular/core';
import { ClientesModel } from '../../models/Clientes.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ListasService } from '../../services/listas.service';
import { AlertasService } from '../../services/alertas.service';
import { ClientesService } from '../../services/clientes.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-forbidd',
  templateUrl: './forbidd.component.html',
  styleUrls: ['./forbidd.component.css']
})
export class ForbiddComponent implements OnInit {
  
  modal: NgbModalRef;
  modalOptions: NgbModalOptions;
  forma: FormGroup;
  formaR: FormGroup;
  cliente: ClientesModel;
  btactivado;
  btactivadoR;
  emailforbiddsel: string;
  passwordforbiddsel: string;
  codigoforbiddsel: string;
  emailRsel: string;
  pedirrenovar: boolean;

  constructor(
    private auth: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private fbR: FormBuilder,
    private modalService: NgbModal,
    private alertasService: AlertasService,
    private clientesService: ClientesService
  ) 
  { 
    this.cliente = new ClientesModel();
    this.pedirrenovar = false;
    this.resetear();
    this.emailRsel = "";
  }

  ngOnInit() {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      centered: true,
      size: 'lg'
    }

    this.cliente = this.clientesService.getClienteLocal();
    this.btactivado = false;
    this.btactivadoR = false;
    
    this.crearFormulario();
    this.cambiosFormulario();
    this.crearFormularioR();
    this.cambiosFormularioR();
    
  }

  //validaciones rojo
  
  get novalidoPassword() { return this.forma.get('password').invalid && this.forma.get('password').touched ; }
  get novalidoCodigo() { return this.forma.get('codigomail').invalid && this.forma.get('codigomail').touched ; }
  get novalidoEmailR() { return this.formaR.get('emailR').invalid && this.formaR.get('emailR').touched ; }

  crearFormulario() {
    this.forma = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(1)]],
      codigomail: ['', [Validators.required, Validators.minLength(1)]]
    });
  }


  cambiosFormulario() {

    ///// statuChanges
    this.forma.valueChanges.subscribe( value => {
        this.btactivado = false;
        this.cliente.password = this.forma.get('password').value ;
        this.cliente.codigomail = this.forma.get('codigomail').value ;

        if ( this.forma.status != "INVALID") {
          this.btactivado = true ;
        }

    });
  }

  
  crearFormularioR() {
    this.formaR = this.fbR.group({
      emailR:  [ '' , [Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]]
    });
  }


  cambiosFormularioR() {

    ///// statuChanges
    this.formaR.valueChanges.subscribe( value => {
        this.btactivadoR = false;
        this.cliente.email  = this.formaR.get('emailR').value ;
        if ( this.formaR.status != "INVALID") {
          this.btactivadoR = true ;
        }
    });
  }


  entrar() {
    if (!this.forma.invalid) {
        this.auth.newpass(this.cliente)
        .subscribe( resp => {
           if (resp) {   
              this.cerrarmodalforbidd();
            }
            else {
              this.alertasService.alertaKO("CODIGO INCORRECTO","pruebe de nuevo");
            }
        },
        (err) => {
          this.alertasService.alertaKO("NO ENCONTRADO","pruebe de nuevo");
        });
    }
 }


  cerrarmodalforbidd(){
    this.resetear();
    this.pedirrenovar = false;
    this.emailforbiddsel = "";
    this.modal.dismiss();
  }

  resetear(){
    this.emailRsel = "";
    this.passwordforbiddsel = "";
    this.codigoforbiddsel = "";
  }

  forbidd() {
    document.getElementById('btmodalforbidd').click();
  }

  forbiddopen(content) {
    this.modal = this.modalService.open(content, this.modalOptions);
  }

  renovar(){
    
    if (!this.formaR.invalid) {
    this.auth.forbidd(this.cliente.email)
        .subscribe( resp => {
            this.alertasService.alertaPeq("SE HA ENVIADO UN CODIGO A SU MAIL");
            this.pedirrenovar = true;
            this.emailforbiddsel = this.cliente.email;
            
        },
        (err) => {
          this.alertasService.alertaKO("NO ENCONTRADO","pruebe de nuevo");
        });
      }
  }

  


}
