import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PersonasModel } from '../models/Personas.model';
import { map} from 'rxjs/operators' ;
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { JsonPipe } from '@angular/common';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ListasService {
  userToken: string;
  idUsuario: string;
  url: string;
  apiurl: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.apiurl = environment.apiurl;
  }


  getCategorias() {
    this.userToken = this.auth.leerToken();
    let endpoint = '/categoriasservicios' ;
    this.url = this.apiurl + endpoint;
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.get( `${this.url}`, { headers}  )
    .pipe(map( resp => {
        return  resp;
    } ));
  }


  getEstados() {
    this.userToken = this.auth.leerToken();
    let endpoint = '/pedidos/estados' ;
    this.url = this.apiurl + endpoint;
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.get( `${this.url}`, { headers}  )
    .pipe(map( resp => {
        return  resp;
    } ));
  }


  getProductos() {
    this.userToken = this.auth.leerToken();
    let endpoint = '/productos' ;
    this.url = this.apiurl + endpoint;
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.get( `${this.url}`, { headers }  )
    .pipe(map( resp => {
   
        return  resp;
    } ));
  }



  getPersonas() {
    this.userToken = this.auth.leerToken();
    let endpoint =  '/personas';
    this.url = this.apiurl + endpoint ;
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.get( `${this.url}`, { headers }  )
    .pipe(map( resp => {
        return resp;
    } ));
  }


  getRoles() {
    this.userToken = this.auth.leerToken();
    let endpoint = '/roles' ;
    this.url = this.apiurl + endpoint;
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.get( `${this.url}`, { headers}  )
    .pipe(map( resp => {
        return  resp;
    } ));
  }



getServicios() {
  this.userToken = this.auth.leerToken();
  let endpoint = '/servicios' ;
  this.url = this.apiurl + endpoint;
  const headers = new HttpHeaders({ 'Authorization': this.userToken });
  return this.http.get( `${this.url}`, { headers}  )
  .pipe(map( resp => {
      return  resp;
  } ));
}


getProveedores() {
  this.userToken = this.auth.leerToken();
  let endpoint = '/proveedores' ;
  this.url = this.apiurl + endpoint;
  const headers = new HttpHeaders({ 'Authorization': this.userToken });
  return this.http.get( `${this.url}`, { headers }  )
  .pipe(map( resp => {
      return  resp;
  } ));
}

getCategoriasProductos() {
  this.userToken = this.auth.leerToken();
  let endpoint = '/categoriasproductos' ;
  this.url = this.apiurl + endpoint;
  const headers = new HttpHeaders({ 'Authorization': this.userToken });
  return this.http.get( `${this.url}`, { headers }  )
  .pipe(map( resp => {
      return  resp;
  } ));
}

getStocks() {
  this.userToken = this.auth.leerToken();
  let endpoint = '/stocks' ;
  this.url = this.apiurl + endpoint;
  const headers = new HttpHeaders({ 'Authorization': this.userToken });
  return this.http.get( `${this.url}`, { headers }  )
  .pipe(map( resp => {
      return  resp;
  } ));
}

getEntradas() {
  this.userToken = this.auth.leerToken();
  let endpoint = '/entradas' ;
  this.url = this.apiurl + endpoint;
  const headers = new HttpHeaders({ 'Authorization': this.userToken });
  return this.http.get( `${this.url}`, { headers }  )
  .pipe(map( resp => {
      return  resp;
  } ));
}

getRoturas() {
  this.userToken = this.auth.leerToken();
  let endpoint = '/roturas' ;
  this.url = this.apiurl + endpoint;
  const headers = new HttpHeaders({ 'Authorization': this.userToken });
  return this.http.get( `${this.url}`, { headers }  )
  .pipe(map( resp => {
      return  resp;
  } ));
}

getFinishings() {
  let endpoint = '/productospedidos/finishings' ;
  this.url = this.apiurl + endpoint;
  return this.http.get( `${this.url}` )
  .pipe(map( resp => {
      return  resp
  } ));
}

getPrintsides() {
  let endpoint = '/productospedidos/printsides' ;
  this.url = this.apiurl + endpoint;
  return this.http.get( `${this.url}` )
  .pipe(map( resp => {
      return  resp;
  } ));
}

getPapercolors() {
  let endpoint = '/productospedidos/papercolors' ;
  this.url = this.apiurl + endpoint;
  return this.http.get( `${this.url}`)
  .pipe(map( resp => {
      
      return  resp;
  } ));
}

getPagesizes() {
  let endpoint = '/productospedidos/pagesizes' ;
  this.url = this.apiurl + endpoint;
  return this.http.get( `${this.url}` )
  .pipe(map( resp => {
      return  resp;
  } ));
}

getPaperweights() {
  let endpoint = '/productospedidos/paperweights' ;
  this.url = this.apiurl + endpoint;
  return this.http.get( `${this.url}` )
  .pipe(map( resp => {
      
      return  resp;
  } ));
}

getPrintform() {
  let endpoint = '/productospedidos/printforms' ;
  this.url = this.apiurl + endpoint;
  return this.http.get( `${this.url}` )
  .pipe(map( resp => {
      
      return  resp;
  } ));
}

getPageorientations() {
  let endpoint = '/productospedidos/pageorientations' ;
  this.url = this.apiurl + endpoint;
  return this.http.get( `${this.url}`)
  .pipe(map( resp => {
      return  resp;
  } ));
}

getPagesheets() {
  let endpoint = '/productospedidos/pagesheets' ;
  this.url = this.apiurl + endpoint;
  return this.http.get( `${this.url}`)
  .pipe(map( resp => {
      return  resp;
  } ));
}

getColores() {
  let endpoint = '/productospedidos/colores' ;
  this.url = this.apiurl + endpoint;
  return this.http.get( `${this.url}`)
  .pipe(map( resp => {
      return  resp;
  } ));
}

getEncuadernardocs() {
  let endpoint = '/productospedidos/encuadernardocs' ;
  this.url = this.apiurl + endpoint;
  return this.http.get( `${this.url}`)
  .pipe(map( resp => {
      return  resp;
  } ));
}

getDatoseasycopy() {
  let endpoint = '/productospedidos/datoseasycopy' ;
  this.url = this.apiurl + endpoint;
  return this.http.get( `${this.url}`)
  .pipe(map( resp => {
      return  resp;
  } ));
}


 

}
