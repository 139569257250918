import { StringifyOptions } from 'querystring';
import { NodeCompatibleEventEmitter } from 'rxjs/internal/observable/fromEvent';
import { BonosModel } from './Bonos.model';

export class ClientesModel {
    id: number;
    edad: string;
    sexo: string;
    password: string;
    password2: string;
    fecha: string;
    pais: string;
    
    nombre: string;
    email: string;
    telefono: string;
    empresa: string;
    CIFNIF: string;
    
    cpostalenvio: string;
    direccionenvio: string;
    viaenvio: string;
    numeroenvio: string;
    puertaenvio: string;
    pisoenvio: string;
    localidadenvio: string;
    provinciaenvio: string;
    cpostalfacturacion: string;
    direccionfacturacion: string;
    viafacturacion: string;
    numerofacturacion: string;
    puertafacturacion: string;
    pisofacturacion: string;
    localidadfacturacion: string;
    provinciafacturacion: string;
    metodopago: string;
    aceptapoliticas:boolean;
    aceptapublicidad: boolean;
    isadmin: string;

    apitoken: string;
    apitokenexpiration: Date;

    codigomail: string;
    bonospagados: BonosModel[];

    constructor(){

        this.nombre = "";
        this.email = "";
        this.telefono = "";
        this.empresa = "";
        this.CIFNIF = "";
        this.isadmin = "F";
        this.cpostalenvio = "";
        this.direccionenvio = "";
        this.viaenvio = "";
        this.numeroenvio = "";
        this.puertaenvio = "";
        this.pisoenvio = "";
        this.localidadenvio = "";
        this.provinciaenvio = "";
        this.cpostalfacturacion= "";
        this.direccionfacturacion = "";
        this.viafacturacion = "";
        this.numerofacturacion = "";
        this.puertafacturacion = "";
        this.pisofacturacion = "";
        this.localidadfacturacion = "";
        this.provinciafacturacion = "";
        this.metodopago = "1";
        this.codigomail = "";
            
    }

}
