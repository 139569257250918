import { Component, OnInit } from '@angular/core';
import { ClientesModel } from '../../models/Clientes.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ListasService } from '../../services/listas.service';
import { AlertasService } from '../../services/alertas.service';
import { ClientesService } from '../../services/clientes.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {
  

  modal: NgbModalRef;
  modalOptions: NgbModalOptions;
  forma: FormGroup;
  cliente: ClientesModel;
  btactivado;
  emailperfilsel: string;

  constructor(
    private auth: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private alertasService: AlertasService,
    private clientesService: ClientesService
  ) 
  { 
    this.cliente = new ClientesModel();
    
  }

  ngOnInit() {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      centered: true,
      size: 'lg'
    }

    this.cliente = this.clientesService.getClienteLocal();
    this.btactivado = false;
    this.resetear();
    this.crearFormulario();
    this.cambiosFormulario();
  }

  //validaciones rojo
  get novalidoNombre() { return this.forma.get('nombreperfil').invalid && this.forma.get('nombreperfil').touched ; }
  get novalidoEmail() { return this.forma.get('emailperfil').invalid && this.forma.get('emailperfil').touched ; }
  get novalidoCpostal() { return this.forma.get('cpostalperfil').invalid && this.forma.get('cpostalperfil').touched ; }
  get novalidoDireccion() { return this.forma.get('direccionperfil').invalid && this.forma.get('direccionperfil').touched ; }
  get novalidoTelefono() { return this.forma.get('telefonoperfil').invalid && this.forma.get('telefonoperfil').touched ; }
  get novalidoLocalidad() { return this.forma.get('localidadperfil').invalid && this.forma.get('localidadperfil').touched ; }
  get novalidoProvincia() { return this.forma.get('provinciaperfil').invalid && this.forma.get('provinciaperfil').touched ; }
  get novalidoVia() { return this.forma.get('viaperfil').invalid && this.forma.get('viaperfil').touched ; }
  get novalidoNumero() { return this.forma.get('numeroperfil').invalid && this.forma.get('numeroperfil').touched ; }
  get novalidoPiso() { return this.forma.get('pisoperfil').invalid && this.forma.get('pisoperfil').touched ; }


  crearFormulario() {
    this.forma = this.fb.group({
      nombreperfil: [ this.cliente.nombre  , [Validators.required, Validators.minLength(1)]],
      emailperfil: [ this.cliente.email , [Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      cpostalperfil: [ this.cliente.cpostalenvio , [Validators.required, Validators.pattern(/^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/)]],
      direccionperfil: [this.cliente.direccionenvio, [Validators.required]],
      viaperfil: [this.cliente.viaenvio, [Validators.required]],
      numeroperfil: [this.cliente.numeroenvio, [Validators.required]],
      pisoperfil: [this.cliente.pisoenvio, [Validators.required]],
      telefonoperfil: [ this.cliente.telefono , [Validators.required,Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,5}$/)  ]],
      provinciaperfil: [this.cliente.provinciaenvio, [Validators.required]],
      localidadperfil: [this.cliente.localidadenvio, [Validators.required]],
      empresaperfil: [this.cliente.empresa],
      paisperfil: [this.cliente.pais],
      CIFNIFperfil: [ this.cliente.CIFNIF , [Validators.pattern(/^((([X-Z])|([LM])){1}([-]?)((\d){7})([-]?)([A-Z]{1}))|((\d{8})([-]?)([A-Z]))$/)]]

    },
    {
      //
    });
  }




  cambiosFormulario() {

    ///// statuChanges
    this.forma.valueChanges.subscribe( value => {
        // this.btactivado = false;
        this.cliente.nombre  = this.forma.get('nombreperfil').value ;
        this.cliente.email  = this.forma.get('emailperfil').value ;
        this.cliente.telefono = this.forma.get('telefonoperfil').value ;
        this.cliente.cpostalenvio = this.forma.get('cpostalperfil').value ;
        this.cliente.cpostalfacturacion = this.forma.get('cpostalperfil').value ;
        this.cliente.direccionenvio = this.forma.get('direccionperfil').value ;
        this.cliente.viaenvio = this.forma.get('viaperfil').value ;
        this.cliente.numeroenvio = this.forma.get('numeroperfil').value ;
        this.cliente.pisoenvio = this.forma.get('pisoperfil').value ;
        this.cliente.direccionfacturacion = this.forma.get('direccionperfil').value ;
        this.cliente.viafacturacion = this.forma.get('viaperfil').value ;
        this.cliente.numerofacturacion = this.forma.get('numeroperfil').value ;
        this.cliente.pisofacturacion = this.forma.get('pisoperfil').value ;
        this.cliente.localidadenvio = this.forma.get('localidadperfil').value ;
        this.cliente.localidadfacturacion = this.forma.get('localidadperfil').value ;
        this.cliente.provinciaenvio = this.forma.get('provinciaperfil').value ;
        this.cliente.provinciafacturacion = this.forma.get('provinciaperfil').value ;
        this.cliente.empresa = this.forma.get('empresaperfil').value ;
        this.cliente.CIFNIF = this.forma.get('CIFNIFperfil').value ;
        this.cliente.pais = this.forma.get('paisperfil').value ;

        if ( this.forma.status != "INVALID") {
          this.btactivado = true ;
        }

    });
  }


  modificar() {
      if (!this.forma.invalid) {
            
            this.clientesService.modificarCliente(this.cliente)
            .subscribe( resp => {
              if(resp){
                let clienteresp = resp as ClientesModel;
                clienteresp.password = "*";
                this.cerrarmodalperfil();
                this.clientesService.saveCliente(clienteresp);
                this.clientesService.getClienteLocal();
                this.alertasService.alertaOK("USUARIO MODIFICADO","se ha actualizado en la app")
                .then();
              }
            }, (err) => {
              this.alertasService.alertaKO("NO MODIFICADO","compruebe el formulario").then();
            })
      }
  }


  cerrarmodalperfil(){
    this.resetear();
    this.modal.dismiss();
  }

  resetear(){
    this.emailperfilsel = "";
  }

  perfil() {
    document.getElementById('btmodalperfil').click();
  }

  perfilopen(content) {
    this.modal = this.modalService.open(content, this.modalOptions);
  }

  


}
