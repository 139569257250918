import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductoModel } from '../models/Producto.model';
import { map} from 'rxjs/operators' ;
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { JsonPipe } from '@angular/common';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ProductosService {
  userToken: string;
  idUsuario: string;
  url: string;
  apiurl: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.apiurl = environment.apiurl;
  }


  crearProducto( producto: ProductoModel ) {
    this.userToken = this.auth.leerToken();
    let endpoint =  '/productos';
    this.url = this.apiurl + endpoint;
    producto.imagen = "https://app.easycopy.es/public/images/sinimagen.png";
    const productoData = {
      
      nombre:  producto.nombre,
      precio: producto.precio,
      descripcion: producto.descripcion,
      imagen: producto.imagen

    };

    const headers = new HttpHeaders ({
      'Authorization': this.userToken
    });

    return this.http.post( `${this.url}`, productoData, {headers} );

  }

  modificarProducto( producto: ProductoModel ) {
    this.userToken = this.auth.leerToken();
    let endpoint =  '/productos/modificar' ;
    this.url = this.apiurl + endpoint;

    const productoData = {
      id: producto.id,
      nombre:  producto.nombre,
      precio: producto.precio,
      descripcion: producto.descripcion,
      imagen: producto.imagen

    };

    const headers = new HttpHeaders ({
      'Authorization': this.userToken
    });
    return this.http.post( `${this.url}`, productoData, {headers} );
    
  }


  getProducto(id) {
    let endpoint =  '/productos/'+id;
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(map( resp => {
      return Object.values(resp);
    } ));
  }

  getListaProductos() {
    let endpoint =  '/productos';
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(map( resp => {
      return resp;
    } ));
  }


  getProductosFilt(nombr :string) {
    this.userToken = this.auth.leerToken();
    let endpoint = '/productos/filt' ;
    this.url = this.apiurl + endpoint;
    const filtData = {
      nombre: nombr
    };
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.post( `${this.url}`, filtData,  { headers }  )
    .pipe(map( resp => {
        return  resp;
    } ));
  }

  getCategoriasProductos() {
    this.userToken = this.auth.leerToken();
    let endpoint = '/categoriasproductos' ;
    this.url = this.apiurl + endpoint;
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.get( `${this.url}`, { headers}  )
    .pipe(map( resp => {
        return  resp;
    } ));
  }

  getProveedores() {
    this.userToken = this.auth.leerToken();
    let endpoint = '/proveedores' ;
    this.url = this.apiurl + endpoint;
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.get( `${this.url}`, { headers}  )
    .pipe(map( resp => {
        return  resp;
    } ));
  }


  deleteProducto(id) {
    this.userToken = this.auth.leerToken();
    let endpoint = '/productos/eliminar/' + id ;
    this.url = this.apiurl +  endpoint ;
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.get( `${this.url}`, { headers }  )
    .pipe(map( resp => {
        return  resp;
    } ));
  }


  uploadFile( id, filetoupload: File ) {
    this.userToken = this.auth.leerToken();
    let endpoint = '/productos/imagen/' ;
    this.url = this.apiurl +  endpoint ;

    var formData: FormData = new FormData();
    formData.append('image', filetoupload, filetoupload.name);
    formData.append('id', id);
  
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.post( `${this.url}`, formData , { headers }  )
    .pipe(map( resp => {
        return  resp;
    } ));

  }

  



}

