import { Component, OnInit, OnDestroy, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ClientesModel } from 'src/app/models/Clientes.model';
import { FiltrosModel } from 'src/app/models/Filtros.model';
import { ProductosService } from '../../services/productos.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable } from 'rxjs';
import { ListasService } from '../../services/listas.service';
import { CartService } from '../../services/cart.service';
import { ProductoModel } from 'src/app/models/Producto.model';


@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.css']
})
export class CarritoComponent implements OnInit {

  @Input() numproductos: number;

  cartok: boolean;

  constructor(
      private router: Router,
      private productosService: ProductosService,
      private cartService: CartService
  ) 
  { 

  }

  ngOnInit() {
    this.getNumProductosCarrito();
    this.cartok = false;
    var cart = this.cartService.getCart();
    if( cart.cliente != ""  && cart.productospedidos != null ){
      this.cartok = true;
    }

  }
    

  ngOnDestroy() {

  }

  carrito() {
    this.router.navigate(['/checkoutcliente']);
  } 

  public getNumProductosCarrito(){
    var cart = this.cartService.getCart();
    if(cart.productospedidos != null){
      this.numproductos = cart.productospedidos.length;
    }
  }
  

 




}
