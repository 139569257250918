import { StringifyOptions } from 'querystring';
import { NodeCompatibleEventEmitter } from 'rxjs/internal/observable/fromEvent';


export class PersonasModel{
    
    id: number;
    nombre: string;
    email: string;
    isadmin: string;
    telefono: string;
    observaciones: string;
    passw: string;
    
    imagen: string;

    constructor(){

        this.nombre = "";
        this.email= "";
        this.telefono= "";
        this.isadmin= "T";

    }
}