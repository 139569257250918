import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nubeabajo',
  templateUrl: './nubeabajo.component.html',
  styleUrls: ['./nubeabajo.component.css']
})
export class NubeabajoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
