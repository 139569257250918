import { StringifyOptions } from 'querystring';
import { NodeCompatibleEventEmitter } from 'rxjs/internal/observable/fromEvent';

export class PreciosModel {
    id: number;
    nombre: string;
    valor: number;
    secciones_id: string;
    orden: string;
    seccion: string;
    precios_id: string;
    

}