import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductoModel } from '../models/Producto.model';
import { FacturasModel } from '../models/Facturas.model';
import { map} from 'rxjs/operators' ;
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { JsonPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { FilesModel } from '../models/Files.model';



@Injectable({
  providedIn: 'root'
})
export class FacturasService {
  userToken: string;
  idUsuario: string;
  url: string;
  apiurl: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.apiurl = environment.apiurl;
  }


  guardarFactura( factura: FacturasModel ) {
    
    let endpoint =  '/Facturas/create';
    this.url = this.apiurl + endpoint;
    
    const productoData = {
      
      pedidos_id: factura.pedidos_id,
      fecha: factura.fecha,
      direccionfactura: factura.direccionfacturacion,
      localidadfactura: factura.localidadfacturacion,
      provinciafactura: factura.provinciafacturacion,
      cpostalfactura: factura.cpostalfacturacion,
      email: factura.email,
      nombre: factura.nombre,
      telefono: factura.telefono,
      empresa: factura.empresa,
      CIFNIF: factura.CIFNIF,
      
    

    };

    return this.http.post( `${this.url}`, productoData);
  }


  modificarfactura( factura: FacturasModel ) {
    this.userToken = this.auth.leerToken();
    let endpoint =  '/Facturas/modificar' ;
    this.url = this.apiurl + endpoint;
    const productoData = {

      pedidos_id: factura.pedidos_id,
      fecha: factura.fecha,
      direccionfactura: factura.direccionfacturacion,
      localidadfactura: factura.localidadfacturacion,
      provinciafactura: factura.provinciafacturacion,
      cpostalfactura: factura.cpostalfacturacion,
      email: factura.email,
      nombre: factura.nombre,
      telefono: factura.telefono,
      empresa: factura.empresa,
      CIFNIF: factura.CIFNIF
      

    };

    const headers = new HttpHeaders ({
      'Authorization': this.userToken
    });
    return this.http.post( `${this.url}`, productoData, {headers} );
    
  }


  getFactura(id) {
    let endpoint =  '/facturas/'+id;
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(map( resp => {
      return resp as FacturasModel;
    } ));
  }
 
  getFacturaCodigo(id) {
    this.userToken = this.auth.leerToken();
    let endpoint =  '/facturas/codigo/'+id;
    this.url = this.apiurl + endpoint ;
    const headers = new HttpHeaders({ 'Authorization': this.userToken });

    return this.http.get( `${this.url}`, {headers})
    .pipe(map( resp => {
      return resp;
    } ));
  }

  getFacturaByCodigo(codigo) {
    let endpoint =  '/facturas/facturabycodigo/'+codigo;
    this.url = this.apiurl + endpoint ;
    
    return this.http.get( `${this.url}` )
    .pipe(map( resp => {
      return resp;
    } ));
  }
 

  getListaFacturas() {
    let endpoint =  '/facturas';
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(map( resp => {
      return resp;
    } ));
  }


  getFacturasFilt(fechai :string, fechaf :string, nombreb :string) {
    this.userToken = this.auth.leerToken();
    let endpoint = '/facturas/filt' ;
    this.url = this.apiurl + endpoint;
    const filtData = {
      fechaini: fechai,
      fechafin: fechaf,
      nombre: nombreb,
    };
    
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.post( `${this.url}`, filtData, {headers} )
    .pipe(map( resp => {
        return  resp;
    } ));
  }



}

