import { StringifyOptions } from 'querystring';
import { NodeCompatibleEventEmitter } from 'rxjs/internal/observable/fromEvent';


export class  EnviosModel{
    id: number;
    pedidos_id: string;
    fecha: string;

    direccionenvio: string;
    viaenvio: string;
    numeroenvio: string;
    pisoenvio: string;
    localidadenvio: string;
    provinciaenvio: string;
    cpostalenvio: string;
    email: string;
    nombre: string; 
    ship: string;
    tipoenvio: string;
    telefono: string;
    logistica: string;
    
}