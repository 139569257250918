import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';

import { HomeadminComponent } from './pages/homeadmin/homeadmin.component';
import { CheckoutclienteComponent } from './pages/checkoutcliente/checkoutcliente.component';
import { CartadminComponent } from './pages/cartadmin/cartadmin.component';
import { EnviosadminComponent } from './pages/enviosadmin/enviosadmin.component';
import { FacturasadminComponent } from './pages/facturasadmin/facturasadmin.component';
import { ClientesadminComponent } from './pages/clientesadmin/clientesadmin.component';
import { PreciosadminComponent } from './pages/preciosadmin/preciosadmin.component';

import { HomeclienteComponent } from './pages/homecliente/homecliente.component';
import { HistoricoclienteComponent } from './pages/historicocliente/historicocliente.component';
import { RegistroComponent } from './components/registro/registro.component';
import { FacturasclienteComponent } from './pages/facturascliente/facturascliente.component';

const routes: Routes = [
  
  
  { path: 'facturascliente/:codigo' , component: FacturasclienteComponent  },
  { path: 'homeadmin' , component: HomeadminComponent, canActivate: [AuthGuard] },// canActivate: [AuthGuard] },
  { path: 'enviosadmin' , component: EnviosadminComponent, canActivate: [AuthGuard] },
  { path: 'clientesadmin' , component: ClientesadminComponent, canActivate: [AuthGuard] },
  { path: 'preciosadmin' , component: PreciosadminComponent, canActivate: [AuthGuard] },
  { path: 'facturasadmin' , component: FacturasadminComponent, canActivate: [AuthGuard] },
  { path: 'cartadmin' , component: CartadminComponent, canActivate: [AuthGuard] },
  { path: 'homecliente/:id' , component: HomeclienteComponent},
  { path: 'homecliente' , component: HomeclienteComponent},
  { path: 'checkoutcliente' , component: CheckoutclienteComponent},
  { path: 'checkoutcliente/pago/:pago' , component: CheckoutclienteComponent},
  { path: 'registro' , component: RegistroComponent },
  { path: 'login' , component: LoginComponent },
  { path: '**' , redirectTo: 'homecliente' }

];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
