import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { ServiciosService } from "../../services/servicios.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { resetCompiledComponents } from "@angular/core/src/render3/jit/module";
import { ListasService } from "../../services/listas.service";
import { AlertasService } from "../../services/alertas.service";
import { EnviosModel } from "src/app/models/Envios.model";
import { EnviosService } from "src/app/services/envios.service";
import { ClientesService } from "src/app/services/clientes.service";
import { CartService } from "src/app/services/cart.service";
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";

import * as XLSX from "xlsx" ;


@Component({
  selector: "app-enviosadmin",
  templateUrl: "./enviosadmin.component.html",
  styleUrls: ["./enviosadmin.component.css"],
})
export class EnviosadminComponent implements OnInit {
  @Output() menuAdmin: EventEmitter<any> = new EventEmitter();

  forma: FormGroup;
  modal: NgbModalRef;
  modalOptions: NgbModalOptions;
  
  btactivado: boolean;
  isAdmin: boolean;
  pagi: number = 1;

  enviosadmin: EnviosModel[];
  envio: EnviosModel;

  formaF: FormGroup;
  fechainifiltrosel: string;
  fechafinfiltrosel: string;
  btactivadoF;

  idsel;
  pedidos_idsel;
  fechasel: string;
  clientenombresel: string;
  clienteemailsel: string;
  clientedireccionenviosel: string;
  clienteviaenviosel: string;
  clientenumeroenviosel: string;
  clientepisoenviosel: string;
  clientelocalidadenviosel: string;
  clienteprovinciaenviosel: string;
  clientecpostalenviosel: string;
  shipsel: string;
  tipoenviosel: string;
  telefonosel: string;

  fechainicioexcel: string;
  fechafinexcel: string;

  cajasexcel = [];
  loading: boolean;

  constructor(
    private ro: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private alertasService: AlertasService,
    private listasService: ListasService,
    private enviosService: EnviosService,
    private clientesService: ClientesService,
    private cartService: CartService
  ) {
    this.loading = false;
    this.btactivado = false;
    this.enviosadmin = [];
    this.envio = new EnviosModel();
    this.menuAdmin.emit();

    var fechaff = new Date(Date.now());
    this.fechafinfiltrosel =
      fechaff.getFullYear() +
      "-" +
      (fechaff.getMonth() + 1) +
      "-" +
      fechaff.getDate();
    var fechaii = fechaff;
    this.fechainifiltrosel =
      fechaii.getFullYear() +
      "-" +
      fechaii.getMonth() +
      "-" +
      fechaii.getDate();
    this.crearFormularioF();
    this.cambiosFormularioF();
  }

  ngOnInit() {
    this.getEnviosAdmin(this.fechainifiltrosel, this.fechafinfiltrosel);

    this.modalOptions = {
      backdrop: "static",
      backdropClass: "customBackdrop",
      centered: true,
      size: "lg",
    };

    this.btactivadoF = true;
    this.fechainicioexcel = "";
    this.fechafinexcel = "";

  }

  getEnviosAdmin(fechai, fechaf) {
    this.loading = true;
    this.enviosService.getEnviosFilt(fechai, fechaf).subscribe((resp: EnviosModel[]) => {
      this.pagi = 1;
      this.enviosadmin = resp ;
  
      setTimeout(() => {
        this.loading = false;
        }, 300);
    });
  }

  passConfirmar() {
    return (formGroup: FormGroup) => {
      const fi = formGroup.controls["fechaini"];
      const ff = formGroup.controls["fechafin"];
      let ffi = Date.parse(fi.value);
      let fff = Date.parse(ff.value);

      this.btactivadoF = true;
      ff.setErrors({ no: false });
      if (ffi > fff) {
        this.btactivadoF = false;
        ff.setErrors({ no: true });
      }
    };
  }

  crearFormularioF() {
    this.formaF = this.fb.group(
      {
        fechaini: [this.fechainifiltrosel, [Validators.required]],
        fechafin: [this.fechafinfiltrosel, [Validators.required]],
      },
      {
        validators: this.passConfirmar(),
      }
    );
  }

  cambiosFormularioF() {
    this.formaF.valueChanges.subscribe((value) => {
      this.fechainifiltrosel = this.formaF.get("fechaini").value;
      this.fechafinfiltrosel = this.formaF.get("fechafin").value;

      if (this.formaF.status != "INVALID") {
        this.btactivadoF = true;
      }
    });
  }

  filtrar() {
    this.getEnviosAdmin(this.fechainifiltrosel, this.fechafinfiltrosel);
  }

  filtrarenter(event) {
    var codigo = event.which || event.keyCode;
    if (codigo === 13) {
      this.filtrar();
    }
  }

  open(content, envio: EnviosModel) {
    if (envio != undefined) {
      this.btactivado = true;

      this.idsel = envio.id;
      this.pedidos_idsel = envio.pedidos_id;
      this.clientenombresel = envio.nombre;
      this.fechasel = envio.fecha;
      this.clienteemailsel = envio.email;
      this.clientedireccionenviosel = envio.direccionenvio;
      this.clienteviaenviosel = envio.viaenvio;
      this.clientenumeroenviosel = envio.numeroenvio;
      this.clientepisoenviosel = envio.pisoenvio;
      this.clientelocalidadenviosel = envio.localidadenvio;
      this.clienteprovinciaenviosel = envio.provinciaenvio;
      this.clientecpostalenviosel = envio.cpostalenvio;
      this.shipsel = envio.ship;
      this.tipoenviosel = envio.tipoenvio;
      this.telefonosel = envio.telefono;
    

      this.modal = this.modalService.open(content, this.modalOptions);
    }
  }

  cerrarmodal(content) {
    this.resetear();
    this.modal.dismiss();
  }

  resetear() {
    this.idsel = null;
    this.clientenombresel = "";
    this.fechasel = "";

    this.envio.pedidos_id = null;
    this.envio.fecha = null;
    this.envio.cpostalenvio = "";
    this.envio.direccionenvio = "";
    this.envio.viaenvio = "";
    this.envio.numeroenvio = "";
    this.envio.pisoenvio = "";
    this.envio.email = "";
    this.envio.localidadenvio = "";
    this.envio.nombre = "";
    this.envio.provinciaenvio = "";
    this.envio.ship = "";
    this.envio.telefono = "";
  }


  eliminarEnvio( envio: EnviosModel) {
    this.alertasService.alertaWarning("¿SEGURO QUE DESEA ELIMINAR?","Se eliminara definitivamente de la App")
    .then((res) => {
      if (res.value) {
        this.enviosService.deleteEnvio(envio.id).subscribe( resp => {
          if (resp) {
              this.alertasService.alertaOK("REGISTRO ELIMINADO","se ha actualizado de la lista")
              .then(
                  this.getEnviosAdmin(this.fechainifiltrosel, this.fechafinfiltrosel) 
              );
          }
          this.cartService.pedidoacabado(envio.pedidos_id);
        }, error => { 
          console.log(error);
        });          
      }
    }, error => { 
      console.log(error);
    });
  }


  sacarEXCELFull() {

    
    //cargar datos en tabla

    this.fechainicioexcel = this.fechainifiltrosel;
    this.fechafinexcel = this.fechainifiltrosel;
    this.cajasexcel = this.enviosadmin ;

    this.alertasService
      .alertaWarning("¿Desea Excel?", "Se conformará Excel con datos filtrados ")
      .then((res) => {
        if (res.value) {
          let rws = document.getElementById("rws");
          let rwsl = rws.childNodes.length;
          if (rwsl > 0) {
            let element = document.getElementById("excel-table");
            const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            //file
            let namefile =
              "excel-" + this.fechainifiltrosel.trim() + ".xlsx";
            XLSX.writeFile(wb, namefile);
          }
          this.limpiarInfoExcel();
        } else {
          this.limpiarInfoExcel();
        }
      });

  }

  limpiarInfoExcel() {
    this.fechainicioexcel = "";
    this.fechafinexcel = "";
    this.cajasexcel = [];

  }
}
