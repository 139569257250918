import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";

import { ServiciosService } from "../../services/servicios.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { resetCompiledComponents } from "@angular/core/src/render3/jit/module";
import { ListasService } from "../../services/listas.service";
import { AlertasService } from "../../services/alertas.service";
import { FacturasService } from "../../services/facturas.service";
import { CartService } from "../../services/cart.service";
import { ClientesService } from "../../services/clientes.service";
import { TipoenviosService } from "../../services/tipoenvios.service";

import { BootstrapOptions } from "@angular/core/src/application_ref";

import { CartModel } from "src/app/models/Cart.model";
import { ClientesModel } from "src/app/models/Clientes.model";
import { FilesModel } from "src/app/models/Files.model";
import { ProductospedidosModel } from "src/app/models/Productospedidos.model";
import { TipoenviosModel } from "src/app/models/Tipoenvios.model ";
import { CarritoComponent } from "src/app/components/carrito/carrito.component";
import { LoginComponent } from "src/app/components/login/login.component";
import { typeWithParameters } from "@angular/compiler/src/render3/util";
import { AuthService } from "src/app/services/auth.service";
import { HistoricoModel } from "src/app/models/Historico.model";
import { storage } from "firebase";
import { JsonPipe } from "@angular/common";
import { RedsyspagosModel } from "src/app/models/Redsyspagos.model";
import { BonosModel } from "src/app/models/Bonos.model";
import { BonosService } from "src/app/services/bonos.service";
import { timeout } from "rxjs/operators";
import { PedidosModel } from "src/app/models/Pedidos.model";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { FacturasModel } from "src/app/models/Facturas.model";

@Component({
  selector: "app-checkoutcliente",
  templateUrl: "./checkoutcliente.component.html",
  styleUrls: ["./checkoutcliente.component.css"],
})
export class CheckoutclienteComponent implements OnInit {
  @Output() loger: EventEmitter<any> = new EventEmitter();
  @Output() register: EventEmitter<any> = new EventEmitter();
  @Output() isloginok: EventEmitter<any> = new EventEmitter();


  ///test https://sis-t.redsys.es:25443/sis/realizarPago ////real  https://sis.redsys.es/sis/realizarPago
  rutapago: string = 'https://sis-t.redsys.es:25443/sis/realizarPago';
  Ds_Signature_post: string = "";
  Ds_MerchantParameters_post: string = "";
  Ds_SignatureVersion_post: string = "";
  Ds_Signature_postz: string = "";
  Ds_MerchantParameters_postz: string = "";
  Ds_SignatureVersion_postz: string = "";
  DS_Merchant_Paymethods_postz: string = "";

  forma: FormGroup;
  cliente: ClientesModel;
  productospedidos: ProductospedidosModel[];
  metodopagosel: string = "1";
  tipoenviosel: number = 0;
  btactivado: boolean = false;

  solobonos: boolean = true;
  igualqueenvio: boolean;
  actunumprod: number = 0;
  histo: HistoricoModel[] = [];
  cart: CartModel;
  archivos: FilesModel[] = [];
  totalGrupos: number = 0;
  totalp: number = 0;
  totalFinal: number = 0;
  copias: number = 0;
  ship: number = 0;
  producto: ProductospedidosModel;
  tipoenvios: TipoenviosModel[] = [];
  tipoenvio1: string;
  tipoenvio2: string;
  tipoenvio3: string;
  tipoenvio4: string;
  tipoenviodesc1: string;
  tipoenviodesc2: string;
  tipoenviodesc3: string;
  tipoenviodesc4: string;

  clientenombresel: string;
  clienteemailsel: string;
  clientetelefonosel: string;
  clientedireccionenviosel: string;
  clienteviaenviosel: string;
  clientenumeroenviosel: string;
  clientepuertaenviosel: string;
  clientepisoenviosel: string;
  clientecpostalenviosel: string;
  clientelocalidadenviosel: string;
  clienteprovinciaenviosel: string;
  clientedireccionfacturacionsel: string;
  clienteviafacturacionsel: string;
  clientenumerofacturacionsel: string;
  clientepuertafacturacionsel: string;
  clientepisofacturacionsel: string;
  clientecpostalfacturacionsel: string;
  clientelocalidadfacturacionsel: string;
  clienteprovinciafacturacionsel: string;
  clienteempresasel: string;
  clienteCIFNIFsel: string;

  bonos: BonosModel[] = [];
  saldobonosel: number = 0;
  saldobonototal: number = 0;

  constructor(
    private ro: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private alertasService: AlertasService,
    private cartService: CartService,
    private auth: AuthService,
    private clientesService: ClientesService,
    private bonosService: BonosService,
    private tipoenviosService: TipoenviosService,
    private facturasService: FacturasService
    ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.btactivado = false;
    this.cliente = new ClientesModel();
    this.clientenombresel = "";
    this.clienteemailsel = "";
    this.clientetelefonosel = "";
    this.clientedireccionenviosel = "";
    this.clienteviaenviosel = "";
    this.clientenumeroenviosel = "";
    this.clientepuertaenviosel = "";
    this.clientepisoenviosel = "";
    this.clientecpostalenviosel = "";
    this.clientelocalidadenviosel = "";
    this.clienteprovinciaenviosel = "";
    this.clientedireccionfacturacionsel = "";
    this.clienteviafacturacionsel = "";
    this.clientenumerofacturacionsel = "";
    this.clientepuertafacturacionsel = "";
    this.clientepisofacturacionsel = "";
    this.clientecpostalfacturacionsel = "";
    this.clientelocalidadfacturacionsel = "";
    this.clienteprovinciafacturacionsel = "";
    this.clienteempresasel = "";
    this.clienteCIFNIFsel = "";

    this.totalGrupos = 0;
    this.ship = 0;
    this.totalFinal = 0;
    this.actunumprod = 0;
    this.productospedidos = [];
    this.igualqueenvio = true;
  }

  ngOnInit() {
    this.crearFormulario();
    this.cambiosFormulario();
    this.getCliente();
    this.getHisto();
    //tipo de envios y despues cart
    this.getPedido();

    const xid = this.ro.snapshot.paramMap.get("pago");

    if (xid && xid != null && xid != "") {
      //pago realizado correctamente si es 1
      if(xid == "1"){
        this.pagoOK();
      }
      //pago con errores si es 0
      if(xid == "0"){
        this.pagoKOERROR();
      }
    }

    this.islogin();
  }

  async pagoOK(){
    let idpedido = this.cartService.getPendientepago();
    
    if(idpedido != null){
     let pago = await this.pagadoPedido(idpedido);
      if(pago){
        this.alertasService
        .alertaPago (
          "PAGO REALIZADO, PEDIDO GUARDADO",
          "se ha enviado email del proceso registrado"
        )
        .then(
          //resetear pedido
          this.resetCart(),
          this.router.navigate(["homecliente"])
        );
      }
    }
  }


  pagoKOERROR(){
    this.cargarDatosPedido();
    this.alertasService
    .alertaKO(
      "PAGO NO REALIZADO",
      "vuelva a intentarlo de nuevo"
    )
    .then(
      //recuperar elecciones de tipo envio , tipo de pago y check
    );
  }


  async savePedido() {
 
    let cartenvio = this.cart;
  
    if (!this.forma.invalid && this.btactivado && this.cliente.email != "") {
      this.btactivado = false;

      if (this.cliente.cpostalfacturacion == "") {
        this.cliente.cpostalfacturacion = this.cliente.cpostalenvio;
      }
      if (this.cliente.localidadfacturacion == "") {
        this.cliente.localidadfacturacion = this.cliente.localidadenvio;
      }
      if (this.cliente.provinciafacturacion == "") {
        this.cliente.provinciafacturacion = this.cliente.provinciaenvio;
      }
      if (this.cliente.direccionfacturacion == "") {
        this.cliente.direccionfacturacion = this.cliente.direccionenvio;
      }
      if (this.cliente.viafacturacion == "") {
        this.cliente.viafacturacion = this.cliente.viaenvio;
      }
      if (this.cliente.numerofacturacion == "") {
        this.cliente.numerofacturacion = this.cliente.numeroenvio;
      }
      if (this.cliente.puertafacturacion == "") {
        this.cliente.puertafacturacion = this.cliente.puertaenvio;
      }
      if (this.cliente.pisofacturacion == "") {
        this.cliente.pisofacturacion = this.cliente.pisoenvio;
      }
      
      if (this.cliente.id == null) {
        let clientecreado = await this.clientesService
          .crearCliente(this.cliente)
          .toPromise()
          .then();
        this.cliente.id = clientecreado["id"];
      }
      
      cartenvio.clientes_id = this.cliente.id;
      cartenvio.tipoenvios_id = this.ship;
      cartenvio.metodopagos_id = parseInt(this.metodopagosel);
      cartenvio.totalbono = this.cartService.correct(this.saldobonosel);
      this.cartService.saveCart(cartenvio);
      
      if (cartenvio != null) {
        this.cartService.guardarPedido(cartenvio).subscribe(async (resp2: PedidosModel) => {
          if (resp2.id != null) {
            var enviodev = resp2;
            if(enviodev != null){
              
              switch (cartenvio.metodopagos_id ){

              case 1: 
                ///guardar localstorage  pendientepago: idpedido
                this.cartService.savPendientepago(enviodev.id.toString());
                let cantidadpago1 = this.totalFinal.toString();
                cantidadpago1 = this.cartService.correct(cantidadpago1);
                this.procesarpagotarjeta(cantidadpago1, enviodev.id  );
                break;
              case 2:
                ///guardar localstorage  pendientepago: idpedido
                this.cartService.savPendientepago(enviodev.id.toString());
                let cantidadpago2 = this.totalFinal.toString();
                cantidadpago2 = this.cartService.correct(cantidadpago2);
                this.procesarpagobizum(cantidadpago2, enviodev.id);
                break;
              case 3:
                ///transferencia
                this.alertasService
                .alertaOK(
                  "PEDIDO GUARDADO",
                  "se ha enviado email con los datos de pago por transferencia del proceso registrado"
                )
                .then(
                  //resetear pedido
                  this.resetCart(),
                  this.router.navigate(["homecliente"])
                );

                break;
              case 4:
                ///sin gasto , se consume de bono
                this.alertasService
                .alertaOK(
                  "PEDIDO GUARDADO",
                  "se ha enviado email de proceso registrado"
                )
                .then(
                  //resetear pedido
                  this.resetCart(),
                  this.router.navigate(["homecliente"])
                );
                break;
                default:
                  this.alertasService
                  .alertaKO("ERROR AL GUARDAR", "pongase en contacto con Easycopy")
                  .then(this.router.navigate(["checkoutcliente"]));
                break;

              }
            }
          }
        })
      }
      else {
        this.alertasService
          .alertaKO("Debe realizar un pedido", "regrese a zona configuración")
          .then();
      }
    } 
    else if (this.productospedidos.length == 0) {
      this.alertasService
        .alertaKO(
          "No hay archivos a enviar",
          "añada documentos y su configuración"
        )
        .then();
    } 
    else {
      this.alertasService
        .alertaKO("Faltan datos en el formulario de envío", "revise los campos")
        .then();
    }
  }

  //validaciones rojo
  get novalidTipoenvios() {
    return (this.forma.get("tipoenvio").invalid && this.forma.get("tipoenvio").touched);
  }
  get novalidoNombre() {
    return this.forma.get("nombre").invalid && this.forma.get("nombre").touched;
  }
  get novalidoEmail() {
    return this.forma.get("email").invalid && this.forma.get("email").touched;
  }
  get novalidoTelefono() {
    return (this.forma.get("telefono").invalid && this.forma.get("telefono").touched);
  }
  get novalidoDireccionenvio() {
    return (this.forma.get("direccionenvio").invalid && this.forma.get("direccionenvio").touched);
  }
  get novalidoViaenvio() {
    return (this.forma.get("viaenvio").invalid && this.forma.get("viaenvio").touched);
  }
  get novalidoNumeroenvio() {
    return (this.forma.get("numeroenvio").invalid && this.forma.get("numeroenvio").touched);
  }
  get novalidoPisoenvio() {
    return (this.forma.get("pisoenvio").invalid && this.forma.get("pisoenvio").touched);
  }
  get novalidoCPostalenvio() {
    return (this.forma.get("cpostalenvio").invalid && this.forma.get("cpostalenvio").touched);
  }
  get novalidoLocalidadenvio() {
    return (this.forma.get("localidadenvio").invalid && this.forma.get("localidadenvio").touched);
  }
  get novalidoProvinciaenvio() {
    return (this.forma.get("provinciaenvio").invalid && this.forma.get("provinciaenvio").touched);
  }
  get novalidoDireccionfacturacion() {
    return (this.forma.get("direccionfacturacion").invalid && this.forma.get("direccionfacturacion").touched);
  }
  get novalidoViafacturacion() {
    return (this.forma.get("viafacturacion").invalid && this.forma.get("viafacturacion").touched);
  }
  get novalidoNumerofacturacion() {
    return (this.forma.get("numerofacturacion").invalid && this.forma.get("numerofacturacion").touched);
  }
  get novalidoPisofacturacion() {
    return (this.forma.get("pisofacturacion").invalid && this.forma.get("pisofacturacion").touched);
  }
  get novalidoCPostalfacturacion() {
    return (this.forma.get("cpostalfacturacion").invalid && this.forma.get("cpostalfacturacion").touched);
  }
  get novalidoLocalidadfacturacion() {
    return (this.forma.get("localidadfacturacion").invalid && this.forma.get("localidadfacturacion").touched);
  }
  get novalidoProvinciafacturacion() {
    return (this.forma.get("provinciafacturacion").invalid && this.forma.get("provinciafacturacion").touched);
  }
  get novalidoEmpresa() {
    return (this.forma.get("empresa").invalid && this.forma.get("empresa").touched);
  }
  get novalidoCIFNIF() {
    return this.forma.get("CIFNIF").invalid && this.forma.get("CIFNIF").touched;
  }

  crearFormulario() {
    this.forma = this.fb.group({
      nombre: ["", [Validators.required, Validators.minLength(3)]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      telefono: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,5}$/
          ),
        ],
      ],
      direccionenvio: ["", [Validators.required, Validators.minLength(1)]],
      viaenvio: ["", [Validators.required, Validators.minLength(1)]],
      numeroenvio: ["", [Validators.required, Validators.minLength(1)]],
      puertaenvio: [""],
      pisoenvio: ["", [Validators.required, Validators.minLength(1)]],
      cpostalenvio: [
        "",
        [
          Validators.required,
          Validators.pattern(/^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/),
        ],
      ],
      localidadenvio: ["", [Validators.required, Validators.minLength(1)]],
      provinciaenvio: ["", [Validators.required, Validators.minLength(1)]],
      direccionfacturacion: [
        "",
        [Validators.required, Validators.minLength(1)],
      ],
      viafacturacion: ["", [Validators.required, Validators.minLength(1)]],
      numerofacturacion: ["", [Validators.required, Validators.minLength(1)]],
      puertafacturacion: [""],
      pisofacturacion: ["", [Validators.required, Validators.minLength(1)]],
      cpostalfacturacion: [
        "",
        [Validators.pattern(/^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/)],
      ],
      localidadfacturacion: [
        "",
        [Validators.required, Validators.minLength(1)],
      ],
      provinciafacturacion: [
        "",
        [Validators.required, Validators.minLength(1)],
      ],
      empresa: [""],
      CIFNIF: [
        "",
        [
          Validators.pattern(
            /^((([X-Z])|([LM])){1}([-]?)((\d){7})([-]?)([A-Z]{1}))|((\d{8})([-]?)([A-Z]))$/
          ),
        ],
      ],
      metodopago: ["1", [Validators.required]],
      aceptapoliticas: [false, [Validators.requiredTrue]],
      aceptapublicidad: [""],
      tipoenvio: [""],
    });
  }

  // private validateCIFNIF(control: AbstractControl) {
  //   const doc = control.value;
  //   let patNIF = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/ ;
  //   let patCIF = /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/ ;
  //   let error = null;
  //   if (!patNIF.test(doc)) {
  //
  //     error = true;
  //   }
  //   // else if (patCIF.test(doc)) {
  //   //   error = true;
  //   // }
  //
  //   return null;
  // }

  cambiosFormulario() {
	this.forma.valueChanges.subscribe((value) => {
		this.cliente.nombre = this.forma.get("nombre").value;
		this.cliente.email = this.forma.get("email").value;
		this.cliente.telefono = this.forma.get("telefono").value;
		this.cliente.direccionenvio = this.forma.get("direccionenvio").value;
		this.cliente.viaenvio = this.forma.get("viaenvio").value;
		this.cliente.numeroenvio = this.forma.get("numeroenvio").value;
		this.cliente.puertaenvio = this.forma.get("puertaenvio").value;
		this.cliente.pisoenvio = this.forma.get("pisoenvio").value;
		this.cliente.cpostalenvio = this.forma.get("cpostalenvio").value;
		this.cliente.localidadenvio = this.forma.get("localidadenvio").value;
		this.cliente.provinciaenvio = this.forma.get("provinciaenvio").value;
		this.cliente.direccionfacturacion = this.forma.get("direccionfacturacion").value;
		this.cliente.viafacturacion = this.forma.get("viafacturacion").value;
		this.cliente.numerofacturacion = this.forma.get("numerofacturacion").value;
		this.cliente.puertafacturacion = this.forma.get("puertafacturacion").value;
		this.cliente.pisofacturacion = this.forma.get("pisofacturacion").value;
		this.cliente.cpostalfacturacion = this.forma.get("cpostalfacturacion").value;
		this.cliente.localidadfacturacion = this.forma.get("localidadfacturacion").value;
		this.cliente.provinciafacturacion = this.forma.get("provinciafacturacion").value;
		this.cliente.empresa = this.forma.get("empresa").value;
		this.cliente.CIFNIF = this.forma.get("CIFNIF").value;
		this.cliente.aceptapoliticas = this.forma.get("aceptapoliticas").value;
		this.cliente.aceptapublicidad = this.forma.get("aceptapublicidad").value;

		this.btactivado = false;

		if (this.forma.status != "INVALID" && this.cart.productospedidos.length > 0) {
			this.btactivado = true;
		}
	});
  }

  cambiarigualqueenvio() {
	let valx = this.igualqueenvio;
	this.igualqueenvio = !this.igualqueenvio;
	if (valx) {
		this.clientedireccionfacturacionsel = this.forma.get("direccionenvio").value;
		this.clienteviafacturacionsel = this.forma.get("viaenvio").value;
		this.clientenumerofacturacionsel = this.forma.get("numeroenvio").value;
		this.clientepuertafacturacionsel = this.forma.get("puertaenvio").value;
		this.clientepisofacturacionsel = this.forma.get("pisoenvio").value;
		this.clientecpostalfacturacionsel = this.forma.get("cpostalenvio").value;
		this.clientelocalidadfacturacionsel = this.forma.get("localidadenvio").value;
		this.clienteprovinciafacturacionsel = this.forma.get("provinciaenvio").value;

		this.forma.controls["direccionfacturacion"].setValue(this.clientedireccionfacturacionsel);
		this.forma.controls["viafacturacion"].setValue(this.clienteviafacturacionsel);
		this.forma.controls["numerofacturacion"].setValue(this.clientenumerofacturacionsel);
		this.forma.controls["puertafacturacion"].setValue(this.clientepuertafacturacionsel);
		this.forma.controls["pisofacturacion"].setValue(this.clientepisofacturacionsel);
		this.forma.controls["cpostalfacturacion"].setValue(this.clientecpostalfacturacionsel);
		this.forma.controls["localidadfacturacion"].setValue(this.clientelocalidadfacturacionsel);
		this.forma.controls["provinciafacturacion"].setValue(this.clienteprovinciafacturacionsel);
	}
  }

  getCart() {
    this.cart = this.cartService.getCart();
  }

  getCliente() {
    this.cliente = this.clientesService.getClienteLocal();

    if (this.cliente.id != null && this.cliente.id != 0) {
      this.saldobonototal = 0;
      let bonolocal = this.bonosService.getBonoLocal();
      if (bonolocal) {
        this.saldobonototal = parseFloat(bonolocal);
      }

      if (this.saldobonosel == null || this.saldobonosel == undefined) {
        this.saldobonosel = 0;
      }

      this.clientenombresel = this.cliente.nombre;
      this.clienteemailsel = this.cliente.email;
      this.clientetelefonosel = this.cliente.telefono;
      this.clientedireccionenviosel = this.cliente.direccionenvio;
      this.clienteviaenviosel = this.cliente.viaenvio;
      this.clientenumeroenviosel = this.cliente.numeroenvio;
      this.clientepuertaenviosel = this.cliente.puertaenvio;
      this.clientepisoenviosel = this.cliente.pisoenvio;
      this.clientecpostalenviosel = this.cliente.cpostalenvio;
      this.clientelocalidadenviosel = this.cliente.localidadenvio;
      this.clienteprovinciaenviosel = this.cliente.provinciaenvio;
      this.clientedireccionfacturacionsel = this.cliente.direccionfacturacion;
      this.clienteviafacturacionsel = this.cliente.viafacturacion;
      this.clientenumerofacturacionsel = this.cliente.numerofacturacion;
      this.clientepuertafacturacionsel = this.cliente.puertafacturacion;
      this.clientepisofacturacionsel = this.cliente.pisofacturacion;
      this.clientecpostalfacturacionsel = this.cliente.cpostalfacturacion;
      this.clientelocalidadfacturacionsel = this.cliente.localidadfacturacion;
      this.clienteprovinciafacturacionsel = this.cliente.provinciafacturacion;
      this.clienteempresasel = this.cliente.empresa;
      this.clienteCIFNIFsel = this.cliente.CIFNIF;

      this.forma.controls["nombre"].setValue(this.clientenombresel);
      this.forma.controls["email"].setValue(this.clienteemailsel);
      this.forma.controls["telefono"].setValue(this.clientetelefonosel);
      this.forma.controls["direccionenvio"].setValue(this.clientedireccionenviosel);
      this.forma.controls["viaenvio"].setValue(this.clienteviaenviosel);
      this.forma.controls["numeroenvio"].setValue(this.clientenumeroenviosel);
      this.forma.controls["puertaenvio"].setValue(this.clientepuertaenviosel);
      this.forma.controls["pisoenvio"].setValue(this.clientepisoenviosel);
      this.forma.controls["cpostalenvio"].setValue(this.clientecpostalenviosel);
      this.forma.controls["localidadenvio"].setValue(this.clientelocalidadenviosel);
      this.forma.controls["provinciaenvio"].setValue(this.clienteprovinciaenviosel);
      this.forma.controls["direccionfacturacion"].setValue(this.clientedireccionfacturacionsel);
      this.forma.controls["viafacturacion"].setValue(this.clienteviafacturacionsel);
      this.forma.controls["numerofacturacion"].setValue(this.clientenumerofacturacionsel);
      this.forma.controls["puertafacturacion"].setValue(this.clientepuertafacturacionsel);
      this.forma.controls["pisofacturacion"].setValue(this.clientepisofacturacionsel);
      this.forma.controls["cpostalfacturacion"].setValue(this.clientecpostalfacturacionsel);
      this.forma.controls["localidadfacturacion"].setValue(this.clientelocalidadfacturacionsel);
      this.forma.controls["provinciafacturacion"].setValue(this.clienteprovinciafacturacionsel);
      this.forma.controls["empresa"].setValue(this.clienteempresasel);
      this.forma.controls["CIFNIF"].setValue(this.clienteCIFNIFsel);

      this.btactivado = false;

      this.forma.controls["nombre"].touched == true;
      this.forma.controls["email"].touched == true;
      this.forma.controls["telefono"].touched == true;
      this.forma.controls["direccionenvio"].touched == true;
      this.forma.controls["viaenvio"].touched == true;
      this.forma.controls["numeroenvio"].touched == true;
      this.forma.controls["puertaenvio"].touched == true;
      this.forma.controls["pisoenvio"].touched == true;
      this.forma.controls["cpostalenvio"].touched == true;
      this.forma.controls["localidadenvio"].touched == true;
      this.forma.controls["provinciaenvio"].touched == true;
      this.forma.controls["direccionfacturacion"].touched == true;
      this.forma.controls["viafacturacion"].touched == true;
      this.forma.controls["numerofacturacion"].touched == true;
      this.forma.controls["puertafacturacion"].touched == true;
      this.forma.controls["pisofacturacion"].touched == true;
      this.forma.controls["cpostalfacturacion"].touched == true;
      this.forma.controls["localidadfacturacion"].touched == true;
      this.forma.controls["provinciafacturacion"].touched == true;
      this.forma.controls["empresa"].touched == true;
      this.forma.controls["CIFNIF"].touched == true;
    }
  }

  getNumProductosCarrito() {
    this.actunumprod =
      this.cart.productospedidos != null
        ? this.cart.productospedidos.length
        : 0;
  }

  getHisto() {
    if (this.cliente != null) {
      this.cartService
        .getHistoricopedidos(this.cliente.id)
        .subscribe((resp: HistoricoModel[]) => {
          if (resp) {
            this.histo = resp;
          }
        });
    }
  }

  async getTotal(cart: CartModel) {
    //total pedido
    this.totalGrupos = 0;
    if (this.cart.productospedidos == null) {
      this.cart.productospedidos = [];
    }
    this.productospedidos = this.cart.productospedidos;
    //bonos

    this.bonos = await this.bonosService.getListaBonos().toPromise();

    this.solobonos = true;
    this.productospedidos.forEach((el) => {
      this.bonos.forEach((it) => {
        if (el.productostipos_id == 3) {
          if (el.tipobonos_id == it.id) {
            el.nombrebono = it.nombre;
            el.descripcionbono = it.descripcion;
          }
        } else {
          this.solobonos = false;
        }
      });
      /////////////////////////////////total_////////////////////////////////////////////////////
      let totalcorr = this.totalGrupos * 1 + +(el.total * 1);
      this.totalGrupos = this.cartService.correct(totalcorr);

    });
    this.cart.ship = this.ship;
    this.cartService.saveCart(cart);
    this.selecenvio();
  }

  getTipoenvios() {
    this.tipoenviosService
      .getListaTipoenvios()
      .subscribe((resp: TipoenviosModel[]) => {
        this.tipoenvios = resp;
        this.tipoenvios.forEach((el) => {
          switch (el.id) {
            case 1:
              this.tipoenvio1 = el.nombre;
              this.tipoenviodesc1 = el.descripcion;
              break;
            case 2:
              this.tipoenvio2 = el.nombre;
              this.tipoenviodesc2 = el.descripcion;
              break;
            case 3:
              this.tipoenvio3 = el.nombre;
              this.tipoenviodesc3 = el.descripcion;
              break;
            case 4:
              this.tipoenvio4 = el.nombre;
              this.tipoenviodesc4 = el.descripcion;
              break;
          }
        });
      });
  }

  selecenvio() {
    this.solobonos = true;
    this.productospedidos.forEach((el) => {
      if (el.productostipos_id != 3) {
        this.solobonos = false;
      }
    });
    if (this.solobonos) {
      this.tipoenvio(4);
    } else {
      this.tipoenvio(1);
    }
  }

  bonoPedido() {
    this.getPedido();
    this.alertasService.alertaOK(
      "BONO GUARDADO",
      "se ha añadido Bono elegido al pedido"
    );
  }

  getPedido() {
    this.getTipoenvios();
    this.getCart();
    this.getNumProductosCarrito();
    this.getTotal(this.cart);
  }

  eliminargrupo(ev) {
    this.alertasService
      .alertaWarning(
        "¿SEGURO QUE DESEA ELIMINAR?",
        "Se perderá el grupo de configuración pedido"
      )
      .then((res) => {
        if (res.value) {
          let idd = ev.id;
          let ind = idd.split("-")[1];
          this.totalGrupos = this.totalGrupos - this.cart.productospedidos[ind].total;
          this.cart.productospedidos.splice(ind, 1);
          this.cartService.deleteProductCart(ind);
          this.getNumProductosCarrito();
          this.getTotal(this.cart);
          this.alertasService
            .alertaOK("ELIMINADO", "se ha actualizado de la lista")
            .then();
        }
      });
  }

  editargrupo(ev) {
    let ind = ev.id.split("-")[1];
    this.cart.currentproduct = ind;
    this.cartService.saveCart(this.cart);
    //cargar en localstorage currentproduct
    this.router.navigate(["homecliente"]);
  }

  resetCart() {
    this.cart = new CartModel();
    this.cartService.resetCart();
  }

  nuevoconfigurador() {
    this.cart.currentproduct = null;
    this.cartService.saveCart(this.cart);
    this.router.navigate(["homecliente"]);
  }

  volveratras() {
    if (this.solobonos) {
      this.cart.currentproduct = null;
      this.cartService.saveCart(this.cart);
    }
    this.router.navigate(["homecliente"]);
  }

  registro() {
    this.register.emit();
  }

  login() {
    this.loger.emit();
  }

  islogin() {
    this.isloginok.emit();
  }

  metodopago(n) {
    this.metodopagosel = n;
  }

  tipoenvio(n) {
    if (this.saldobonototal == null || this.saldobonototal == undefined) {
      this.saldobonototal = 0;
    }
    this.tipoenvios.forEach((el, index) => {
      if (n == index + 1) {
        this.ship = n;
        this.tipoenviosel = el.precio;

        this.totalFinal =
          Math.round(el.precio * 1 * 1000) / 1000 +
          Math.round(this.totalGrupos * 1 * 1000) / 1000;
        this.cart.totalfinal = this.totalFinal;

        if (this.saldobonototal > 0 && !this.solobonos) {
          let sbonototal: number = this.saldobonototal;
          let tfinal: number = this.totalFinal;

          if (sbonototal <= tfinal) {
            this.saldobonosel = sbonototal;
            this.totalFinal = tfinal - sbonototal;
          } else if (sbonototal > tfinal) {
            this.saldobonosel = tfinal;
            this.totalFinal = 0;
          }
        }
      }
    });
    
    this.tipoenviosel = this.cartService.correct(this.tipoenviosel);
    this.totalFinal = this.cartService.correct(this.totalFinal);
    //this.saldobonototal = this.cartService.correct(this.saldobonototal);

    //this.metodopago(1);
    if (this.totalFinal == 0 && !this.solobonos) {
      this.metodopago(4);
    }
  }

  copiardireccion(ev) {
    if (!this.igualqueenvio) {
      this.clientedireccionfacturacionsel = ev.target.value;
    }
  }

  copiarvia(ev) {
    if (!this.igualqueenvio) {
      this.clienteviafacturacionsel = ev.target.value;
    }
  }

  copiarnumero(ev) {
    if (!this.igualqueenvio) {
      this.clientenumerofacturacionsel = ev.target.value;
    }
  }

  copiarpuerta(ev) {
    if (!this.igualqueenvio) {
      this.clientepuertafacturacionsel = ev.target.value;
    }
  }

  copiarpiso(ev) {
    if (!this.igualqueenvio) {
      this.clientepisofacturacionsel = ev.target.value;
    }
  }

  copiarlocalidad(ev) {
    if (!this.igualqueenvio) {
      this.clientelocalidadfacturacionsel = ev.target.value;
    }
  }

  copiarprovincia(ev) {
    if (!this.igualqueenvio) {
      this.clienteprovinciafacturacionsel = ev.target.value;
    }
  }

  copiarcpostal(ev) {
    if (!this.igualqueenvio) {
      this.clientecpostalfacturacionsel = ev.target.value;
    }
  }


  async procesarpagobizum(amount: string, numpedido: number) {
    let zeta = "z";
    amount = this.cartService.correct(amount);    
    if (parseInt(amount) > 0) {
      this.cartService.getPagoRedsys(amount, zeta, numpedido).subscribe((resp: string[]) => {
        if (resp != null) {
          let bo = document.getElementById("btpagobizum");
          let parameters = resp;
          this.Ds_MerchantParameters_postz = parameters[0];
          this.Ds_Signature_postz = parameters[1];
          this.Ds_SignatureVersion_postz = parameters[2];
          if (parameters.length > 0) {
            setTimeout(function() {
              bo.click();
            }, 2000);
          }
        }
      });
    }
  }

  async procesarpagotarjeta(amount: string, numpedido: number) {

    /////  para depurar en local sin pasar por redsys /////
    // this.pagoOK();                                 /////
    // return;                                        /////
    
    let zeta = "0";
    amount = this.cartService.correct(amount);    
    if (parseInt(amount) > 0) {
      this.cartService.getPagoRedsys(amount, zeta, numpedido).subscribe((resp: string[]) => {
        if (resp != null) {
          let bo = document.getElementById("btpagotarjeta");
          let parameters = resp;
          this.Ds_MerchantParameters_post = parameters[0];
          this.Ds_Signature_post = parameters[1];
          this.Ds_SignatureVersion_post = parameters[2];
          this.rutapago = parameters[3];
        
          if (parameters.length > 0) {
            setTimeout(function() {
              bo.click();
            }, 2000);
          }
        }
      });
    }
  }

  async pagadoPedido(idpedido) {

    ///registrar pagado y limpiar app comprobando pedientepago
    let pedidopagado =  await this.cartService.Pedidopagado('T', idpedido).toPromise().then();
    return pedidopagado.pagado;

  }

  cargarDatosPedido() {
  
    ///cargar preferencias antes de pago desde pendientepago localstorage
    let pendiente = this.cartService.getPendientepago();
    console.log("error ", pendiente );

  }

}

