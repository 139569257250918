import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductoModel } from '../models/Producto.model';
import { CartModel } from '../models/Cart.model';
import { catchError, map} from 'rxjs/operators' ;
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { JsonPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { ProductospedidosModel } from '../models/Productospedidos.model';
import { FilesModel } from '../models/Files.model';
import { HistoricoModel } from '../models/Historico.model';
import { PedidosModel } from '../models/Pedidos.model';



@Injectable({
  providedIn: 'root'
})
export class CartService {
  userToken: string;
  idUsuario: string;
  url: string;
  apiurl: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.apiurl = environment.apiurl;
  }
  correct(numero: any){
    if(numero != undefined){
      let num = numero.toString();
      if(num.includes('.')){
        let numsep =  num.split('.');
        let decimales = numsep[1];
        let entero = numsep[0];

        if(decimales.length == 0){
          decimales = num + "0";
        }
        if(decimales.length >= 3){
          decimales = decimales.substring(0,2);
        }
        if(entero == "00"){
          entero = "0";
        }
        if(decimales < 10){
          decimales =  decimales + "0"  ;
        }
        numero = entero ;
        if(decimales > 0){
          numero += "."+ decimales;
        }
      }
    }
    if(numero == "undefined"){
      numero = "0";
    }
    return numero;
  }


  guardarPedido( pedido: CartModel ) {
     
    let endpoint =  '/pedidos/create';
    this.url = this.apiurl + endpoint;
    
    const productoData = {
      pagado : 'F',
      clientes_id: pedido.clientes_id,
      notaxamt: pedido.notaxamt,
      cur: pedido.cur,
      ship: pedido.ship,
      taxamt: pedido.taxamt,
      totalamt: pedido.totalamt,
      total: pedido.total,
      created: pedido.created,
      weight: pedido.weight,
      estados_id: 1,
      metodopagos_id: pedido.metodopagos_id,
      tipoenvios_id: pedido.tipoenvios_id,
      totalbono: pedido.totalbono,
      totalfinal: pedido.ship + pedido.total,
      productospedidos: pedido.productospedidos == null ? [] : pedido.productospedidos
      
    };
    
    return this.http.post( `${this.url}`, productoData).pipe(map( resp => {
      return resp as PedidosModel;
    } ));
  }


  modificarPedido( pedido: CartModel ) {
    this.userToken = this.auth.leerToken();
    let endpoint =  '/pedidos/modificar' ;
    this.url = this.apiurl + endpoint;
    const productoData = {
      
      notaxamt: pedido.notaxamt,
      ship: pedido.ship,
      taxamt: pedido.taxamt,
      totalamt: pedido.totalamt,
      total: pedido.total,
      estados_id: pedido.estados_id,
      totalfinal: pedido.ship + pedido.total,
      productospedidos: pedido.productospedidos
      

    };

    const headers = new HttpHeaders ({
      'Authorization': this.userToken
    });
    return this.http.post( `${this.url}`, productoData, {headers} );
    
  }


  Pedidopagado( pedidopagado: string, idpedido: number ) {
    this.userToken = this.auth.leerToken();
    let endpoint =  '/pedidos/pagado' ;
    this.url = this.apiurl + endpoint;
    const Data = {
      
      id: idpedido,
      pagado: pedidopagado,
      redsyspagos_id: "2022"+idpedido 
  
    };
  
    const headers = new HttpHeaders ({
      'Authorization': this.userToken
    });
    return this.http.post( `${this.url}`, Data, {headers} ).pipe(map( resp => {
      
      return resp as PedidosModel ;
    } ));
  }

  Pedidoabonado( pedidopagado: string, idpedido: number ) {
    this.userToken = this.auth.leerToken();
    let endpoint =  '/pedidos/abonado' ;
    this.url = this.apiurl + endpoint;
    const Data = {
      
      id: idpedido,
  
    };
    const headers = new HttpHeaders ({
      'Authorization': this.userToken
    });
    return this.http.post( `${this.url}`, Data, {headers} ).pipe(map( resp => {
      
      return resp as PedidosModel ;
    } ));
  }


  getPedido(id) {
    this.userToken = this.auth.leerToken();
    let endpoint =  '/pedidos/'+id;
    this.url = this.apiurl + endpoint ;
    const headers = new HttpHeaders ({
      'Authorization': this.userToken
    });
    return this.http.get( `${this.url}`, {headers});
  }


  getPedidofactura(idpedido) {
    let endpoint =  '/pedidos/factura';
    this.url = this.apiurl + endpoint ;
    const productoData = {
      id: idpedido,
    }

    return this.http.post( `${this.url}`, productoData );
  }


  getHistoricopedidos(idcliente){
    let endpoint =  '/pedidos/historico/'+idcliente;
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(map( resp => {
      return Object.values(resp) as HistoricoModel[];
    } ));
  }


  getProductopedido(id) {
    let endpoint =  '/productospedidos/'+id;
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(map( resp => {
      return   resp as ProductospedidosModel;
    } ));
  }
 

  getListaPedidos() {
    let endpoint =  '/pedidos';
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(map( resp => {
      return resp;
    } ));
  }


  getFiles(id) {
    let endpoint =  '/productospedidos/getfiles/'+id;
    this.url = this.apiurl + endpoint ;
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.get( `${this.url}`, {headers})
    .pipe(map( resp => {
      return resp as FilesModel[];
    } ));
  }


  upDocx(file) {
    let endpoint = '/pedidos/updocx';
    this.url = this.apiurl + endpoint ;
    var fileData: FormData = new FormData();
    fileData.append('filedocx', file );

    return this.http.post( `${this.url}`, fileData )
    .pipe(
      map( resp => {
        return  resp;
      }),
      catchError((err) => {
        console.error("logerr " , Object.values(err));
        return err.error;
      })
    );
  }


  getNumDocx(filename) {

    let endpoint =  '/pedidos/getpagdocx/'+filename ;
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(
      map( resp => {
        return  resp;
      }),
      catchError((err) => {
        console.error("logerr " , Object.values(err));
        return err.error;
      })
    );
  }


  deleteDocx(filename) {

    let endpoint =  '/pedidos/deletedocx/'+filename ;
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(
      map( resp => {
        return  resp;
      }),
      catchError((err) => {
        console.error("logerr " , Object.values(err));
        return err.error;
      })
    );
  }



  filedescargado(idfile) {
    this.userToken = this.auth.leerToken();
    let endpoint =  '/productospedidos/filedescargado';
    this.url = this.apiurl + endpoint ;
    const productoData = {
      id: idfile
    };
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.post( `${this.url}`, productoData, {headers}).pipe(map( resp => {
      
      return  resp as FilesModel;
  } ));
  }


  pedidoacabado(idpedido) {
    this.userToken = this.auth.leerToken();
    let endpoint =  '/pedidos/acabado';
    this.url = this.apiurl + endpoint ;
    const productoData = {
      id: idpedido
    };
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.post( `${this.url}`, productoData, {headers}).pipe(map( resp => {
      return  resp as CartModel;
  } ));
  }

  
  pedidoenviado(idpedido, enlacelogistica) {
    this.userToken = this.auth.leerToken();
    let endpoint =  '/pedidos/enviado';
    this.url = this.apiurl + endpoint ;
    const productoData = {
      id: idpedido,
      logistica: enlacelogistica
    };
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.post( `${this.url}`, productoData, {headers})
    .pipe(map( resp => {
       return  resp as CartModel;
    } ));
  }


  getFacturasFilt(fechai :string, fechaf :string) {
    this.userToken = this.auth.leerToken();
    let endpoint = '/pedidos/filt' ;
    this.url = this.apiurl + endpoint;
    const filtData = {
      fechaini: fechai,
      fechafin: fechaf
    };
    
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.post( `${this.url}`, filtData, {headers} )
    .pipe(map( resp => {
        return  resp;
    } ));
  }


  getPedidosFilt(fechai :string, fechaf :string) {
    this.userToken = this.auth.leerToken();
    let endpoint = '/pedidos/filt' ;
    this.url = this.apiurl + endpoint;
    const filtData = {
      fechaini: fechai,
      fechafin: fechaf
    };

    
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.post( `${this.url}`, filtData, {headers} )
    .pipe(map( resp => {
        return  resp;
    } ));
  }

  
  deletePedido(id) {
    this.userToken = this.auth.leerToken();
    let endpoint = '/pedidos/eliminar/' + id ;
    this.url = this.apiurl +  endpoint ;
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.get( `${this.url}`, { headers }  )
    .pipe(map( resp => {
        return  resp;
    } ));
  }


  //get cart de localstorage, si no existe se guarda por primera vez
  getCart(){
    var cart : CartModel = new CartModel();
    //pasar json de pedido a modelo Pedido
 
    if (localStorage.getItem('cart')) {
      var res = localStorage.getItem('cart');
 
      cart =  JSON.parse(res) as CartModel;
    }
    return cart;
  }

  getPendientepago(){
    var res:any = null;

    if (localStorage.getItem('pagopendiente')) {
      res = localStorage.getItem('pagopendiente');
    }
    return res;
  }
  

  haveCart(){ 
    if (localStorage.getItem('cart')) {
      return true;
    }
    return false;
  }


  saveCart(cart: CartModel){
    //create update localstorage
    var c = JSON.stringify(cart);
    localStorage.setItem('cart', c);
  }


  deleteFileProduct(index, idcurrent){
    var cart: CartModel = this.getCart();
    cart.productospedidos.forEach((el)=>{
        if(el.id == idcurrent){
          el.files.splice(index, 1);
        }
    });
    this.saveCart(cart);
  }


  deleteProductCart(index){
    var cart: CartModel = this.getCart();
    cart.productospedidos.splice(index, 1);
    this.saveCart(cart);
  }

  
  getPagoRedsys(total, zeta, numpedido?) {
    let endpoint = '/pedidos/paramsRedsys' ;
    this.url = this.apiurl + endpoint;
    let total100 = Number(total) * 100;
    const Data = {
      AMOUNT: total100,
      DS_MERCHANT_PAYMETHODS: zeta,
      DS_MERCHANT_ORDER: "2022"+ numpedido.toString()
    };
    
    return this.http.post( `${this.url}`, Data )
    .pipe(               
      map( resp => {
        return  resp;
      }),
      catchError((err) => {
        console.error("logerr " , Object.values(err));
        return err.error;
      })
    );
  }


  savPendientepago(idpedido: string){
    //create update localstorage
    let pedidopendientepago = idpedido;
    // let c = JSON.stringify(pedidopendientepago);
    localStorage.setItem('pagopendiente', pedidopendientepago);
  }


  resetCart() {
    while (localStorage.getItem("cart") != null) {
      localStorage.removeItem("cart");
    }
  }

  resetPagopendiente() {
    while (localStorage.getItem("pagopendiente") != null) {
      localStorage.removeItem("pagopendiente");
    }
  }

  emailPago(pedido: CartModel){
    this.userToken = this.auth.leerToken();
    let endpoint = '/pedidos/emailpago' ;
    this.url = this.apiurl + endpoint;
    const filtData = {
      id: pedido.id
    }
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.post( `${this.url}`, filtData, {headers} )
    .pipe(map( resp => {
        return  resp;
    } ));
  }








}

