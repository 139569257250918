import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute  } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { PrintsidesModel } from 'src/app/models/Printsides.model';
import { FinishingsModel } from 'src/app/models/Finishings.model';
import { PapercolorsModel } from 'src/app/models/Papecolors.model';
import { PagesizesModel } from 'src/app/models/Pagesizes.model';
import { PaperweightsModel } from 'src/app/models/Paperweights.model';
import { PageorientationsModel } from 'src/app/models/Pageorientations.model';
import { PagesheetsModel } from 'src/app/models/Pagesheets.model';
import { ServiciosService } from '../../services/servicios.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { resetCompiledComponents } from '@angular/core/src/render3/jit/module';
import { ListasService } from '../../services/listas.service';
import { AlertasService } from '../../services/alertas.service';
import { CartModel } from 'src/app/models/Cart.model';
import { FilesModel } from 'src/app/models/Files.model';
import { CartService } from 'src/app/services/cart.service';
import { ClientesService } from 'src/app/services/clientes.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProductospedidosModel } from 'src/app/models/Productospedidos.model';
import { EncuadernardocsModel } from 'src/app/models/Encuadernardocs.model';
import { ColoresModel } from 'src/app/models/Colores.model';
import { BonosModel } from 'src/app/models/Bonos.model';
import { BonosService } from 'src/app/services/bonos.service';

@Component({
    selector: 'app-cartadmin',
    templateUrl: './cartadmin.component.html',
    styleUrls: ['./cartadmin.component.css']
  })
  export class CartadminComponent implements OnInit {

    @Output() menuAdmin: EventEmitter<any> = new EventEmitter();
    
    forma: FormGroup;
    modal: NgbModalRef;
    modalOptions: NgbModalOptions;
    btactivado: boolean ;
    isAdmin: boolean;
    pagi: number = 1;
    cart: CartModel;
    archivos: FilesModel[] = [];
    totalGrupos: number;
    totalp: number;
    pedidosadmin: CartModel[];
    pedido: CartModel;
    idsel ;
    logisticasel: string = "" ;
    clientesel: string ;
    fechasel: string ;
    totalsel: string ;
    productospedidosel: ProductospedidosModel[] = [];
    finishings = [];
    encuadernardocs = [];
    anillascolor = [];
    tapatraseras = [];
    tapadelanteras = [];
    printsides = [];
    papercolors = [];
    pagesizes = [];
    paperweights = [];
    pageorientations = [];
    pagesheets = [];
    filesd = [];
    acabado: boolean;

    formaF: FormGroup;
    fechainifiltrosel: string;
    fechafinfiltrosel: string;
    btactivadoF;
    public loading: boolean;

    bonos = [];

    constructor(
        private ro: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private modalService: NgbModal,
        private alertasService: AlertasService,
        private listasService: ListasService,
        private cartService: CartService,
        private clientesService: ClientesService,
        private bonosService: BonosService
    )
    {
      this.loading = false;
      this.btactivado = false;
      this.pedidosadmin = [];
      this.pedido = new CartModel();
      this.getFinishingsall();
      this.getPagesizesall();
      this.getPapercolorsall();
      this.getPapersheetsall();
      this.getPaperweightsall();
      this.getPrintsidesall();
      this.getPageorientationsall();
      this.getEncuadernardocsall();
      this.getColoresall();

      this.acabado = false;
      this.menuAdmin.emit();

      var fechaff = new Date(Date.now());
      this.fechafinfiltrosel =
        fechaff.getFullYear() +
        "-" +
        (fechaff.getMonth() + 1) +
        "-" +
        fechaff.getDate();
      var fechaii = fechaff;
      this.fechainifiltrosel =
        fechaii.getFullYear() +
        "-" +
        fechaii.getMonth() +
        "-" +
        fechaii.getDate();
      this.crearFormularioF();
      this.cambiosFormularioF();
    }
    
    
    ngOnInit() {
      
      this.getPeticionesAdmin(this.fechainifiltrosel, this.fechafinfiltrosel);
  
      this.modalOptions = {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
        centered: true,
        size: 'lg'
      }
      
    }
  
  
    getPeticionesAdmin(fechai, fechaf) {
      this.loading = true;
      this.cartService.getPedidosFilt(fechai, fechaf)
      .subscribe( resp => {
    
        this.pagi = 1;
        this.pedidosadmin = resp as CartModel[];

        setTimeout(() => {
          this.loading = false;
          }, 300);
      });
    }

    passConfirmar() {
      return (formGroup: FormGroup) => {
        const fi = formGroup.controls["fechaini"];
        const ff = formGroup.controls["fechafin"];
        let ffi = Date.parse(fi.value);
        let fff = Date.parse(ff.value);
  
        this.btactivadoF = true;
        ff.setErrors({ no: false });
        if (ffi > fff) {
          this.btactivadoF = false;
          ff.setErrors({ no: true });
        }
      };
    }
  
    crearFormularioF() {
      this.formaF = this.fb.group(
        {
          fechaini: [this.fechainifiltrosel, [Validators.required]],
          fechafin: [this.fechafinfiltrosel, [Validators.required]],
        },
        {
          validators: this.passConfirmar(),
        }
      );
    }
  
    cambiosFormularioF() {
      this.formaF.valueChanges.subscribe((value) => {
        this.fechainifiltrosel = this.formaF.get("fechaini").value;
        this.fechafinfiltrosel = this.formaF.get("fechafin").value;
        if (this.formaF.status != "INVALID") {
          this.btactivadoF = true;
        }
      });
    }
  
    filtrar() {
      this.getPeticionesAdmin(this.fechainifiltrosel, this.fechafinfiltrosel);
    }
  
    filtrarenter(event) {
      var codigo = event.which || event.keyCode;
      if (codigo === 13) {
        this.filtrar();
      }
    }
  

    cambiarpagado( val: string , pedido: CartModel){

      let espagado = (val == 'T' ? 'PAGADO' : 'NO PAGADO' );
      this.alertasService.alertaConfirmar("¿SEGURO QUE DESEA CAMBIAR A "+espagado+" ?","Se cambiará su estado")
      .then((res) => {
        if (res.value) {
          this.cartService.Pedidopagado(val , pedido.id).subscribe( resp => {
            if (resp) {
                this.alertasService.alertaOK("REGISTRO REALIZADO","se ha actualizado la lista")
                .then(
                    this.getPeticionesAdmin(this.fechainifiltrosel, this.fechafinfiltrosel) 
                );
            }
            this.resetear();
          }, error => { 
            console.log(error);
          });          
        }
      }, error => { 
        console.log(error);
      });
    }


    emailpago( pedido: CartModel){
      this.alertasService.alertaConfirmar("¿DESEA ENVIAR UN MAIL AL CLIENTE PARA QUE REALICE EL PAGO POR TRANSFERENCIA ?","Se le enviarán los datos del pedido")
      .then((res) => {
        if (res.value) {
          this.cartService.emailPago(pedido).subscribe( resp => {
            if (resp) {
                this.alertasService.alertaOK("ENVÍO REALIZADO"," Se ha notificado el requerimiento de pago al cliente ")
                .then(
                    this.getPeticionesAdmin(this.fechainifiltrosel, this.fechafinfiltrosel) 
                );
            }
            this.resetear();
          }, error => { 
            console.log(error);
          });          
        }
      }, error => { 
        console.log(error);
      });
    }

    open(content, pedido: CartModel ) {
    
      if(pedido != undefined){
        this.btactivado = true;
        this.idsel = pedido.id ;
        this.clientesel = pedido.cliente ;
        this.fechasel = pedido.created.toString() ;
        this.productospedidosel = pedido.productospedidos;
  
        this.productospedidosel.forEach((el: ProductospedidosModel) =>{
          el.pagesizes = this.getpagesizes(el.pagesizes_id);
          el.paperweights = this.getpaperweights(el.paperweights_id);
          el.papercolors = this.getpapercolors(el.papercolors_id);
          el.pagesheets = this.getpagesheets(el.pagesheets_id);
          el.pageorientations = this.getpageorientations(el.pageorientations_id);
          el.finishings = this.getfinishing(el.finishings_id);
          el.encuadernardocs = this.getencuadernardocs(el.encuadernardocs_id);
          el.anillascolor = this.getanillascolor(el.anillascolor_id);
          el.tapasdelantera = this.gettapadelanteras(el.tapadelantera_id);
          el.tapastrasera = this.gettapatraseras(el.tapatrasera_id);
          el.nombrebono = this.getbonos(el.tipobonos_id);

          this.cartService.getFiles(el.id).subscribe((resp)=>{
            if(resp){
              el.files = resp as FilesModel[];
            }  
          });
        })

        this.modal = this.modalService.open(content, this.modalOptions);
      }
    }

    writelogistica(ev){
        this.logisticasel = ev.currentTarget.value;
    }


    descargado(ev){
  
      let id = parseInt(ev.currentTarget.id.split('-')[1]);
      let idi = parseInt(ev.currentTarget.id.split('-')[2]);
      let idp = parseInt(ev.currentTarget.id.split('-')[3]);
      let prodi = parseInt(ev.currentTarget.id.split('-')[4]);
      this.cartService.filedescargado(id).subscribe((resp: FilesModel)=>{
    
        let fil = resp ;
        this.productospedidosel[prodi].files[idi] = fil;
        this.pedidoacabado(this.idsel);
      });
      
    }

    pedidoacabado(idped){
  
      this.acabado = true;
        this.productospedidosel.forEach( (el)=>{
          el.files.forEach( (itel)=>{
            if(itel.descargado != 'T'){
              this.acabado = false;
            }
          })
        })
        if(this.acabado){
          this.cartService.pedidoacabado(idped).subscribe((re)=>{
            if(re){
              //lista de pedidos actualizada
              this.getPeticionesAdmin(this.fechainifiltrosel, this.fechafinfiltrosel);
            }
          });
        }
    }

    getFinishingsall(){
      this.listasService.getFinishings().subscribe((resp)=>{
        if(resp){
          this.finishings = Object.values(resp) as FinishingsModel[];
        }
      });
    }

    getEncuadernardocsall(){
      this.listasService.getEncuadernardocs().subscribe((resp)=>{
        if(resp){
          this.encuadernardocs = Object.values(resp) as EncuadernardocsModel[];
        }
      });
    }

    getColoresall(){
      this.listasService.getColores().subscribe((resp)=>{
        if(resp){
          let lista = Object.values(resp) as ColoresModel[];
          this.anillascolor = lista.filter(x=>x.anillas == 'T');
          this.tapadelanteras = lista.filter(x=>x.tapadelantera == 'T');
          this.tapatraseras = lista.filter(x=>x.tapatrasera == 'T');
        }
      });
    }
    
    getPrintsidesall() {
      this.listasService.getPrintsides().subscribe((resp)=>{
          if(resp){
            this.printsides = Object.values(resp) as PrintsidesModel[];
          }
        });
    }
  
    getPapercolorsall() {
      this.listasService.getPapercolors().subscribe((resp)=>{
          if(resp){
            this.papercolors = Object.values(resp) as PapercolorsModel[];
          }
        });
    }
    
    getPagesizesall() {
      this.listasService.getPagesizes().subscribe((resp)=>{
          if(resp){
            this.pagesizes = Object.values(resp) as PagesizesModel[];
          }
        });
    }
    
    getPaperweightsall(){
      this.listasService.getPaperweights().subscribe((resp)=>{
          if(resp){
            this.paperweights = Object.values(resp) as PaperweightsModel[];
          }
        });
    }
    
    getPageorientationsall(){
      this.listasService.getPageorientations().subscribe((resp)=>{
          if(resp){
            this.pageorientations = Object.values(resp) as PageorientationsModel[];
          }
        });
    }
    
    getPapersheetsall(){
      this.listasService.getPagesheets().subscribe((resp)=>{
          if(resp){
            this.pagesheets = Object.values(resp) as PagesheetsModel[];
          }
        })
    }

    getBonosall(){
      this.bonosService.getListaBonos().subscribe((resp)=>{
          if(resp){
            this.bonos = Object.values(resp) as BonosModel[];
          }
        })
    }

     getfinishing(id){
      let nombre = "";
      this.finishings.forEach(function(el){
        if(el.id == id){
          nombre = el.descripcion ;
        }
      })
      return nombre;
    }

     getencuadernardocs(id){
      let nombre = "";
      this.encuadernardocs.forEach(function(el){
        if(el.id == id){
          nombre = el.nombre ;
        }
      })
      return nombre;
    }

     getanillascolor(id){
      let nombre = "";
      this.anillascolor.forEach(function(el){
        if(el.id == id){
          nombre = el.nombre ;
        }
      })
      return nombre;
    }

     gettapadelanteras(id){
      let nombre = "";
      this.tapadelanteras.forEach(function(el){
        if(el.id == id){
          nombre = el.nombre ;
        }
      })
      return nombre;
    }

     gettapatraseras(id){
      let nombre = "";
      this.tapatraseras.forEach(function(el){
        if(el.id == id){
          nombre = el.nombre ;
        }
      })
      return nombre;
    }
  
     getprintsides(id){
      let nombre = "";
       this.printsides.forEach((el)=>{
         if(el.id == id){
          nombre = el.nombre ;
        }
      })
      return nombre;
    }

     getpapercolors(id){
      let nombre = "";
      this.papercolors.forEach(function(el){
        if(el.id == id){
          nombre = el.nombre ;
        }
      })
      return nombre;
    }
  
     getpagesizes(id){
      let nombre = "";
      this.pagesizes.forEach(function(el){
        if(el.id == id){
          nombre = el.nombre ;
        }
      })
      return nombre;
    }
  
     getpaperweights(id){
      let nombre = "";
      this.paperweights.forEach(function(el){
        if(el.id == id){
          nombre = el.nombre ;
        }
      })
      return nombre;
    }
  
     getpageorientations(id){
      let nombre = "";
      this.pageorientations.forEach(function(el){
        if(el.id == id){
          nombre = el.nombre ;
        }
      })
      return nombre;
    }
  
    getpagesheets(id){
      let nombre = "";
      this.pagesheets.forEach(function(el){
        if(el.id == id){
          nombre = el.num ;
        }
      })
      return nombre;
    }

    getbonos(id){
      let nombre = "";
      this.bonos.forEach(function(el){
        if(el.id == id){
          nombre = el.nombre ;
        }
      })
      return nombre;
    }

    enviarpedido(){
      if(this.idsel != null) {

        this.cartService.pedidoenviado(this.idsel, this.logisticasel).subscribe((resp)=> {
          if (resp) {
            this.modal.dismiss();
            this.alertasService.alertaOK("ENVÍO REGISTRADO","se ha actualizado de la lista")
            .then(
              this.getPeticionesAdmin(this.fechainifiltrosel, this.fechafinfiltrosel)
            )
          }
        })
      }
    }


    getNum(lista){
      return Object.values(lista).length;
    }
    

    cerrarmodal(content){
      this.resetear();
      this.modal.dismiss();
    }


    eliminarPedido(pedido: CartModel) {
      this.alertasService.alertaWarning("¿SEGURO QUE DESEA ELIMINAR?","Se eliminara definitivamente de la App")
      .then((res) => {
        if (res.value) {
          this.cartService.deletePedido(pedido.id).subscribe( resp => {
            if (resp) {
                this.alertasService.alertaOK("REGISTRO ELIMINADO","se ha actualizado de la lista")
                .then(
                    this.getPeticionesAdmin(this.fechainifiltrosel, this.fechafinfiltrosel) 
                );
            }
            this.resetear();
          }, error => { 
            console.log(error);
          });          
        }
      }, error => { 
        console.log(error);
      });
    }
  

    resetear(){
      this.idsel = null; 
      this.logisticasel = null; 
      this.clientesel = "";
      this.fechasel = "";
      this.totalsel = "";
      this.productospedidosel = [];

      this.pedido.clientes_id  = null;
      this.pedido.total  = 0;
      this.pedido.created  = null;
      this.pedido.productospedidos = [];

    }


  
  }
  
