import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ListasService } from '../../services/listas.service';

@Component({
  selector: 'app-historicocliente',
  templateUrl: './historicocliente.component.html',
  styleUrls: ['./historicocliente.component.css']
})
export class HistoricoclienteComponent implements OnInit {



  constructor(
      private router: Router
  ) { }

  ngOnInit() {

  }





}
