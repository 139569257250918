import { transformAll } from '@angular/compiler/src/render3/r3_ast';
import { Directive, EventEmitter,ElementRef, HostListener, Input, Output, HostBinding  } from '@angular/core';
import { FilesModel } from 'src/app/models/Files.model';
import { CargaimagenesService } from '../services/cargaimagenes.service';


@Directive({
  selector: '[appNgDropFiles]'
})
export class NgDropFilesDirective {

  @Output() mouseSobre: EventEmitter<boolean> = new EventEmitter();
  @Output() nuevoDoc: EventEmitter<any[]> = new EventEmitter();
  @Input() archivos: FilesModel[] = [];


  constructor(private cargaimagenesService: CargaimagenesService) { 
  }

  @HostListener('dragover', ['$event'])
  public onDragEnter( event: any){
  
    this.mouseSobre.emit(true);
    this._prevenirDetener(event);
  }
  @HostListener('dragleave', ['$event'])
  public onDragLeave( event: any){
    if(event.target.id == "xdrag" || event.target.id == "xdrag2"){
      setTimeout(()=>this.mouseSobre.emit(false), 500);
    }
    this._prevenirDetener(event);
  }
  @HostListener('drop', ['$event'])
  public onDrop( event: any){
    const transferencia = this._getTransfer(event);
    if(!transferencia){
      return;
    }
    this._extraerArchivos(transferencia.files);
    this.mouseSobre.emit(false);
    this._prevenirDetener(event);
  }

  private _getTransfer( event: any){
    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer ;
  }

  private _extraerArchivos(archivosLista: FileList){
    
    for (const propiedad in Object.getOwnPropertyNames(archivosLista)){
      const archivoTemporal = archivosLista[propiedad];
      if(this._archivoPuedeSerCargado(archivoTemporal)){
        const nuevo = new FilesModel();
        nuevo.archivo = archivoTemporal;
        nuevo.originalname = archivoTemporal.name;
        nuevo.contenttype = archivoTemporal.type;
        nuevo.size = archivoTemporal.size;

        //pages
        // var count = 0;
        // var reader = new FileReader();
        // reader.readAsBinaryString(archivoTemporal);
        // reader.onloadend  = function(){
        //   var res = reader.result; 
        //   count =  res.toString().match(/\/Type[\s]*\/Page[^s]/g).length;
        // }

        this.archivos.push(nuevo);
        if(nuevo){
          //var nuevo_firebase = [];
          this.cargaimagenesService.cargararchivofirebase(nuevo).then(function(task){
            nuevo.firebase_id = task.ref.bucket;
            nuevo.firebase_path = task.ref.fullPath;
            task.ref.getDownloadURL().then(function(res){
            nuevo.firebase_url = res;
            });
          });
        }
        

      }
    }
  }

  //validaciones
  private _prevenirDetener(event){
    event.preventDefault();
    event.stopPropagation();

  }

  private _archivoPuedeSerCargado( file: File ): boolean{
    if( !this._archivoDrop( file.name) && this._esArchivo(file.type) ){
      return true;
    }
    return false;
  }

  private _archivoDrop(nombre: string){
    for( let archivo of this.archivos){

      if(archivo.originalname == nombre ){
        console.log('archivo se puede agregar '+ nombre);
        return true;
      }
    }
    return false;
  }

  private _esArchivo(tipo: string): boolean{
    return (tipo == '' || tipo == undefined) ? false : true;
  }

}
