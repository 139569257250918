import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { PersonasService } from '../../../services/personas.service';
import { ClientesService } from '../../../services/clientes.service';
import { AlertasService } from '../../../services/alertas.service';
import { LoginComponent } from 'src/app/components/login/login.component';
import { RegistroComponent } from 'src/app/components/registro/registro.component';
import { PerfilComponent } from 'src/app/components/perfil/perfil.component';
import { ClientesModel } from 'src/app/models/Clientes.model';
import { ForbiddComponent } from 'src/app/components/forbidd/forbidd.component';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styles: []
})
export class NavbarComponent implements OnInit {

  @ViewChild(LoginComponent) loginentrar;
  @ViewChild(RegistroComponent) registguardar;
  @ViewChild(PerfilComponent) perfilguardar;
  @ViewChild(ForbiddComponent) forbiddentrar;
  
  
  nombreusuario: string = "";
  registrado: boolean = false;

  ocultar: string = "";
  ocultado: boolean = false;
  adminactive: boolean;
  cliente: ClientesModel;
  clienteid: boolean;
  
  constructor(
    private auth: AuthService,
    private personasService: PersonasService,
    private clientesService: ClientesService,
    private alertasService: AlertasService,
    private router: Router
  ) {
    //leer persona
    this.adminactive = false;
  }
  ngOnInit() {
    this.loginadmin();
    
  }

  esCliente(){
    this.cliente = this.clientesService.getClienteLocal() ;
    this.clienteid = false;
    this.registrado = false;
    if(this.cliente.id != null){
      this.clienteid = true;
      this.nombreusuario = this.cliente.nombre;
      this.registrado = true;
    }
  }


  menu() {
      this.ocultar == "" ? this.ocultar = "active" :  this.ocultar = "" ;
  }

  loginadmin() {
    let personalocal= localStorage.getItem('persona');
    if(personalocal != null){
      this.adminactive = true;
    }
  }

  loginadminout() {
    this.personasService.deletePersonaLocal();
    this.logout();
  }

  getCliente(ev){
    
    if(this.router.url == "/checkoutcliente"){
      window.location.reload();
    }
  }

  enlacecontacto() {
      
    window.open('https://easycopy.es/contacto');
  }

  enlaceprivacidad() {
      
    window.open('https://easycopy.es/privacidad');
  }

  enlaceterminos() {
      
    window.open('https://easycopy.es/terminos');
  }

  registrar(){
    this.registguardar.registro();
  }

  perfil(){
    this.esCliente();
    if(this.clienteid){
      this.perfilguardar.perfil();
    }
    else{
     this.alertasService.alertaKO("Usuario no registrado", "registre sus datos para acceder su información como cliente").then();
    }
  }

  identificar(){
    this.loginentrar.login();
  }

  forbidd(){
    this.forbiddentrar.forbidd();
  }

  onActivate(componentReference) {
    if(componentReference.loger != null){
      componentReference.loger.subscribe((e) => {
      this.loginentrar.login();
      });
    }
    if(componentReference.register != null){
      componentReference.register.subscribe((e) => {
      this.registguardar.registro();
      });
    }
    if(componentReference.menuAdmin != null){
     this.loginadmin();
    }
    if(componentReference.isloginok != null){
     this.esCliente();
    }

    
 }


  esmovil() {
    var ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua) ;
  }

  logout() {
    this.adminactive = false;
    this.auth.logout();
    this.esCliente();
  }


}