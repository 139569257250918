import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SystemJsNgModuleLoader,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";

import { ServiciosService } from "../../services/servicios.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { resetCompiledComponents } from "@angular/core/src/render3/jit/module";
import { ListasService } from "../../services/listas.service";
import { AlertasService } from "../../services/alertas.service";
import { CartService } from "../../services/cart.service";
import { ClientesService } from "../../services/clientes.service";
import { TipofilesService } from "../../services/tipofiles.service";
import { CargaimagenesService } from "../../services/cargaimagenes.service";
import { PreciosService } from "../../services/precios.service";
import { ProductosService } from "../../services/productos.service";

import { DocsModel } from "src/app/models/Docs.model";
import { PreciosModel } from "src/app/models/Precios.model";
import { ProductoModel } from "src/app/models/Producto.model";
import { FilesModel } from "src/app/models/Files.model";
import { CartModel } from "src/app/models/Cart.model";
import { ClientesModel } from "src/app/models/Clientes.model";
import { TipofilesModel } from "src/app/models/Tipofiles.model";
import { EncuadernardocsModel } from "src/app/models/Encuadernardocs.model";
import { ProductospedidosModel } from "src/app/models/Productospedidos.model";

import { BootstrapOptions } from "@angular/core/src/application_ref";
import { PDFDocument } from "pdf-lib";
import { PrintsidesModel } from "src/app/models/Printsides.model";
import { FinishingsModel } from "src/app/models/Finishings.model";
import { PapercolorsModel } from "src/app/models/Papecolors.model";
import { PagesizesModel } from "src/app/models/Pagesizes.model";
import { PaperweightsModel } from "src/app/models/Paperweights.model";
import { PrintformsModel } from "src/app/models/Printforms.model";
import { PageorientationsModel } from "src/app/models/Pageorientations.model";
import { PagesheetsModel } from "src/app/models/Pagesheets.model";
import {
  nullSafeIsEquivalent,
  THIS_EXPR,
} from "@angular/compiler/src/output/output_ast";
import { TipoenviosModel } from "src/app/models/Tipoenvios.model ";
import { HistoricoModel } from "src/app/models/Historico.model";
import { readFile, readFileSync, writeFileSync } from "file-system";

import { ColoresModel } from "src/app/models/Colores.model";

@Component({
  selector: "app-homecliente",
  templateUrl: "./homecliente.component.html",
  styleUrls: ["./homecliente.component.css"],
})
export class HomeclienteComponent implements OnInit {

  @Output() isloginok: EventEmitter<any> = new EventEmitter();
  @Input() clientex;

  sinprecios: boolean = true;
  estaSobreDrop: boolean;
  file: File;
  archivos: FilesModel[] = [];
  precios: PreciosModel[] = [];
  productopedido: ProductospedidosModel;
  producto: ProductoModel;
  cart: CartModel;
  cliente: ClientesModel;
  actunumprod: number;
  histo: HistoricoModel[] = [];
  finishings: FinishingsModel[] = [];
  printsides = [];
  papercolors = [];
  pagesizes = [];
  paperweights = [];
  printform = [];
  pageorientations = [];
  pagesheets = [];
  encuadernardocs = [];
  anillacolores = [];
  tapadelanteras = [];
  tapatraseras = [];
  tipofiles = [];

  total: number;
  totalp: number;
  pags: number;
  copias: number;
  tampap: number;
  grampap: number;
  formimp: number;
  tipimp: number;
  encuad: number;
  colores: number;
  anillacol: number;
  tapadcol: number;
  tapatcol: number;

  veranillas: boolean;
  vertapat: boolean;
  vertapad: boolean;

  portadasacolor: boolean;
  paginasporcara: number;
  rotacionauto: boolean;
  orientacionpapel: number;
  acabado: number;
  caras: number;
  peso: number;
  tipo: number;
  elid: number;
  finishingsname: string;
  printsidesname: string;
  papercolorsname: string;
  pagesizesname: string;
  paperweightsname: string;
  printformname: string;
  pageorientationsname: string;
  pagesheetsname: string;
  indexpp: number;

  coloresanillas: ColoresModel[] = [];
  colorestapadelantera: ColoresModel[] = [];
  colorestapatrasera: ColoresModel[] = [];
  coloreshexa: ColoresModel[] = [];

  encuadernadoc: string;
  anillascolor: string;
  tapadelanteracolor: string;
  tapatraseracolor: string;
  anillascolorhexa: string;
  tapadelanterahexa: string;
  tapatraserahexa: string;

  precioOK: boolean;
  precio0_1: PreciosModel = new PreciosModel();
  precio0_2: PreciosModel = new PreciosModel();

  precio1_1: PreciosModel = new PreciosModel();
  precio1_2: PreciosModel = new PreciosModel();
  precio1_3: PreciosModel = new PreciosModel();

  precio2_1: PreciosModel = new PreciosModel();
  precio2_2: PreciosModel = new PreciosModel();

  precio20_1: PreciosModel = new PreciosModel();
  precio20_2: PreciosModel = new PreciosModel();

  precio3_1: PreciosModel = new PreciosModel();
  precio3_2: PreciosModel = new PreciosModel();
  precio3_3: PreciosModel = new PreciosModel();

  precio4_1: PreciosModel = new PreciosModel();

  precio5_1: PreciosModel = new PreciosModel();
  precio5_2: PreciosModel = new PreciosModel();
  precio5_3: PreciosModel = new PreciosModel();

  precio6_1: PreciosModel = new PreciosModel();

  precio7_1: PreciosModel = new PreciosModel();
  precio7_2: PreciosModel = new PreciosModel();
  precio7_3: PreciosModel = new PreciosModel();
  precio7_4: PreciosModel = new PreciosModel();

  precio8_1: PreciosModel = new PreciosModel();
  precio8_2: PreciosModel = new PreciosModel();
  precio8_3: PreciosModel = new PreciosModel();
  precio8_4: PreciosModel = new PreciosModel();
  precio8_5: PreciosModel = new PreciosModel();
  precio8_6: PreciosModel = new PreciosModel();
  precio8_7: PreciosModel = new PreciosModel();
  precio8_8: PreciosModel = new PreciosModel();
  precio8_9: PreciosModel = new PreciosModel();

  idpedido: string;
  constructor(
    private ro: ActivatedRoute,
    private router: Router,
    private listasService: ListasService,
    private alertasService: AlertasService,
    private cartService: CartService,
    private clientesService: ClientesService,
    private cargaimagenesService: CargaimagenesService,
    private preciosService: PreciosService,
    private productosService: ProductosService,
    private tipofilesService: TipofilesService
  ) {
    this.estaSobreDrop = false;
    this.cart = new CartModel();
    this.cart.total = 0;
    this.idpedido = "";
    
    //this.cartService.saveCart(this.cart);//limpiar cart
  }

  async ngOnInit() {
    
    this.getFinishingsall();
    this.getPagesizesall();
    this.getPapercolorsall();
    this.getPapersheetsall();
    this.getPaperweightsall();
    this.getPrintformall();
    this.getPrintsidesall();
    this.getPageorientationsall();
    this.getPrecios();
    this.getcart();
    this.getcliente();
    this.getHisto();
    this.gettipofilesall();
    this.getColoresall();
    this.getEncuadernardocssall();

    ////campos de configuracion inicializados
    this.actunumprod = 0;
    this.total = 0;
    this.totalp = 0;
    this.copias = 0;
    this.tampap = 2;
    this.grampap = 1;
    this.formimp = 1;
    this.tipimp = 1;
    this.encuad = 1;
    this.anillacol = 1;
    this.tapadcol = 1;
    this.tapatcol = 1;
    this.colores = 1;
    this.veranillas = true;
    this.vertapat = false;
    this.vertapad = false;
    this.portadasacolor = false;
    this.paginasporcara = 1;
    this.rotacionauto = false;
    this.orientacionpapel = 4;
    this.acabado = 1;
    this.peso = 0;
    this.tipo = 1;
    this.caras = 1;
    this.elid = null;
    this.finishingsname = "";
    this.printsidesname = "";
    this.papercolorsname = "";
    this.pagesizesname = "";
    this.paperweightsname = "";
    this.printformname = "";
    this.pageorientationsname = "";
    this.pagesheetsname = "";
    this.indexpp = null;
    this.anillascolor = "seleccione color de anillas";
    this.tapadelanteracolor = "seleccione color de la tapadera ";
    this.tapatraseracolor = "seleccione color de la tapadera ";

    this.asignPrecios();
    this.getCurrentp();
    if (this.cart.productospedidos != null) {
      this.indexpp = this.cart.productospedidos.length;
    }

    //parametro id  idpedido seguimiento desde mail
    const xid = this.ro.snapshot.paramMap.get("id");

    if (xid && xid != null && xid != "") {
      //abrir modal con mensaje de como ver histórico de ese pedido
      this.idpedido = xid;
    }

    ///saludo
    let saludo = localStorage.getItem('saludo');
    if(saludo == null){
      this.alertasService.alertaInfo('¡Bienvenido a EasyCopy!','Acceda a login desde el Menú para obtener una mejor experiencia de usuario');
      localStorage.setItem('saludo', 'T');
    }

    this.islogin();
  }

  islogin() {
    this.isloginok.emit();
  }

  //cart /////////////////
  guardarCart(cart: CartModel) {
    //guardar en localstorage
    this.corregirprecioscart();
    cart.total = this.cartService.correct(cart.total);
    return this.cartService.saveCart(cart);
  }

  getcart() {
    this.cart = this.cartService.getCart();
  }

  //cliente /////////////////
  guardarCliente(cliente: ClientesModel) {
    //guardar en localstorage
    return this.clientesService.saveCliente(cliente);
  }

  getcliente() {
    this.cliente = this.clientesService.getClienteLocal();
  }

  //currentp /////////////////
  guardarCurrentp() {
    let ix = 0;
    let xi = 0;
    this.cart.productospedidos.forEach((el: ProductospedidosModel) => {
      if (el.id == parseInt(this.cart.currentproduct)) {
        xi = ix;
      }
      ix++;
    });
    this.cart.productospedidos.splice(xi, 1);
    this.cart.productospedidos.push(this.productopedido);
  }

  //volver atras
  getCurrentp() {
    if (
      this.cart.currentproduct != null &&
      this.cart.currentproduct != undefined
    ) {
      if (this.cart.productospedidos != null) {
        let ix = 0;
        this.cart.productospedidos.forEach((el: ProductospedidosModel) => {
          if (el.id == parseInt(this.cart.currentproduct)) {
            this.productopedido = el;

            this.tampap = this.productopedido.pagesizes_id;
            this.grampap = this.productopedido.paperweights_id;
            this.formimp = this.productopedido.printform_id;
            this.tipimp = this.productopedido.papercolors_id;
            this.portadasacolor =
              this.productopedido.covercolor == "T" ? true : false;
            this.paginasporcara = this.productopedido.pagesheets_id;
            this.rotacionauto =
              this.productopedido.autorotate == "T" ? true : false;
            this.orientacionpapel = this.productopedido.pageorientations_id;
            this.acabado = this.productopedido.finishings_id;
            this.peso = this.productopedido.weight;
            this.copias = this.productopedido.copias;
            this.tipo = this.productopedido.productostipos_id;
            this.encuad = this.productopedido.encuadernardocs_id;
            this.anillacol = this.productopedido.anillascolor_id;
            this.tapadcol = this.productopedido.tapadelantera_id;
            this.tapatcol = this.productopedido.tapatrasera_id;

            this.elid = this.productopedido.id;
            this.archivos = el.files;

            this.totalp = this.cartService.correct(this.productopedido.total);

            this.finishings.forEach(function (el) {
              if (el.id == this.finishings_id) {
                this.finishingsname = el.descripcion;
              }
            });
            this.printsides.forEach(function (el) {
              if (el.id == this.printsides_id) {
                this.printsidesname = el.nombre;
              }
            });
            this.papercolors.forEach(function (el) {
              if (el.id == this.papercolors_id) {
                this.papercolorsname = el.nombre;
              }
            });
            this.pagesizes.forEach(function (el) {
              if (el.id == this.pagesizes_id) {
                this.pagesizesname = el.nombre;
              }
            });
            this.paperweights.forEach(function (el) {
              if (el.id == this.paperweights_id) {
                this.paperweightsname = el.nombre;
              }
            });
            this.printform.forEach(function (el) {
              if (el.id == this.printform_id) {
                this.printformname = el.nombre;
              }
            });
            this.pageorientations.forEach(function (el) {
              if (el.id == this.pageorientations_id) {
                this.pageorientationsname = el.nombre;
              }
            });
            this.pagesheets.forEach(function (el) {
              if (el.id == this.pagesheets_id) {
                this.pagesheetsname = el.num;
              }
            });
            this.encuadernardocs.forEach(function (el) {
              if (el.id == this.encuadernardocs_id) {
                this.encuadernadoc = el.nombre;
              }
            });
            this.anillacolores.forEach(function (el) {
              if (el.id == this.anillacolores) {
                this.anillascolor = el.num;
              }
            });
            this.tapatraseras.forEach(function (el) {
              if (el.id == this.tapatrasera_id) {
                this.tapatrasera = el.nombre;
              }
            });
            this.tapadelanteras.forEach(function (el) {
              if (el.id == this.tapadelantera_id) {
                this.tapadelantera = el.nombre;
              }
            });
          }
          ix++;
        });
      }
    } else {
      this.productopedido = this.getProductoPanel();
    }
    
    if(this.productopedido != null){
      this.productopedido.total = this.cartService.correct(
        this.productopedido.total
      );
    }
  }

  getPrecios() {
    this.preciosService.getListaPrecios().subscribe((resp) => {
      this.precios = resp as PreciosModel[];
    });
  }

  asignPrecios() {
    this.precioOK = true;
    this.precio0_1.valor = 0;
    this.precio0_2.valor = 0;

    this.precio1_1.valor = 0;
    this.precio1_2.valor = 0;
    this.precio1_3.valor = 0;

    this.precio2_1.valor = 0;
    this.precio2_2.valor = 0;

    this.precio3_1.valor = 0;
    this.precio3_2.valor = 0;
    this.precio3_3.valor = 0;

    this.precio4_1.valor = 0;

    this.precio5_1.valor = 0;
    this.precio5_2.valor = 0;
    this.precio5_3.valor = 0;

    this.precio6_1.valor = 0;

    this.precio7_1.valor = 0;
    this.precio7_2.valor = 0;
    this.precio7_3.valor = 0;
    this.precio7_4.valor = 0;

    this.precio8_1.valor = 0;
    this.precio8_2.valor = 0;
    this.precio8_3.valor = 0;
    this.precio8_4.valor = 0;
    this.precio8_5.valor = 0;
    this.precio8_6.valor = 0;
    this.precio8_7.valor = 0;
    this.precio8_8.valor = 0;
    this.precio8_9.valor = 0;

    this.precio20_1.valor = 0;
    this.precio20_2.valor = 0;

    if (
      this.precios != undefined &&
      this.precios != null &&
      this.precios.length > 0
    ) {
      this.precios.forEach((el: PreciosModel) => {
        switch (el.precios_id) {
          case "1":
            this.precio1_1 = el;
            break;
          case "2":
            this.precio1_2 = el;
            break;
          case "3":
            this.precio1_3 = el;
            break;
          case "4":
            this.precio2_1 = el;
            break;
          case "5":
            this.precio2_2 = el;
            break;
          case "6":
            this.precio3_1 = el;
            break;
          case "7":
            this.precio3_2 = el;
            break;
          case "8":
            this.precio4_1 = el;
            break;
          case "9":
            this.precio5_1 = el;
            break;
          case "10":
            this.precio5_2 = el;
            break;
          case "11":
            this.precio5_3 = el;
            break;
          case "12":
            this.precio6_1 = el;
            break;
          case "13":
            this.precio7_1 = el;
            break;
          case "14":
            this.precio7_2 = el;
            break;
          case "15":
            this.precio7_3 = el;
            break;
          case "16":
            this.precio7_4 = el;
            break;
          case "17":
            this.precio8_1 = el;
            break;
          case "18":
            this.precio8_2 = el;
            break;
          case "19":
            this.precio8_3 = el;
            break;
          case "20":
            this.precio8_4 = el;
            break;
          case "21":
            this.precio8_5 = el;
            break;
          case "22":
            this.precio8_6 = el;
            break;
          case "23":
            this.precio8_7 = el;
            break;
          case "24":
            this.precio8_8 = el;
            break;
          case "25":
            this.precio3_3 = el;
            break;
          case "26":
            this.precio8_9 = el;
            break;
          case "27":
            this.precio0_1 = el;
            break;
          case "28":
            this.precio0_2 = el;
            break;
          case "29":
            this.precio20_1 = el;
            break;
          case "30":
            this.precio20_2 = el;
            break;
          default:
            break;
        }
      });
    }
    this.sinprecios = false;
  }

  gettipofilesall() {
    this.tipofilesService
      .getListaTipofiles()
      .subscribe((resp: TipofilesModel[]) => {
        if (resp) {
          this.tipofiles = resp;
        }
      });
  }

  getFinishingsall() {
    return this.listasService
      .getPrintsides()
      .subscribe((resp: FinishingsModel[]) => {
        if (resp) {
          this.finishings = resp;
        }
      });
  }

  getPrintsidesall() {
    return this.listasService
      .getPrintsides()
      .subscribe((resp: PrintsidesModel[]) => {
        if (resp) {
          this.printsides = resp;
        }
      });
  }

  getPapercolorsall() {
    return this.listasService
      .getPapercolors()
      .subscribe((resp: PapercolorsModel[]) => {
        if (resp) {
          this.papercolors = resp;
        }
      });
  }

  getPagesizesall() {
    return this.listasService
      .getPagesizes()
      .subscribe((resp: PagesizesModel[]) => {
        if (resp) {
          this.pagesizes = resp;
        }
      });
  }

  getPaperweightsall() {
    return this.listasService
      .getPaperweights()
      .subscribe((resp: PaperweightsModel[]) => {
        if (resp) {
          this.paperweights = resp;
        }
      });
  }

  getPrintformall() {
    return this.listasService
      .getPrintform()
      .subscribe((resp: PrintformsModel[]) => {
        if (resp) {
          this.printform = resp;
        }
      });
  }

  getPageorientationsall() {
    return this.listasService
      .getPageorientations()
      .subscribe((resp: PageorientationsModel[]) => {
        if (resp) {
          this.pageorientations = resp;
        }
      });
  }

  getPapersheetsall() {
    return this.listasService
      .getPagesheets()
      .subscribe((resp: PagesheetsModel[]) => {
        if (resp) {
          this.pagesheets = resp;
        }
      });
  }

  getEncuadernardocssall() {
    return this.listasService
      .getEncuadernardocs()
      .subscribe((resp: EncuadernardocsModel[]) => {
        if (resp) {
          this.encuadernardocs = resp;
        }
      });
  }

  getColoresall() {
    return this.listasService.getColores().subscribe((resp: ColoresModel[]) => {
      if (resp) {
        this.coloreshexa = resp;
        this.coloresanillas = this.coloreshexa.filter((x) => x.anillas == "T");
        this.colorestapadelantera = this.coloreshexa.filter(
          (x) => x.tapadelantera == "T"
        );
        this.colorestapatrasera = this.coloreshexa.filter(
          (x) => x.tapatrasera == "T"
        );
      }
    });
  }

  //recoger configuracion actual
  getProductoPanel() {
    
    var pp = new ProductospedidosModel();

    pp.id = this.indexpp;
    pp.pagesizes_id = this.tampap;
    pp.paperweights_id = this.grampap;
    pp.printform_id = this.formimp;
    pp.papercolors_id = this.tipimp;
    pp.covercolor = this.portadasacolor ? "T" : "F";
    pp.pagesheets_id = this.paginasporcara;
    pp.autorotate = this.rotacionauto ? "T" : "F";
    pp.pageorientations_id = this.orientacionpapel;
    pp.finishings_id = this.acabado;
    pp.weight = this.peso;
    pp.productostipos_id = this.tipo;
    pp.encuadernardocs_id = this.encuad;
    pp.anillascolor_id = this.anillacol;
    pp.tapadelantera_id = this.tapadcol;
    pp.tapatrasera_id = this.tapatcol;
    pp.encuadernardocs = this.encuadernadoc;
    pp.anillascolor = this.anillascolor;
    pp.tapasdelantera = this.tapadelanteracolor;
    pp.tapastrasera = this.tapatraseracolor;
    pp.copias = this.copias;
    pp.total = this.totalp;

    this.finishings.forEach(function (el) {
      if (el.id == pp.finishings_id) {
        pp.finishings = el.nombre;
      }
    });
    this.papercolors.forEach(function (el) {
      if (el.id == pp.papercolors_id) {
        pp.papercolors = el.nombre;
      }
    });

    this.pagesizes.forEach(function (el) {
      if (el.id == pp.pagesizes_id) {
        pp.pagesizes = el.nombre;
      }
    });
    this.paperweights.forEach(function (el) {
      if (el.id == pp.paperweights_id) {
        pp.paperweights = el.nombre;
      }
    });
    this.printform.forEach(function (el) {
      if (el.id == pp.printform_id) {
        pp.printform = el.nombre;
      }
    });

    this.pageorientations.forEach(function (el) {
      if (el.id == pp.pageorientations_id) {
        pp.pageorientations = el.nombre;
      }
    });

    this.pagesheets.forEach(function (el) {
      if (el.id == pp.pagesheets_id) {
        pp.pagesheets = el.num;
      }
    });

    this.encuadernardocs.forEach(function (el) {
      if (el.id == pp.encuadernardocs_id) {
        pp.encuadernardocs = el.nombre;
      }
    });

    this.anillacolores.forEach(function (el) {
      if (el.id == pp.anillascolor_id) {
        pp.anillascolor = el.nombre;
      }
    });

    this.tapadelanteras.forEach(function (el) {
      if (el.id == pp.tapadelantera_id) {
        pp.tapasdelantera = el.nombre;
      }
    });

    this.tapatraseras.forEach(function (el) {
      if (el.id == pp.tapatrasera_id) {
        pp.tapastrasera = el.nombre;
      }
    });

    if (pp.files == undefined) {
      pp.files = [];
    }
    pp.files = this.archivos;
    pp.id = this.elid;
    if (pp.id == null) {
      pp.id = this.generateId();
    }

    pp.total = this.cartService.correct(pp.total);
    return pp;
  }

  /////////////////////funciones docs
  // añade un archivo
  async handleFileSelect(evt) {

    var files = evt.target.files;
    for (var i = 0, f; (f = files[i]); i++) {
      var nuevo = new FilesModel();
      nuevo.id = new Date().getTime() + new Date().getFullYear();
      nuevo.originalname = f.name;
      nuevo.contenttype = f.type;
      nuevo.size = f.size;
      nuevo.tipofiles_id = this.getTipoFile(f.name);
      nuevo.archivo = f;
      //guardar firebase y retornar archivo actualizado
      let resultadoFirebase = await this.saveFirebase(nuevo);
      //paginas pdf
      nuevo.pages = 1;
      if (nuevo.contenttype.includes("pdf")) {
        const res = this.getpagspdf(nuevo.archivo);
        if (res) {
          nuevo.pages = await res;
        }
      } else if (nuevo.contenttype.includes("doc")) {
      
        let res: any = this.getpagsdocx(nuevo.archivo);
        if (res) {
          nuevo.pages = (await res) as number;
        }
      }

      //se añade a listado de archivos
      this.archivos.push(nuevo);
      //peso se añade
      this.peso = this.peso + nuevo.size;
      //paginas
      this.pags += nuevo.pages;
      this.actualizarprecio();
    }
  }


  async handleFileDrop(evt) {
    
    var files = evt.dataTransfer.files;
    for (var i = 0, f; (f = files[i]); i++) {
      var nuevo = new FilesModel();
      nuevo.id = new Date().getTime() + new Date().getFullYear();
      nuevo.originalname = f.name;
      nuevo.contenttype = f.type;
      nuevo.size = f.size;
      nuevo.tipofiles_id = this.getTipoFile(f.name);
      nuevo.archivo = f;
      //guardar firebase y retornar archivo actualizado
      let resultadoFirebase = await this.saveFirebase(nuevo);
      //paginas pdf
      nuevo.pages = 1;
      if (nuevo.contenttype.includes("pdf")) {
        const res = this.getpagspdf(nuevo.archivo);
        if (res) {
          nuevo.pages = await res;
        }
      } else if (nuevo.contenttype.includes("doc")) {
      
        let res: any = this.getpagsdocx(nuevo.archivo);
        if (res) {
          nuevo.pages = (await res) as number;
        }
      }

      //se añade a listado de archivos
      this.archivos.push(nuevo);
      //peso se añade
      this.peso = this.peso + nuevo.size;
      //paginas
      this.pags += nuevo.pages;
      this.actualizarprecio();
    }
  }


  async saveFirebase(nuevo: FilesModel) {
    //firebase
    const task = await this.cargaimagenesService.cargararchivofirebase(nuevo);
    if (task) {
      nuevo.firebase_id = task.ref.bucket;
      nuevo.firebase_path = task.ref.fullPath;
      const res = await task.ref.getDownloadURL();
      nuevo.firebase_url = res;
    }
    return nuevo;
  }

  async getpagspdf(file: File) {
    const arrayBuffer = await new Response(file).arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    return pdfDoc.getPageCount();
  }

  //pags docx
  async getpagsdocx(file: File) {
    var num : any;    
    let guardo = await this.cartService.upDocx(file).toPromise().then();
    if(guardo[0] != null && guardo[0] != undefined ){
      let res =  guardo[0];
      num = await this.cartService.getNumDocx(res).toPromise().then();
      this.cartService.deleteDocx(res).toPromise().then();
    }
    return num;
  }

  getArchivo(originalname) {
    this.archivos.forEach((el) => {
      if (el.originalname == originalname) {
        return el;
      }
    });
  }

  outestasobre(){
    this.estaSobreDrop = false;
  }

  //drop
  estasobre(ev) {
    if (ev) {
      this.estaSobreDrop = true;
    } else {
      this.estaSobreDrop = false;
    }
  }


  fileup() {
  
    document.getElementById("filesI").click();
  }

  eliminardoc(id) {
    this.alertasService
      .alertaWarning("¿SEGURO QUE DESEA ELIMINAR?", "Se perderá documento añadido")
      .then((res) => {
        if (res.value) {
          let isp = id.split("-");
          let idc = parseInt(isp[1]);
          var indice = 0;
          var size = 0;
          this.archivos.forEach(function (el, i) {
            if (el.id == idc) {
              indice = i;
              if (el.size != null) {
                size = el.size;
              }
            }
          });
          this.peso = this.peso - size;
          this.archivos.splice(indice, 1);
          //quitar weight
          if (this.cart.currentproduct != null) {
            this.cartService.deleteFileProduct(
              indice,
              this.cart.currentproduct
            );
          }
          this.actualizarprecio();
          this.alertasService
            .alertaOK("ELIMINADO", "se ha actualizado de la lista")
            .then();
        }
      });
  }

  //////funciones panel
  finalizarpedido() {
    if (this.archivos.length > 0) {
      this.guardarls();
      this.router.navigate(["checkoutcliente"]);
    } else {
      this.alertasService
        .alertaInfo(
          "Debe subir archivos",
          "pase a carrito de compra si desea ir a zona de pago"
        )
        .then();
    }
  }

  //NUEVA CONFIGURACION
  otropedido() {
	if (this.archivos.length > 0) {
		this.guardarls();
		this.archivos = [];
		this.cart.currentproduct = null;
		this.guardarCart(this.cart);
		this.alertasService.alertaOK(
			"Set guardado satisfactoriamente",
			"puede revisarlo desde el carrito de compra"
		);
		this.total = 0;
		this.totalp = 0;
	}
  }

  guardarls() {
    //bajar
    this.getcart();
    if (
      this.cart.currentproduct != "" &&
      this.cart.currentproduct != undefined &&
      this.cart.currentproduct != null
    ) {
      //si es de reedicion
      this.productopedido = this.getProductoPanel();
      this.guardarCurrentp();
    } else {
      if (this.cart.productospedidos == null) {
        this.cart.productospedidos = [];
      }
      //producto nuevo
      this.productopedido = this.getProductoPanel();
      this.cart.created == null ? new Date() : this.cart.created;
      this.cart.currentproduct = "" + this.productopedido.id;
      this.cart.productospedidos.push(this.productopedido);
    }
    //actualizar total
    this.cart.total = 0;
    if (this.cart.taxamt == 0) {
      this.cart.taxamt = 0.21;
      this.cart.cur = "EUR";
    }
    this.cart.productospedidos.forEach((el) => {
      this.cart.total += el.total;
    });
    this.cart.totalamt = this.cart.total * this.cart.taxamt;
    //subir de nuevo

    this.guardarCart(this.cart);
    this.actualizarnumprod();
  }

  corregirprecioscart() {
    if(this.cart != null && this.cart != undefined){

      this.cart.total = this.cartService.correct(this.cart.total);
      this.cart.totalamt = this.cartService.correct(this.cart.totalamt);
      this.cart.totalbono = this.cartService.correct(this.cart.totalbono == undefined ? 0 : this.cart.totalbono );
      this.total = this.cartService.correct(this.total); 
      this.totalp = this.cartService.correct(this.totalp);
    }
  }

  actualizarnumprod() {
    this.getcart();
    this.actunumprod =
      this.cart.productospedidos != null
        ? this.cart.productospedidos.length
        : 0;
  }

  actualizarnumprodoutput() {
    this.actualizarnumprod();
    this.alertasService.alertaOK(
      "BONO GUARDADO",
      "se ha añadido Bono elegido al pedido"
    );
  }

  generateId(): number {
    let aa = new Date().getTime();
    let n: number = parseInt(aa.toString()) * 8;
    return n;
  }

  menoscopias() {
    if (this.archivos.length > 0) {
      if (this.copias > 0) {
        this.copias--;
        this.actualizarprecio();
      }
    }
  }

  mascopias() {
    if (this.archivos.length > 0) {
      this.copias++;
      this.actualizarprecio();
    }
  }

  ////recalcular precio
  actualizarprecio() {

	if (this.archivos.length > 0) {
		if (this.copias == 0) {
			this.copias = 1;
		}

		if (this.precio1_1.valor == 0) {
			this.asignPrecios();
		}

		//calcular numero de paginas totales
		this.pags = 0;
		this.archivos.forEach((el) => {
			this.pags += el.pages;
		});

		//total segun configuracion
		this.total = 0;

		var tam = 0;
		var tim = 0;
		var gram = 0;
		var fimp = 0;
		var portcolor = 0;
		var orientacion = 0;
		var pagporcara = 0;
		var acabado = 0;
		var encuadporpag = 0;


		// TIPO DE IMPRESION
		switch (this.tipimp) {
		  case 1:			
			if (this.precio0_1.valor != undefined) {
				tim = Math.round(this.precio0_1.valor * 1000) / 1000;
		  	}
			break;
		  case 2:			
			if (this.precio0_2.valor != undefined) {
				tim = Math.round(this.precio0_2.valor * 1000) / 1000;
		  	}
			break;
		}

		// TAMAÑO DEL PAPEL POR TIPO DE IMPRESION
		if (this.tipimp == 1) {

			switch (this.tampap) {
		  	  case 1:
		  		if (this.precio1_1.valor != undefined) {
					tam = Math.round(this.precio1_1.valor * 1000) / 1000;
		  		}
				break;
		  	  case 2:
				if (this.precio1_2.valor != undefined) {
					tam = Math.round(this.precio1_2.valor * 1000) / 1000;
				}
				break;
		  	  case 3:
				if (this.precio1_3.valor != undefined) {
					tam = Math.round(this.precio1_3.valor * 1000) / 1000;
				}
				break;
			}
		}
		if (this.tipimp == 2) {

			switch (this.tampap) {
		  	  case 1:
		  		if (this.precio3_1.valor != undefined) {
					tam = Math.round(this.precio3_1.valor * 1000) / 1000;
		  		}
				break;
		  	  case 2:
				if (this.precio3_2.valor != undefined) {
					tam = Math.round(this.precio3_2.valor * 1000) / 1000;
				}
				break;
		  	  case 3:
				if (this.precio3_3.valor != undefined) {
					tam = Math.round(this.precio3_3.valor * 1000) / 1000;
				}
				break;
			}
		}

		// GRAMAJE DEL PAPEL
		switch (this.grampap) {
		  case 1:
			if (this.precio2_1.valor != undefined) {
				gram = Math.round(this.precio2_1.valor * 1000) / 1000;
			}
			break;
		  case 2:
			if (this.precio2_2.valor != undefined) {
				gram = Math.round(this.precio2_2.valor * 1000) / 1000;
			}
			break;
		}

		// PORTADAS A COLOR
		if (this.portadasacolor) {
			if (this.precio4_1.valor != undefined) {
				portcolor = Math.round(this.precio4_1.valor * 1000) / 1000;
			}
		}

		// FORMA DE IMPRESION
		switch (this.formimp) {
		  case 1:
			if (this.precio20_1.valor != undefined) {
				fimp = Math.round(this.precio20_1.valor * 1000) / 1000;
			}
			break;
		  case 2:
			if (this.precio20_2.valor != undefined) {
				fimp = Math.round(this.precio20_2.valor * 1000) / 1000;
			}
			break;
		}

		// PAGINAS POR CARA
		if (this.paginasporcara == 0) { this.paginasporcara = 1; }
		switch (this.paginasporcara) {
		  case 1:
			if (this.precio5_1.valor != undefined) {
				pagporcara = Math.round(this.precio5_1.valor * 1000) / 1000;
			}
			break;
		  case 2:
			if (this.precio5_2.valor != undefined) {
				pagporcara = Math.round(this.precio5_2.valor * 1000) / 1000;
			}
			break;
		  case 4:
			if (this.precio5_3.valor != undefined) {
				pagporcara = Math.round(this.precio5_3.valor * 1000) / 1000;
			}
			break;
		}

		// ACABADO
		switch (this.acabado) {
		  case 1:
			if (this.precio8_1.valor != undefined) {
				acabado = Math.round(this.precio8_1.valor * 1000) / 1000;
			}
			break;
		  case 2:
			if (this.precio8_2.valor != undefined) {
				acabado = Math.round(this.precio8_2.valor * 1000) / 1000;
			}
			break;
		  case 3:
			if (this.precio8_8.valor != undefined) {
				acabado = Math.round(this.precio8_8.valor * 1000) / 1000;
			}
			if (this.precio8_9.valor != undefined) {
				encuadporpag = Math.round(this.precio8_9.valor * 1000) / 1000;
			}
			break;
		}

		// CALCULOS
		var T = (this.pags / this.paginasporcara);

		// (T x Pb)
		let T1 = (T * tim);

		// (T x Tp)
		let T2 = (T * tam);

		// (T x Fi)
		let T3 = (T * fimp);

		// (T x Gp)
		let T4 = (T * gram);

		// (T x Pe)
		let T5 = (T * encuadporpag);
      
		this.total = T1 + T2 + T3 + T4 + pagporcara + portcolor + orientacion + acabado + T5;
		this.total = Math.round((this.total) * 1000) / 1000;

		////total pedido
		this.totalp = (this.total * this.copias);
		this.totalp = Math.round((this.totalp) * 1000) / 1000 ;  

	}
	else {
		this.totalp = 0;
	}

	//corregir cantidades
	this.total = this.cartService.correct(this.total);
	this.totalp = this.cartService.correct(this.totalp);
  }


  settampap(n: number) {
    this.tampap = n;
    if (this.productopedido != null) {
      this.productopedido.pagesizes_id = n;
    }
    if (this.archivos.length > 0) {
      this.actualizarprecio();
    }
  }

  setgrampap(n: number) {
    this.grampap = n;
    if (this.productopedido != null) {
      this.productopedido.paperweights_id = n;
    }
    if (this.archivos.length > 0) {
      this.actualizarprecio();
    }
  }

  setprintform(n: number) {
    this.formimp = n;
    if (this.productopedido != null) {
      this.productopedido.printform_id = n;
    }
    if (this.archivos.length > 0) {
      this.actualizarprecio();
    }
  }

  settipimp(n: number) {
    this.tipimp = n;
    if (this.productopedido != null) {
      this.productopedido.papercolors_id = n;
    }
    if (this.archivos.length > 0) {
      if (this.tipimp == 2) {
        this.portadasacolor = false;
      }
      this.actualizarprecio();
    }
  }

  setportadasacolor() {
    this.portadasacolor = !this.portadasacolor;
    if (this.productopedido != null) {
      this.productopedido.covercolor = this.portadasacolor == true ? "T" : "F";
    }
    if (this.archivos.length > 0) {
      this.actualizarprecio();
    }
  }

  setpaginasporcara(n: number) {
    this.paginasporcara = n;
    if (this.productopedido != null) {
      this.productopedido.pagesheets_id = n;
    }
    if (this.archivos.length > 0) {
      this.actualizarprecio();
    }
  }

  setrotacionauto() {
    this.rotacionauto = !this.rotacionauto;
    this.setpaginasporcara(1);
    if (this.productopedido != null) {
      this.productopedido.autorotate = this.rotacionauto == true ? "T" : "F";
    }
    if (this.archivos.length > 0) {
      this.actualizarprecio();
    }
  }

  setorientacionpapel(n: number) {
    this.orientacionpapel = n;
    if (this.productopedido != null) {
      this.productopedido.pageorientations_id = n;
    }
    if (this.archivos.length > 0) {
      this.actualizarprecio();
    }
  }

  setacabado(n: number) {
    this.acabado = n;
    if (this.productopedido != null) {
      this.productopedido.finishings_id = n;
    }
    if (this.archivos.length > 0) {
      this.actualizarprecio();
    }
    if (n == 3) {
      this.setanillascolor(1);
      this.settapatraseracolor(1);
      this.settapadelanteracolor(1);
    }
  }

  setencuaderdardocs(n: number) {
    this.encuadernardocs.forEach((el: EncuadernardocsModel) => {
      if (el.id == n) {
        this.encuadernadoc = el.nombre;
        this.encuad = n;
      }
    });
    if (this.productopedido != null) {
      this.productopedido.encuadernardocs_id = n;
    }
    if (this.archivos.length > 0) {
      this.actualizarprecio();
    }
  }

  setcolores(n: number) {
    this.colores = n;
    switch (n) {
      case 1:
        this.veranillas = true;
        this.vertapat = false;
        this.vertapad = false;
        break;
      case 2:
        this.veranillas = false;
        this.vertapat = false;
        this.vertapad = true;
        break;
      case 3:
        this.veranillas = false;
        this.vertapat = true;
        this.vertapad = false;
        break;
    }
  }

  setanillascolor(n: number) {
    this.coloreshexa.forEach((el: ColoresModel) => {
      if (el.id == n) {
        this.anillascolor = el.nombre;
        this.anillacol = n;
      }
    });
    if (this.productopedido != null) {
      this.productopedido.anillascolor_id = n;
    }
    if (this.archivos.length > 0) {
      this.actualizarprecio();
    }
  }

  settapatraseracolor(n: number) {
    this.coloreshexa.forEach((el: ColoresModel) => {
      if (el.id == n) {
        this.tapatraseracolor = el.nombre;
        this.tapatcol = n;
      }
    });
    if (this.productopedido != null) {
      this.productopedido.tapatrasera_id = n;
    }
    if (this.archivos.length > 0) {
      this.actualizarprecio();
    }
  }

  settapadelanteracolor(n: number) {
    this.coloreshexa.forEach((el: ColoresModel) => {
      if (el.id == n) {
        this.tapadelanteracolor = el.nombre;
        this.tapadcol = n;
      }
    });
    if (this.productopedido != null) {
      this.productopedido.tapadelantera_id = n;
    }
    if (this.archivos.length > 0) {
      this.actualizarprecio();
    }
  }

  getTipoFile(name) {
    let tip = null;
    this.tipofiles.forEach((el) => {
      let ext = name.split(".")[1];
      if (el.nombre.includes(ext)) {
        tip = el.id;
      }
    });
    return tip;
  }

  getHisto() {
    if (this.cliente != null) {
      this.cartService
        .getHistoricopedidos(this.cliente.id)
        .subscribe((resp: HistoricoModel[]) => {
          if (resp) {
            this.histo = resp;
          }
        });
    }
  }
}
