import { StringifyOptions } from 'querystring';
import { NodeCompatibleEventEmitter } from 'rxjs/internal/observable/fromEvent';


export class  FacturasModel{
    id: number;
    pedidos_id: number;
    fecha: string;
    importe: string;
    iva: string;
    metodopagos_id: string;
    metodopagos: string;
    numero: string;
    year: string;
    numerofactura: string;
    codigodescarga: string;

    direccionfacturacion: string;
    localidadfacturacion: string;
    provinciafacturacion: string;
    cpostalfacturacion: string;
    email: string;
    nombre: string; 
    CIFNIF: string; 
    empresa: string; 
    telefono: string; 
    

    
}