import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PreciosModel } from '../models/Precios.model';
import { map} from 'rxjs/operators' ;
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { JsonPipe } from '@angular/common';
import { EntradasModel } from '../models/Entradas.model';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PreciosService {
  userToken: string;
  idUsuario: string;
  url: string;
  apiurl: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.apiurl = environment.apiurl;
  }



  getprecio(id) {
    this.userToken = this.auth.leerToken();
    let endpoint = '/precios/' + id ;
    this.url = this.apiurl + endpoint ;
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.get( `${this.url}`, { headers}  )
    .pipe(map( resp => {
        return resp;
    } ));
  }


  getListaPrecios() {
    this.userToken = this.auth.leerToken();
    let endpoint =  '/precios';
    this.url = this.apiurl + endpoint ;;
  
    return this.http.get( `${this.url}`  )
    .pipe(map( resp => {
        return resp as PreciosModel[];
    } ));
  }



  getPreciosFilt() {
    this.userToken = this.auth.leerToken();
    let endpoint = '/precios/filt' ;
    this.url = this.apiurl + endpoint;
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.get( `${this.url}`, { headers }  )
    .pipe(map( resp => {
        return  resp;
    } ));
  }


  modificarPrecio(valorp: number, idp: number ) {
    this.userToken = this.auth.leerToken();
    let endpoint = '/precios/update' ;
    this.url = this.apiurl + endpoint;
    const filtData = {
      valor: valorp,
      id: idp
    };
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.post( `${this.url}`, filtData,  { headers }  )
    .pipe(map( resp => {
        return  resp;
    } ));
  }
  

}