import { StringifyOptions } from 'querystring';
import { NodeCompatibleEventEmitter } from 'rxjs/internal/observable/fromEvent';
import { ProductospedidosModel } from './Productospedidos.model';


export class CartModel{
    
    
    id: number;
    clientes_id: number;
    notaxamt: number;
    cur: string;
    ship: number;
    taxamt: number;
    totalamt: number;
    total: number;
    totalfinal: number;
    created: Date;
    weight: number;
    acabado: string;
    estado: string;
    estados_id: number;
    cliente: string;
    tipoenvios_id: number;
    metodopagos_id: number;
    totalbono: number;
    

    productospedidos: ProductospedidosModel[];
    currentproduct: string;
    constructor(){
        this.notaxamt = 0;
        this.cur = 'EUR';
        this.ship = 0;
        this.taxamt = 0;
        this.totalamt = 0;
        this.total = 0;
        this.total = 0;
        this.weight = 0;
        this.acabado = 'F'
        this.cliente = "";
        this.currentproduct =  "";
        this.tipoenvios_id =  null;
        this.metodopagos_id =  null;
    }

}