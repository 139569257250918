import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore' ;
import * as firebase  from 'firebase';
import { FilesModel } from 'src/app/models/Files.model';

@Injectable({
  providedIn: 'root'
})
export class CargaimagenesService {

  private CARPETA_DOCS = "doc";
  constructor(
    private db: AngularFirestore
  ) { }

  async cargararchivofirebase(archivo: FilesModel){

    const storageRef = firebase.storage().ref();
      //subida firebase
      const uploadTask: firebase.storage.UploadTask = storageRef.child(`${ this.CARPETA_DOCS }/${ archivo.originalname }`).put(archivo.archivo);
      return await uploadTask;
  }
      //recuperacion datos firebase

      async getarchivofirebase(uploadTask){
      var firebase_res = [];
      if(uploadTask){
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
          () => {
            var snapshot = uploadTask.snapshot;
            if(snapshot.state == "success"){
              snapshot.ref.getDownloadURL().then(function(res){
              //url   
                firebase_res.push(res);
              })   
                //raiz
                firebase_res.push(snapshot.ref.bucket);
                //path
                firebase_res.push(snapshot.ref.fullPath);
            } 
          });
        
          return firebase_res;
      }

  } 
  


}
