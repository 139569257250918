import { NumberParsingError } from 'pdf-lib';
import { StringifyOptions } from 'querystring';
import { NodeCompatibleEventEmitter } from 'rxjs/internal/observable/fromEvent';
import { FilesModel } from '../models/Files.model';

export class ProductospedidosModel{
    
    id: number;
    productos_id: number;
    cart_id: number;
    copias: number;
    description: string;
    pagesizes_id: number;
    paperweights_id: number;
    printform_id: number;
    autorotate: string;
    covercolor: string;
    papercolors_id: number;
    pagesheets_id: number;
    printsides_id: number;
    pageorientations_id: number;
    finishings_id: number;
    encuadernardocs_id: number;
    anillascolor_id: number;
    weight: number;
    tapadelantera_id: number;
    tapatrasera_id: number;
    total: number;
    pagesizes: string;
    printsides: string;
    paperweights: string;
    printform: string;
    papercolors: string;
    pagesheets: string;
    pageorientations: string;
    finishings: string;
    encuadernardocs: string;
    anillascolor: string;
    tapasdelantera: string;
    tapastrasera: string;
    pedidos_id: number;
    files: FilesModel[];

    productostipos_id: number;
    tipobonos_id: number;
    nombrebono: string;
    descripcionbono: string;
    
    constructor(){

    this.productos_id = 0,
    this.cart_id = null,
    this.copias = 0,
    this.description = "",
    this.pagesizes_id = 0,
    this.paperweights_id = 0,
    this.printform_id = 0,
    this.autorotate = null,
    this.covercolor = "",
    this.papercolors_id = 0,
    this.pagesheets_id = 0,
    this.printsides_id = 0,
    this.pageorientations_id = 0,
    this.finishings_id = 0,
    this.encuadernardocs_id = 0,
    this.anillascolor_id = 0,
    this.weight = 0,
    this.tapadelantera_id = 0,
    this.tapatrasera_id = 0,
    this.total = 0,
    this.pagesizes = "",
    this.printsides = "",
    this.paperweights = "",
    this.printform = "",
    this.papercolors = "",
    this.pagesheets = "",
    this.pageorientations = "",
    this.finishings = "",
    this.encuadernardocs = "",
    this.anillascolor = "",
    this.tapasdelantera = "",
    this.tapastrasera = "",
    this.pedidos_id = 0,
    this.files = [];

    this.productostipos_id = 0,
    this.tipobonos_id = 0,
    this.nombrebono = "",
    this.descripcionbono = ""
    }
    
}