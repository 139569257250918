// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiurl: '/api',
  firebase : {
    apiKey: "AIzaSyCZvVPuUuSzmFuZNDm4C3WNR9pW4QiFsKM",
    authDomain: "appeasycopy-b07c8.firebaseapp.com",
    projectId: "appeasycopy-b07c8",
    storageBucket: "appeasycopy-b07c8.appspot.com",
    messagingSenderId: "882539763758",
    appId: "1:882539763758:web:fa297138b5ab25e59be7bf"
  }
};


// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

// Initialize Firebase
//firebase.initializeApp(firebaseConfig);