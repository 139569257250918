import { Component, OnInit, OnDestroy, Output, Input, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ClientesModel } from 'src/app/models/Clientes.model';
import { FiltrosModel } from 'src/app/models/Filtros.model';
import { ProductosService } from '../../services/productos.service';
import { BonosService } from '../../services/bonos.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable } from 'rxjs';
import { ListasService } from '../../services/listas.service';
import { AlertasService } from '../../services/alertas.service';
import { CartService } from '../../services/cart.service';
import { ClientesService } from '../../services/clientes.service';
import { ProductoModel } from 'src/app/models/Producto.model';
import { ProductospedidosModel } from 'src/app/models/Productospedidos.model';
import { BonosModel } from 'src/app/models/Bonos.model';
import { CartModel } from 'src/app/models/Cart.model';
import { PedidosModel } from 'src/app/models/Pedidos.model';


@Component({
  selector: 'app-listaproductos',
  templateUrl: './listaproductos.component.html',
  styleUrls: ['./listaproductos.component.css']
})
export class ListaproductosComponent implements OnInit {

  @Output() actunum: any = new EventEmitter();

  bonocart: CartModel;
  bonopedido: ProductospedidosModel;
  bono: BonosModel;
  ocultarP: string;
  productos = [];
  bonos: BonosModel[] = [];
  cesta = []; //cesta de localstorage
  udsx = [];
  saldoactual: number = 0;

  indexpp: number = null;
  elid: number = null;



  constructor(
      private router: Router,
      private productosService: ProductosService,
      private cartService: CartService,
      private bonosService: BonosService,
      private clientesService: ClientesService,
      private alertasService: AlertasService
  ) 
  { 

  }

  ngOnInit() {
    this.ocultarP = ""
    //this.getproductos();
    this.getbonos();
    this.getsaldoactual();
   
  }
    

  ngOnDestroy() {

  }

  ocultarmenu() {
    this.ocultarP = "";
  }

  menuP() {
    this.ocultarP == "" ? this.ocultarP = "active" :  this.ocultarP = "" ;
  }

  getproductos() {
    this.productosService.getListaProductos()
    .subscribe( resp => {
        this.productos = Object.values(resp);
        this.productos.forEach( el =>{
          el.uds = 0;
          this.udsx.push(el.uds);
        } );
    });
  }

  getbonos() {
    this.bonosService.getListaBonos()
    .subscribe( (resp: BonosModel[]) => {
        this.bonos = resp;    
    });
  }
  
  getsaldoactual() {
  
    let cliente = this.clientesService.getClienteLocal();
    this.saldoactual = 0;
    this.bonosService.getSaldoactual(cliente.id)
    .subscribe( (resp: BonosModel[]) => {
    
        let saldos = resp;
        resp.forEach((el)=>{
        
          this.saldoactual += parseFloat(el.saldo);
          //guardar saldo en localstorage
          localStorage.setItem('bono', this.saldoactual.toString());
        })    
    });
    
  }



  addcesta(p: ProductoModel){
    //alert("a la cesta el produto id "+p.id+" con uds "+p.uds);
    
    this.productosService.getProducto(p.id).subscribe( resp =>{
      if(resp){
      var producto = Object.values(resp);
      this.cesta.push(producto);
      }
    });

    this.getproductos();
  }

  addbono(b: BonosModel){
    ////debe esstar logueado en la aplicacion
    let cliente = localStorage.getItem('cliente');
    if( cliente != null ){
      //añadir a cart el bono como producto
      this.guardarbono(b);
    }
    else{
      this.alertasService
      .alertaInfo(
        "PARA ADQUIRIR UN BONO DEBE INICIAR SESION",
        "Por favor registrese en la aplicación "
      )
    }

   
    
  }


  getcart() {
    this.bonocart = this.cartService.getCart();
  }

  guardarbono(bono: BonosModel) {
    
      this.getcart();
    
      if (this.bonocart.productospedidos == null) {
        this.bonocart.productospedidos = [];
      }

      this.bonopedido = this.getBonoproducto(bono);
      this.bonocart.created == null ? new Date() : this.bonocart.created;
      this.bonocart.productospedidos.push(this.bonopedido);
      if(this.bonocart.currentproduct == ""){
        this.bonocart.currentproduct = "1";
      }
      this.bonocart.total = 0;
      if (this.bonocart.taxamt == 0) {
        this.bonocart.taxamt = 0.21;
        this.bonocart.cur = "EUR";
      }
      this.bonocart.productospedidos.forEach((el) => {
        this.bonocart.total += (Number)(el.total);


      });
      this.bonocart.totalamt = (Number)(this.bonocart.total) * (Number)(this.bonocart.taxamt);


      this.cartService.saveCart(this.bonocart);
    
      ////emitir cambio numprod o actualizar checkout
      this.actunum.emit();
      
  }


  getBonoproducto(bono: BonosModel) {
    var pp = new ProductospedidosModel();
    
    pp.productostipos_id = 3;
    pp.total = parseFloat(parseFloat(bono.precio).toFixed(2));
    pp.tipobonos_id = bono.id;
    pp.id = this.generateId();


    return pp;
  }

  generateId(): number {
    let aa = new Date().getTime();
    let n: number = parseInt(aa.toString()) * 8;
    return n;
  }

 




  
}
