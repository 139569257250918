import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductoModel } from '../models/Producto.model';
import { EnviosModel } from '../models/Envios.model';
import { map} from 'rxjs/operators' ;
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { JsonPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { FilesModel } from '../models/Files.model';



@Injectable({
  providedIn: 'root'
})
export class EnviosService {
  userToken: string;
  idUsuario: string;
  url: string;
  apiurl: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.apiurl = environment.apiurl;
  }


  guardarEnvio( envio: EnviosModel ) {
    
    let endpoint =  '/envios/create';
    this.url = this.apiurl + endpoint;
    
    const productoData = {
      
      pedidos_id: envio.pedidos_id,
      fecha: envio.fecha,
      direccionenvio: envio.direccionenvio,
      localidadenvio: envio.localidadenvio,
      provinciaenvio: envio.provinciaenvio,
      cpostalenvio: envio.cpostalenvio,
      email: envio.email,
      nombre: envio.nombre,
      ship: envio.ship,
      logisitica: envio.logistica
    
    };
  
    return this.http.post( `${this.url}`, productoData);
  }


  modificarEnvio( envio: EnviosModel ) {
    this.userToken = this.auth.leerToken();
    let endpoint =  '/Envios/modificar' ;
    this.url = this.apiurl + endpoint;
    const productoData = {

      pedidos_id: envio.pedidos_id,
      fecha: envio.fecha,
      direccionenvio: envio.direccionenvio,
      localidadenvio: envio.localidadenvio,
      provinciaenvio: envio.provinciaenvio,
      cpostalenvio: envio.cpostalenvio,
      email: envio.email,
      nombre: envio.nombre,
      ship: envio.ship,
      logisitica: envio.logistica
      

    };

    const headers = new HttpHeaders ({
      'Authorization': this.userToken
    });
    return this.http.post( `${this.url}`, productoData, {headers} );
    
  }


  getEnvio(id) {
    let endpoint =  '/Envios/'+id;
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(map( resp => {
      return Object.values(resp);
    } ));
  }
 

  getListaEnvios() {
    let endpoint =  '/Envios';
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(map( resp => {
      return resp;
    } ));
  }


  getEnviosFilt(fechai :string, fechaf :string) {
    this.userToken = this.auth.leerToken();
    let endpoint = '/envios/filt' ;
    this.url = this.apiurl + endpoint;
    const filtData = {
      fechaini: fechai,
      fechafin: fechaf
    };
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
 
    return this.http.post( `${this.url}`, filtData, {headers} )
    .pipe(map( resp => {
        return  resp;
    } ));
  }


  deleteEnvio(id) {
    this.userToken = this.auth.leerToken();
    let endpoint = '/envios/eliminar/' + id ;
    this.url = this.apiurl +  endpoint ;
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.get( `${this.url}`, { headers }  )
    .pipe(map( resp => {
        return  resp;
    } ));
  }




}

