import { StringifyOptions } from 'querystring';
import { NodeCompatibleEventEmitter } from 'rxjs/internal/observable/fromEvent';

export class FiltrosModel {
    
    fechainicio: string;
    fechafin: string;
    nombre: string;


}