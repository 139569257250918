import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { resetCompiledComponents } from "@angular/core/src/render3/jit/module";
import { FacturasModel } from "src/app/models/Facturas.model";
import { DatoseasycopyModel } from "src/app/models/Datoseasycopy.model";
import { PedidosModel } from "src/app/models/Pedidos.model";
import { ListasService } from "../../services/listas.service";
import { ServiciosService } from "../../services/servicios.service";
import { AlertasService } from "../../services/alertas.service";
import { FacturasService } from "src/app/services/facturas.service";
import { ClientesService } from "src/app/services/clientes.service";
import { CartService } from "src/app/services/cart.service";
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { style } from "@angular/animations";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "app-facturasadmin",
  templateUrl: "./facturasadmin.component.html",
  styleUrls: ["./facturasadmin.component.css"],
})
export class FacturasadminComponent implements OnInit {
  @Output() menuAdmin: EventEmitter<any> = new EventEmitter();

  forma: FormGroup;
  modal: NgbModalRef;
  modalOptions: NgbModalOptions;
  btactivado: boolean;
  isAdmin: boolean;
  pagi: number = 1;

  facturasadmin: FacturasModel[];
  datoseasycopy: DatoseasycopyModel[];
  factura: FacturasModel;

  formaF: FormGroup;
  fechainifiltrosel: string;
  fechafinfiltrosel: string;
  nombrefiltrosel: string;
  btactivadoF;

  idsel;
  pedidos_idsel;
  fechasel: string;
  importesel: string;
  ivasel: string;
  metodopagossel: string;
  metodopagos_idsel: string;
  numerofacturasel: string;
  clienteemailsel: string;
  clientedireccionfacturasel: string;
  clientelocalidadfacturasel: string;
  clienteprovinciafacturasel: string;
  clientecpostalfacturasel: string;
  clientetelefonosel: string;
  clientenombresel: string;
  clienteempresasel: string;
  clienteCIFNIFsel: string;
  loading : boolean;
  
  envio = ["","","",""];
  lista = [];
  rutacodigo: string = "";;

  constructor(
    private ro: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private alertasService: AlertasService,
    private listasService: ListasService,
    private facturasService: FacturasService,
    private clientesService: ClientesService,
    private cartService: CartService
  ) {
    this.loading = false;
    this.btactivado = false;
    this.facturasadmin = [];
    this.factura = new FacturasModel();
    this.menuAdmin.emit();
    this.nombrefiltrosel = "";
    var fechaff = new Date(Date.now());
    this.fechafinfiltrosel =
      fechaff.getFullYear() +
      "-" +
      (fechaff.getMonth() + 1) +
      "-" +
      fechaff.getDate();
    var fechaii = fechaff;
    this.fechainifiltrosel =
      fechaii.getFullYear() +
      "-" +
      fechaii.getMonth() +
      "-" +
      fechaii.getDate();
    this.crearFormularioF();
    this.cambiosFormularioF();
    
  }

  ngOnInit() {
    
    this.getfacturasAdmin(this.fechainifiltrosel, this.fechafinfiltrosel, this.nombrefiltrosel);
    this.getDatosEasycopy();
    
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      centered: true,
      size: 'lg'
    }

    this.btactivadoF = true;
    this.nombrefiltrosel = '';
  }

  getfacturasAdmin(fechai, fechaf, nombreb) {
    this.loading = true;
    this.facturasService.getFacturasFilt(fechai, fechaf, nombreb).subscribe((resp:FacturasModel[]) => {
      this.pagi = 1;
      this.facturasadmin = resp ;
      setTimeout(() => {
        this.loading = false;
        }, 300);
    });
  }

  getDatosEasycopy() {
      
    this.listasService.getDatoseasycopy().subscribe((resp: DatoseasycopyModel[]) => {
      if(resp){
        this.datoseasycopy = resp;
      }
    });
  }

  passConfirmar() {
    return (formGroup: FormGroup) => {
      const fi = formGroup.controls["fechaini"];
      const ff = formGroup.controls["fechafin"];
      let ffi = Date.parse(fi.value);
      let fff = Date.parse(ff.value);

      this.btactivadoF = true;
      ff.setErrors({ no: false });
      if (ffi > fff) {
        this.btactivadoF = false;
        ff.setErrors({ no: true });
      }
    };
  }

  crearFormularioF() {
    this.formaF = this.fb.group(
      {
        fechaini: [this.fechainifiltrosel, [Validators.required]],
        fechafin: [this.fechafinfiltrosel, [Validators.required]],
        nombreb: [this.nombrefiltrosel, [Validators.required]],
      },
      {
        validators: this.passConfirmar(),
      }
    );
  }

  cambiosFormularioF() {
    this.formaF.valueChanges.subscribe((value) => {
      this.fechainifiltrosel = this.formaF.get("fechaini").value;
      this.fechafinfiltrosel = this.formaF.get("fechafin").value;
      this.nombrefiltrosel = this.formaF.get("nombreb").value;
      if (this.formaF.status != "INVALID") {
        this.btactivadoF = true;
      }
    });
  }

  filtrar() {
    this.getfacturasAdmin(this.fechainifiltrosel, this.fechafinfiltrosel, this.nombrefiltrosel);
  }

  filtrarenter(event) {
    var codigo = event.which || event.keyCode;
    if (codigo === 13) {
      this.filtrar();
    }
  }

  open(content, factura: FacturasModel) {
    if (factura != undefined) {
      this.btactivado = true;

      this.fechasel = factura.fecha;
      this.idsel = factura.id;
      this.pedidos_idsel = factura.pedidos_id;
      this.importesel = factura.importe;
      this.ivasel = factura.iva;
      this.metodopagossel = factura.metodopagos;
      this.metodopagos_idsel = factura.metodopagos_id;
      this.clientenombresel = factura.nombre;
      this.clienteemailsel = factura.email;
      this.clientedireccionfacturasel = factura.direccionfacturacion;
      this.clientelocalidadfacturasel = factura.localidadfacturacion;
      this.clienteprovinciafacturasel = factura.provinciafacturacion;
      this.clientecpostalfacturasel = factura.cpostalfacturacion;
      this.clienteempresasel = factura.empresa;
      this.clienteCIFNIFsel = factura.CIFNIF;
      this.clientetelefonosel = factura.telefono;
      this.numerofacturasel = factura.numerofactura;
      this.modal = this.modalService.open(content, this.modalOptions);
    }
  }

  cerrarmodal(content) {
    this.resetear();
    this.modal.dismiss();
  }

  resetear() {
    this.idsel = null;
    this.clientenombresel = "";
    this.fechasel = "";
    this.idsel = null;
    this.pedidos_idsel = null;
    this.importesel = "";
    this.ivasel = "";
    this.metodopagossel = "";
    this.metodopagos_idsel = "";
    this.clientenombresel = "";
    this.clienteemailsel = "";
    this.clientedireccionfacturasel = "";
    this.clientelocalidadfacturasel = "";
    this.clienteprovinciafacturasel = "";
    this.clientecpostalfacturasel = "";
    this.clienteempresasel = "";
    this.clienteCIFNIFsel = "";
    this.clientetelefonosel = "";
    this.numerofacturasel = "";

    this.factura.nombre = null;
    this.factura.fecha = null;
    this.factura.importe = null;
    this.factura.iva = null;
    this.factura.metodopagos = null;
    this.factura.metodopagos_id = null;

    this.factura.cpostalfacturacion = "";
    this.factura.direccionfacturacion = "";
    this.factura.email = "";
    this.factura.localidadfacturacion = "";
    this.factura.nombre = "";
    this.factura.provinciafacturacion = "";
    this.factura.telefono = "";
    this.factura.empresa = "";
    this.factura.CIFNIF = "";
    this.factura.numero = "";
    this.factura.year = "";
    this.factura.numerofactura = "";
  }

  controlnll(res){
    if(res == null || res == undefined){
      res = "";
    }
    return res;
  }

  sacarPDF(pe: FacturasModel) {
    
    this.cartService.getPedidofactura(pe.pedidos_id).subscribe( (resp: PedidosModel)=> {
      
      if(resp){
        let pedido: PedidosModel = resp;
        let docDefinition = this.getDocDefinition(pe, pedido);
        let cre = pdfMake.createPdf(docDefinition);
        cre.open();
      }
      else{
        this.alertasService
            .alertaKO("Factura no encontrada", "debe revisar el pedido "+pe.pedidos_id)
            .then();
      }

    })
  }


  


  zfill(number, width) {
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */ 
    var zero = "0"; /* String de cero */  
    
    if (width <= length) {
        if (number < 0) {
             return ("-" + numberOutput.toString()); 
        } else {
             return numberOutput.toString(); 
        }
    } else {
        if (number < 0) {
            return ("-" + (zero.repeat(width - length)) + numberOutput.toString()); 
        } else {
            return ((zero.repeat(width - length)) + numberOutput.toString()); 
        }
    }  
  }


  getDocDefinition(pe: FacturasModel, pedido: PedidosModel) {

    let fecha = new Date();
    let hoy = fecha.getUTCDate()+"/"+fecha.getUTCMonth()+"/"+fecha.getUTCFullYear();
    let factura = this.controlnll(pe.numerofactura) ;
    this.lista = [];

    //datos easycopy
    let nif_empresa = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('NIF')))[0].dato) ;
    let nombre_empresa = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('nombre')))[0].dato) ;
    let direccion_empresa = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('direccion')))[0].dato) ;
    let mail_empresa = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('mail')))[0].dato) ;
    let telefono_empresa = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('telefono')))[0].dato); 
    let localidad_empresa = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('localidad')))[0].dato); 
    let logo_empresa = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('logo64')))[0].dato); 
    let imagen1 = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('imagen1')))[0].dato); 

    //datos cliente
    let nif_cliente = this.controlnll(pe.CIFNIF) ;
    let nombre_cliente = this.controlnll(pe.nombre);
    let direccion_cliente = this.controlnll(pe.direccionfacturacion) ; 
    let mail_cliente = this.controlnll(pe.email) ;
    let telefono_cliente = this.controlnll(pe.telefono); 
    let localidad_cliente = this.controlnll(pe.localidadfacturacion); 
    let cpostal_cliente = this.controlnll(pe. cpostalfacturacion) ;
    let provincia_cliente = this.controlnll(pe.provinciafacturacion) ; 

    //totales
    let totalneto = pedido.total - pedido.totalamt;
    let totalniva = pedido.totalamt ;
    let totalfactura = pedido.total;

    //cabeceras
    this.lista.push([ 
      { text: 'CÓDIGO', style: 'parrafo' },
      { text: 'CONCEPTO', style: 'parrafo' },
      { text: 'CANT.', style: 'parrafo' },
      { text: 'TOTAL', style: 'parrafo' }
    ])
    //pedido
    pedido.productospedidos.forEach( (el)=>{
       let productotipo = "impresión de documentos";
       if(el.productostipos_id != 1){
        productotipo = "producto de tienda";
       }
       let prodid = 'NNN' + this.zfill(el.id, 6);
       let vals = [ prodid , productotipo, 1, el.total ];
       this.lista.push(vals);
    })
    //envio
    let envioid = 'E'+this.zfill(pedido. tipoenvios_id, 6);
    this.envio = [  envioid, pedido.tipoenvio+" "+ pedido.descenvio, pedido.productospedidos.length.toString(), pedido.precioenvio ];
    this.lista.push(this.envio);
  
    return {
      background: function () {
        return {
          canvas: [
        {
          type: 'rect',
          x: 0, y: 0, w: 595.28, h: 841.89,
          color: '#efefef',
        
        }
      ]
      };
    },
      content: 
      
      [
        
        {
            columns: 
            [
              [
                  {
                    image: logo_empresa,
                    width: 100,
                  }
              ],
              [
                  {
                    text: ""+hoy,
                    style: 'tit1'
                  },
                  {
                    text: "" + factura ,
                    style: 'tit1'
                  },
                  {
                    
                  },
              ] 
            ],
        },
        {
          columns: 
          [
            [{
              text: "",
              style: 'hueco'
            }]
          ]
        },
        {      
          columns:    
          [
            [
                {
                  text: 'DATOS EMPRESA',
                  style: 'parrafo'
                },
                {
                  text: nombre_empresa,
                  style: 'parrafo'
                },
                {
                  text: nif_empresa,
                  style: 'parrafo'
                },
                {
                  text: direccion_empresa,
                  style: 'parrafo'
                },
                {
                  text: localidad_empresa,
                  style: 'parrafo'
                },
                {
                  text: mail_empresa,
                  style: 'parrafo'
                },
                {
                  text: telefono_empresa,
                  style: 'parrafo'
                }
            ],
            [
                {
                  text: 'DATOS CLIENTE',
                  style: 'parrafo'
                },
                {
                  text: 'NOMBRE '+ nombre_cliente,
                  style: 'parrafo'
                },
                {
                  text: 'CIF/DNI ' + nif_cliente,
                  style: 'parrafo'
                },
                {
                  text: 'DIRECCIÓN ' + direccion_cliente,
                  style: 'parrafo'
                },
                {
                  text: '' + localidad_cliente,
                  style: 'parrafo'
                },
                {
                  text: '' + provincia_cliente,
                  style: 'parrafo'
                },
                {
                  text: 'TLF'+ telefono_cliente,
                  style: 'parrafo'
                }
            ]
          ],
        },
        {
          columns: 
          [
            [{
              text: "",
              style: 'hueco'
            }]
          ]
        },
        {
          style: { color: "#065528" },
          layout: 'noBorders',
          table: {
            
            headerRows: 1,
            widths: [ '*' , '50%', '*' , '*' ],
    
            body: 
            this.lista
          }
        },
        {
          columns: 
          [
            [{
              text: "",
              style: 'hueco'
            }]
          ]
        },
        {
          style: { color: "#065528" },
          layout: 'noBorders',
          table: {
            headerRows: 1,
            widths: [ '*' , '*' ,'50%', '*' ],
            body: 
            [
              [
              {
                text: '',
                style: ''
              },             
              {
                text: '',
                style: ''
              },             
              {
                text: 'TOTAL NETO',
                style: 'parrafo'
              },
              {
                text: totalneto,
                style: ''
              }
              ],
              [
              {
                text: '',
                style: ''
              },             
              {
                text: '',
                style: ''
              },             
              {
                text: 'TOTAL IVA(21%)',
                style: 'parrafo'
              },
              {
                text: totalniva,
                style: ''
              },
              ],
              [
              {
                text: '',
                style: ''
              },             
              {
                text: '',
                style: ''
              },             
              {
                text: 'TOTAL FACTURA',
                style: 'parrafo'
              },
              {
                text: totalfactura,
                style: 'parrafo'
              },
              ],
            ],
          }
        },
        {
          columns: 
          [
            [
                {
                  text: '',
                  style: ''
                },
  
            ],
            [
                {
                  text: '',
                  style: ''
                },
  
            ],
            [
                {
                  text: '',
                  style: ''
                },
  
            ],
            [
              
                {
                  image: imagen1,
                  width: 130,
                }
            ]
          ],
      },
        {
          columns: 
          [
            [{
              text: "",
              style: 'hueco'
            }]
          ]
        },
        {      
          columns:    
          [
            [
                {
                  text: 'FECHA',
                  style: 'parrafo'
                }
            ],
            [
                {
                  text: 'WEB',
                  style: 'parrafo'
                }
            ],
            [
                {
                  text: 'MAIL',
                  style: 'parrafo'
                },
            ],
          ],
        },
        {      
          columns:    
          [
            [
                {
                  text: hoy,
                  style: 'parrafo'
                }
            ],
            [
                {
                  text: 'https://easycopy.es',
                  style: 'parrafo'
                }
            ],
            [
                {
                  text: 'hola@easycopy',
                  style: 'parrafo'
                },
            ]
          ],
        },
        {
          columns: 
          [
            [{
              text: "",
              style: 'hueco'
            }]
          ]
        },
        {
          style: {
              margin: [0,0,200,0]
          },
          columns: 
          [
            [{
              text: "De acuerdo a la ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, EasyCopy garantiza la confidencialidad de los dato personales de sus clientes. Ahora sus datos forman parte de su base de datos con la única finalidad de prestarle los servicios que ha solicitado. Si desea conocer o ejercitar sus derechos, diríjase por correo electrónico a hola@easycopy.es",
              style: 'parrafoP'
            }]
          ]
        },
      ],
      styles: {
        fondo: {
          background: '#efefef'
        },
        tit1: {
          margin: [0, 1, 0, 1],
          fontSize: 24,
          color: '#065528',
          alignment: 'right',
        },
        parrafo: {
          margin: [0, 1, 0, 1],
          alignment: 'left',
          fontSize: 14,
          color: '#065528',
        },
        parrafoP: {
          margin: [0, 0, 0, 0],
          alignment: 'left',
          fontSize: 9,
          color: '#065528'
        },
        hueco: {
          margin: [0, 0, 0, 20]
        }
      }
    };


  }
}
