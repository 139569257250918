import { Component, OnInit } from '@angular/core';
import { ClientesModel } from '../../models/Clientes.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ListasService } from '../../services/listas.service';
import { AlertasService } from '../../services/alertas.service';
import { ClientesService } from '../../services/clientes.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {
  

  modal: NgbModalRef;
  modalOptions: NgbModalOptions;
  forma: FormGroup;
  cliente: ClientesModel;
  btactivado;
  emailregistsel: string;
  passwordregistsel: string;
  password2registsel: string;


  validoNombre: boolean = false;
  validoPassw: boolean = false;
  validoCpostal: boolean = false;
  validoDireccion: boolean = false;
  validoTelefono: boolean = false;
  validoLocalidad: boolean = false;
  validoProvincia: boolean = false;
  validoVia: boolean = false;
  validoNumero: boolean = false;
  validoPiso: boolean = false;
  validoEmail: boolean = false;


  constructor(
    private auth: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private alertasService: AlertasService,
    private clientesService: ClientesService
  ) 
  { 
    this.crearFormulario();
    this.cambiosFormulario();
  }

  ngOnInit() {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      centered: true,
      size: 'lg'
    }

    this.cliente = new ClientesModel();
    this.btactivado = false;
    this.resetear();
  }

  //validaciones rojo
  get novalidoNombre() { return this.forma.get('nombreregist').invalid && this.forma.get('nombreregist').touched ; }
  get novalidoEmail() { return this.forma.get('emailregist').invalid && this.forma.get('emailregist').touched ; }
  get novalidoPassw() { return this.forma.get('passwordregist').invalid && this.forma.get('passwordregist').touched ; }
  get novalidoCpostal() { return this.forma.get('cpostalregist').invalid && this.forma.get('cpostalregist').touched ; }
  get novalidoDireccion() { return this.forma.get('direccionregist').invalid && this.forma.get('direccionregist').touched ; }
  get novalidoTelefono() { return this.forma.get('telefonoregist').invalid && this.forma.get('telefonoregist').touched ; }
  get novalidoLocalidad() { return this.forma.get('localidadregist').invalid && this.forma.get('localidadregist').touched ; }
  get novalidoProvincia() { return this.forma.get('provinciaregist').invalid && this.forma.get('provinciaregist').touched ; }
  get novalidoVia() { return this.forma.get('viaregist').invalid && this.forma.get('viaregist').touched ; }
  get novalidoNumero() { return this.forma.get('numeroregist').invalid && this.forma.get('numeroregist').touched ; }
  get novalidoPiso() { return this.forma.get('pisoregist').invalid && this.forma.get('pisoregist').touched ; }


  
  get novalidoPassw2() { 
    const pass1 = this.forma.get('passwordregist').value;
    const pass2 = this.forma.get('password2regist').value;
    return (pass1 === pass2) ? false : true ;
   //&& this.forma.get('passw2').touched ; 
  }

  passConfirmar(pa1 :string, pa2 :string) {
      return ( formGroup: FormGroup ) => {
          const pss1 = formGroup.controls[pa1];
          const pss2 = formGroup.controls[pa2];
          if ( pss1.value == pss2.value ) {
            pss2.setErrors(null);
          }
          else{
            pss2.setErrors({ no: true});
          }
      }
  }


  crearFormulario() {
    this.forma = this.fb.group({
      nombreregist: ['', [Validators.required, Validators.minLength(1)]],
      emailregist: [ '' , [Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      passwordregist: ['', [Validators.required, Validators.minLength(1)]],
      password2regist: ['', [Validators.required, Validators.minLength(1)]],
      cpostalregist: [ '' , [Validators.required, Validators.pattern(/^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/)]],
      direccionregist: ['', [Validators.required]],
      viaregist: ['', [Validators.required]],
      numeroregist: ['', [Validators.required]],
      puertaregist: [''],
      pisoregist: ['', [Validators.required]],
      telefonoregist: [ '' , [Validators.required,Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,5}$/)  ]],
      provinciaregist: ['', [Validators.required]],
      localidadregist: ['', [Validators.required]],
      empresaregist: [''],
      paisregist: [''],
      CIFNIFregist: [ '' , [Validators.pattern(/^((([X-Z])|([LM])){1}([-]?)((\d){7})([-]?)([A-Z]{1}))|((\d{8})([-]?)([A-Z]))$/)]],

    },
    {
      validators: this.passConfirmar('passwordregist', 'password2regist')
    });
  }




  cambiosFormulario() {

    ///// statuChanges
    this.forma.valueChanges.subscribe( value => {
        // this.btactivado = false;
        this.cliente.nombre  = this.forma.get('nombreregist').value ;
        this.cliente.email  = this.forma.get('emailregist').value ;
        this.cliente.password = this.forma.get('passwordregist').value ;
        this.cliente.password2 = this.forma.get('password2regist').value ;
        this.cliente.telefono = this.forma.get('telefonoregist').value ;
        this.cliente.cpostalenvio = this.forma.get('cpostalregist').value ;
        this.cliente.cpostalfacturacion = this.forma.get('cpostalregist').value ;
        this.cliente.direccionenvio = this.forma.get('direccionregist').value ;
        this.cliente.viaenvio = this.forma.get('viaregist').value ;
        this.cliente.viafacturacion = this.forma.get('viaregist').value ;
        this.cliente.numeroenvio = this.forma.get('numeroregist').value ;
        this.cliente.puertaenvio = this.forma.get('puertaregist').value ;
        this.cliente.numerofacturacion = this.forma.get('numeroregist').value ;
        this.cliente.puertafacturacion = this.forma.get('puertaregist').value ;
        this.cliente.pisoenvio = this.forma.get('pisoregist').value ;
        this.cliente.pisofacturacion = this.forma.get('pisoregist').value ;
        this.cliente.direccionfacturacion = this.forma.get('direccionregist').value ;
        this.cliente.localidadenvio = this.forma.get('localidadregist').value ;
        this.cliente.localidadfacturacion = this.forma.get('localidadregist').value ;
        this.cliente.provinciaenvio = this.forma.get('provinciaregist').value ;
        this.cliente.provinciafacturacion = this.forma.get('provinciaregist').value ;
        this.cliente.empresa = this.forma.get('empresaregist').value ;
        this.cliente.CIFNIF = this.forma.get('CIFNIFregist').value ;
        this.cliente.pais = this.forma.get('paisregist').value ;

        this.controlvalids();

        if ( this.forma.status != "INVALID") {
          this.btactivado = true ;
        }

    });
  }

  controlvalids(){

    if(this.forma.controls['nombreregist'].status == "VALID"){
      this.validoNombre = false;
    }
    if(this.forma.controls['cpostalregist'].status == "VALID"){
      this.validoCpostal = false;
    }
    if(this.forma.controls['direccionregist'].status == "VALID"){
      this.validoDireccion = false;
    }
    if(this.forma.controls['telefonoregist'].status == "VALID"){
      this.validoTelefono = false;
    }
    if(this.forma.controls['localidadregist'].status == "VALID"){
      this.validoLocalidad = false;
    }
    if(this.forma.controls['provinciaregist'].status == "VALID"){
      this.validoProvincia = false;
    }
    if(this.forma.controls['viaregist'].status == "VALID"){
      this.validoVia = false;
    }
    if(this.forma.controls['numeroregist'].status == "VALID"){
      this.validoNumero = false;
    }
    if(this.forma.controls['pisoregist'].status == "VALID"){
      this.validoPiso = false;
    }
    if(this.forma.controls['emailregist'].status == "VALID"){
      this.validoEmail = false;
    }

  }


  registrar() {
      if (!this.forma.invalid) {
        //comprobar que no existe
        this.clientesService.searchCliente(this.cliente.email).subscribe((resp)=>{
        let encontrado = resp as string;
          if(encontrado == 'F'){
            
            this.clientesService.registrarCliente(this.cliente)
            .subscribe( resp => {
              if(resp){
                let clienteresp = resp as ClientesModel;
                clienteresp.password = "*";
                this.cerrarmodalregist();
                this.clientesService.saveCliente(clienteresp);
                this.clientesService.getClienteLocal();
                this.alertasService.alertaOK("USUARIO REGISTRADO","puede entrar en la app")
                .then( this.router.navigateByUrl('/regist') );
              }
            }, (err) => {
              this.alertasService.alertaKO("NO REGISTRADO","compruebe el formulario").then();
            });
          }
          else{
            this.alertasService.alertaKO("YA REGISTRADO","elija otro email").then();
          }
        })

        
      }
  }

  cerrarmodalregist(){
    this.resetear();
    this.modal.dismiss();
  }

  resetear(){
    this.emailregistsel = "";
    this.passwordregistsel = "";
    this.password2registsel = "";

  }

  mostrarvalids(){
      
    this.validoNombre= true; 
    this.validoPassw= true; 
    this.validoCpostal= true; 
    this.validoDireccion= true; 
    this.validoTelefono= true; 
    this.validoLocalidad= true; 
    this.validoProvincia= true; 
    this.validoVia= true; 
    this.validoNumero= true; 
    this.validoPiso= true; 
    this.validoEmail= true; 
  
  }

registro() {
      document.getElementById('btmodalregist').click();
  }

  registopen(content) {
    this.modal = this.modalService.open(content, this.modalOptions);
  }

  


}
