import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientesModel } from '../models/Clientes.model';
import { UsuarioModel } from '../models/Usuario.model';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CartModel } from '../models/Cart.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userToken: string;
  url: string;
  apiurl: string;
  rolcliente: boolean;
  cambiarMenu = new Subject<string>();
  cambiarMenuObservable = this.cambiarMenu.asObservable();
  

  constructor(
    private http: HttpClient,
    private router: Router
  )
  {
      this.apiurl = environment.apiurl;
      this.userToken = this.leerToken();
      
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('cliente')
    localStorage.removeItem('isadmin');
    localStorage.removeItem('saludo');
    this.userToken = "";
    this.router.navigateByUrl('/homecliente');
  }


  ///////////////////////login
  login(usuario: UsuarioModel) {
    let endpoint = '/signin' ;
    this.url = this.apiurl + endpoint;
    const authData = { email: usuario.email, passw: usuario.passw };
  
    return this.http.post( `${this.url}`, authData
    ).pipe(map( resp => {
    
           this.guardarToken(resp['apitoken'], resp['isadmin']);

           return resp;
    }) );
  }


  /////////////////////registro
  nuevoUsuario(cliente: ClientesModel) {
      let endpoint =  '/regist'; 
      this.url = this.apiurl + endpoint ;
      
      const authData = {
        nombre: cliente.nombre,
        email: cliente.email,
        password: cliente.password,
        direccion: cliente.direccionenvio,
        telefono: cliente.telefono,

        direccionenvio: cliente.direccionenvio,
        cpostalenvio: cliente.cpostalenvio,
        localidadenvio: cliente.localidadenvio,
        provinciaenvio: cliente.provinciaenvio,
        direccionfacturacion: cliente.direccionfacturacion,
        cpostalfacturacion: cliente.cpostalfacturacion,
        localidadfacturacion: cliente.localidadfacturacion,
        provinciafacturacion: cliente.provinciafacturacion,

        empresa: cliente.empresa,
        CIFNIF: cliente.CIFNIF,
        aceptapublicidad: cliente.aceptapublicidad? "T": "F",
        aceptapoliticas: "T"
      };
      
      return this.http.post(`${this.url}`, authData);

}


  private guardarToken(apitoken: string, isadmin: string) {
    localStorage.setItem('token', apitoken);
    localStorage.setItem('isadmin', isadmin == null ? 'F': isadmin);
    localStorage.setItem('saludo', '');
    this.userToken = apitoken;
  }


  leerCart() {
    if (localStorage.getItem('cart')) {
      var cart = JSON.parse(localStorage.getItem('cart'));
      return cart  ;
    }
  }


  leerToken() {
      if (localStorage.getItem('token')) {
          this.userToken = localStorage.getItem('token');
          if (this.noAuth()) {
              this.router.navigateByUrl('/homecliente');
          }
          return this.userToken  ;
      }
      else {
          this.router.navigateByUrl('/homecliente');
      }
  }

  leerIdUsuario() {
    if (localStorage.getItem('cliente')) {
      let cliente = localStorage.getCliente();
      let id = '';
      if(cliente.id != 0){
        id = cliente.id;
      }
      return id;
    }
    else {
        return  '';
    }
  }
  
  leerNombre() {
    if (localStorage.getItem('cliente')) {
        let cliente = localStorage.getCliente();
        let nom = '';
        if(cliente.nombre != ''){
          nom = cliente.nombre;
        }
        return nom;
    }
    else {
        return  '';
    }
  }

  leerIsAdmin() {
    if (localStorage.getItem('isadmin')) {
        var leerToken = localStorage.getItem('isadmin');
        return leerToken;
    }
    else {
        return  '';
    }
  }

  leerCliente() {
    if (localStorage.getItem('cliente')) {
      var cli = JSON.parse(localStorage.getItem('cliente'));
      return cli  ;
    }
  }


    noAuth(): boolean {
    let noesta = true;
    if( this.userToken.length > 2  && this.nocaduc(this.userToken) ) { noesta = false;  }
    return noesta;
    }

    nocaduc(tok) {
      let endpoint = '/nocaduc' ;
      this.url = this.apiurl + endpoint;
      const authData = { token: tok };
      return this.http.post( `${this.url}`, authData
      ).pipe(map( (resp: Boolean) => {
          return resp;
        })
      );
    }

    forbidd(emailusuario) {
      let endpoint = '/codigomail' ;
      this.url = this.apiurl + endpoint;
      const authData = { 
        email: emailusuario
      };
      return this.http.post( `${this.url}`, authData)
      .pipe(map( resp => {
        return resp;
      }) );

    }

    newpass(cliente: ClientesModel) {
      let endpoint = '/newpass' ;
      this.url = this.apiurl + endpoint;
  
      const authData = { email: cliente.email, password: cliente.password , codigomail: cliente.codigomail};
      return this.http.post( `${this.url}`, authData
      ).pipe(map( resp => {
          //this.guardarToken(resp['apitoken'], resp['isadmin'])
          return resp;
      }) );
    }


}
