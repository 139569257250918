import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ClientesModel } from '../models/Clientes.model';
import { map} from 'rxjs/operators' ;
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { JsonPipe } from '@angular/common';
import { jsonpCallbackContext } from '@angular/common/http/src/module';
import { routerNgProbeToken } from '@angular/router/src/router_module';



@Injectable({
  providedIn: 'root'
})

export class ClientesService {

  userToken: string;
  idUsuario: string;
  url: string;
  apiurl: string;

  constructor (
    private http: HttpClient,
    private auth: AuthService
  )
  {
    this.apiurl = environment.apiurl;
  }


  getClientes() {
      this.userToken = this.auth.leerToken();
    

        let endpoint = '/clientes/list' ;
        this.url = this.apiurl + endpoint;
        const headers = new HttpHeaders({ 'Authorization': this.userToken });
        return this.http.get( `${this.url}`, { headers }  )
        .pipe(map( resp => {
            return  resp;
        } ));
      
  }


  getCliente(id) {
      this.userToken = this.auth.leerToken();
      
      if(this.userToken != null){
      let endpoint = '/clientes/'+id ;
      this.url = this.apiurl + endpoint;
      const headers = new HttpHeaders({ 'Authorization': this.userToken });
      return this.http.get( `${this.url}`, { headers }  )
      .pipe(map( resp => {
          return  resp;
      } ));
    }
  }


  searchCliente(mail) {
      
      let endpoint = '/clientes/search/'+mail ;
      this.url = this.apiurl + endpoint;
      return this.http.get( `${this.url}` )
      .pipe(map( resp => {
          return  resp;
      } ));
    
  }


  getClientesFilt(nombr: string) {

    this.userToken = this.auth.leerToken();
    let endpoint = '/clientes/filt' ;
    this.url = this.apiurl + endpoint;
    const filtData = {
      nombre: nombr
    };
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.post( `${this.url}`, filtData,  { headers }  )
    .pipe(map( resp => {
        return  resp;
    } ));
  }


crearCliente(cliente: ClientesModel) {
    this.userToken = this.auth.leerToken();
    let endpoint =  '/clientes/create';
    this.url = this.apiurl + endpoint ;
    //const headers = new HttpHeaders({ 'Authorization': this.userToken });
    const clienteData = {

      nombre: cliente.nombre,
      email: cliente.email,
      telefono: cliente.telefono,
      direccionenvio: cliente.direccionenvio,
      viaenvio: cliente.viaenvio,
      numeroenvio: cliente.numeroenvio,
      puertaenvio: cliente.puertaenvio,
      pisoenvio: cliente.pisoenvio,
      cpostalenvio: cliente.cpostalenvio,
      localidadenvio: cliente.localidadenvio,
      provinciaenvio: cliente.provinciaenvio,
      direccionfacturacion: cliente.direccionfacturacion,
      viafacturacion: cliente.viafacturacion,
      numerofacturacion: cliente.numerofacturacion,
      puertafacturacion: cliente.puertafacturacion,
      pisofacturacion: cliente.pisofacturacion,
      cpostalfacturacion: cliente.cpostalfacturacion,
      localidadfacturacion: cliente.localidadfacturacion,
      provinciafacturacion: cliente.provinciafacturacion,
      empresa: cliente.empresa,
      CIFNIF: cliente.CIFNIF,
      aceptapublicidad: cliente.aceptapublicidad ? "T": "F",
      aceptapoliticas: cliente.aceptapoliticas? "T": "F"

    };
    
    return this.http.post( `${this.url}`, clienteData )
    .pipe(map( resp => {
      return  resp;
  } ));
}


registrarCliente(cliente: ClientesModel) {
    let endpoint =  '/clientes/register';
    this.url = this.apiurl + endpoint ;
    const clienteData = {
      password: cliente.password,
      nombre: cliente.nombre,
      email: cliente.email,
      telefono: cliente.telefono,
      direccionenvio: cliente.direccionenvio,
      viaenvio: cliente.viaenvio,
      numeroenvio: cliente.numeroenvio,
      puertaenvio: cliente.puertaenvio,
      pisoenvio: cliente.pisoenvio,
      cpostalenvio: cliente.cpostalenvio,
      localidadenvio: cliente.localidadenvio,
      provinciaenvio: cliente.provinciaenvio,
      direccionfacturacion: cliente.direccionfacturacion,
      viafacturacion: cliente.viafacturacion,
      numerofacturacion: cliente.numerofacturacion,
      puertafacturacion: cliente.puertafacturacion,
      pisofacturacion: cliente.pisofacturacion,
      cpostalfacturacion: cliente.cpostalfacturacion,
      localidadfacturacion: cliente.localidadfacturacion,
      provinciafacturacion: cliente.provinciafacturacion,
      empresa: cliente.empresa,
      CIFNIF: cliente.CIFNIF,
      aceptapublicidad: cliente.aceptapublicidad ? "T": "F",
      aceptapoliticas: cliente.aceptapoliticas? "T": "F"

      
    };
  
    return this.http.post( `${this.url}`, clienteData );
}


modificarCliente(cliente: ClientesModel) {
    let endpoint =  '/clientes/register';
    this.url = this.apiurl + endpoint ;
    const clienteData = {
      nombre: cliente.nombre,
      email: cliente.email,
      telefono: cliente.telefono,
      direccionenvio: cliente.direccionenvio,
      viaenvio: cliente.viaenvio,
      numeroenvio: cliente.numeroenvio,
      puertaenvio: cliente.puertaenvio,
      pisoenvio: cliente.pisoenvio,
      cpostalenvio: cliente.cpostalenvio,
      localidadenvio: cliente.localidadenvio,
      provinciaenvio: cliente.provinciaenvio,
      direccionfacturacion: cliente.direccionfacturacion,
      viafacturacion: cliente.viafacturacion,
      numerofacturacion: cliente.numerofacturacion,
      puertafacturacion: cliente.puertafacturacion,
      pisofacturacion: cliente.pisofacturacion,
      cpostalfacturacion: cliente.cpostalfacturacion,
      localidadfacturacion: cliente.localidadfacturacion,
      provinciafacturacion: cliente.provinciafacturacion,
      empresa: cliente.empresa,
      CIFNIF: cliente.CIFNIF,
      
    };
  
    return this.http.post( `${this.url}`, clienteData );
}


  deleteClientes(id) {
    this.userToken = this.auth.leerToken();
    let endpoint = '/clientes/eliminar/' + id ;
    this.url = this.apiurl +  endpoint ;
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
    return this.http.get( `${this.url}`, { headers }  )
    .pipe(map( resp => {
        return  resp;
    } ));
  }


  //get cliente de localstorage
  getClienteLocal(){
    var cliente : ClientesModel = new ClientesModel();
    //pasar json de pedido a modelo Pedido
    if (localStorage.getItem('cliente')) {
      var res = localStorage.getItem('cliente');
 
      cliente =  JSON.parse(res) as ClientesModel;
    }
    return cliente;
  }


  saveCliente(cliente: ClientesModel){
    //create update localstorage
    var c = JSON.stringify(cliente);
    localStorage.setItem('cliente', c);

  }


}

