import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { ServiciosService } from "../../services/servicios.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { resetCompiledComponents } from "@angular/core/src/render3/jit/module";
import { ListasService } from "../../services/listas.service";
import { AlertasService } from "../../services/alertas.service";
import { PreciosModel } from "src/app/models/Precios.model";
import { PreciosService } from "src/app/services/precios.service";
import { ClientesService } from "src/app/services/clientes.service";
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-preciosadmin",
  templateUrl: "./preciosadmin.component.html",
  styleUrls: ["./preciosadmin.component.css"],
})
export class PreciosadminComponent implements OnInit {
  @Output() menuAdmin: EventEmitter<any> = new EventEmitter();

  modal: NgbModalRef;
  modalOptions: NgbModalOptions;

  forma: FormGroup;
  btactivado: boolean;
  isAdmin: boolean;
  pagi: number = 1;

  preciosadmin: PreciosModel[];
  precio: PreciosModel;

  idsel;
  pedidos_idsel;
  fechasel: string;
  nombresel: string;
  valorsel: number;
  secciones_idsel: string;
  ordensel: string;
  seccionsel: string;
  precios_idsel: string;
  loading: boolean;

  constructor(
    private ro: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private alertasService: AlertasService,
    private listasService: ListasService,
    private preciosService: PreciosService,
    private clientesService: ClientesService
  ) {
    this.loading = false;
    this.btactivado = false;
    this.preciosadmin = [];
    this.precio = new PreciosModel();
    this.menuAdmin.emit();
    this.valorsel = 0.0;
  }

  ngOnInit() {
    this.getpreciosAdmin();

    this.modalOptions = {
      backdrop: "static",
      backdropClass: "customBackdrop",
      centered: true,
      size: "lg",
    };

    this.crearFormulario();
    this.cambiosFormulario();
  }

  //validaciones rojo
  get novalidoValor() {
    return this.forma.get("valor").invalid && this.forma.get("valor").touched;
  }

  crearFormulario() {
    this.forma = this.fb.group({
      valor: [0, [Validators.required]],
    });
  }

  cambiosFormulario() {
    this.forma.valueChanges.subscribe((value) => {
      this.precio.valor = this.forma.get("valor").value;

      this.btactivado = false;

      if (this.forma.status != "INVALID") {
        this.btactivado = true;
      }
    });
  }

  getpreciosAdmin() {
    this.loading = true;
    this.preciosService.getPreciosFilt().subscribe((resp) => {
      this.pagi = 1;
      this.preciosadmin = resp as PreciosModel[];
      setTimeout(() => {
        this.loading = false;
        }, 300);
    });
  }

  open(content, precio: PreciosModel) {
    if (precio != undefined) {
      this.btactivado = true;

      this.idsel = precio.id;
      this.nombresel = precio.nombre;
      this.valorsel = precio.valor;
      this.secciones_idsel = precio.secciones_id;
      this.ordensel = precio.orden;
      this.seccionsel = precio.seccion;
      this.precios_idsel = precio.precios_id;

      this.modal = this.modalService.open(content, this.modalOptions);
    }
  }

  savePrecio() {
    
    if (!this.forma.invalid && this.btactivado) {
      this.btactivado = false;
      let vlr = this.forma.get('valor').value;
      if (vlr != null) {
        this.preciosService.modificarPrecio( vlr, this.idsel).subscribe((resp)=>{
          if(resp){
            this.resetear();
            this.modal.dismiss();
            this.getpreciosAdmin() ;
          }
        });
      }
    }
  }

  cerrarmodal(content) {
    this.resetear();
    this.modal.dismiss();
  }

  resetear() {
    this.idsel = null;
    this.nombresel = null;
    this.valorsel = null;
    this.secciones_idsel = null;
    this.ordensel = null;
    this.seccionsel = null;
    this.precios_idsel = null;

    this.precio.id = null;
    this.precio.nombre = "";
    this.precio.valor = 0;
    this.precio.secciones_id = "";
    this.precio.orden = "";
    this.precio.seccion = "";
    this.precio.precios_id = "";
  }
}
