import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductoModel } from '../models/Producto.model';
import { EnviosModel } from '../models/Envios.model';
import { map} from 'rxjs/operators' ;
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { JsonPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { FilesModel } from '../models/Files.model';



@Injectable({
  providedIn: 'root'
})
export class TipoenviosService {
  userToken: string;
  idUsuario: string;
  url: string;
  apiurl: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.apiurl = environment.apiurl;
  }


  


  getEnvio(id) {
    let endpoint =  '/tipoenvios/'+id;
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(map( resp => {
      return Object.values(resp);
    } ));
  }
 

  getListaTipoenvios() {
    let endpoint =  '/tipoenvios';
    this.url = this.apiurl + endpoint ;
    return this.http.get( `${this.url}`)
    .pipe(map( resp => {
      return resp;
    } ));
  }


  getEnviosFilt(fechai :string, fechaf :string) {
    this.userToken = this.auth.leerToken();
    let endpoint = '/tipoenvios/filt' ;
    this.url = this.apiurl + endpoint;
    const filtData = {
      fechaini: fechai,
      fechafin: fechaf
    };
    const headers = new HttpHeaders({ 'Authorization': this.userToken });
 
    return this.http.post( `${this.url}`, filtData, {headers} )
    .pipe(map( resp => {
        return  resp;
    } ));
  }



}

