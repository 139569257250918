import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ClientesModel } from '../../models/Clientes.model';
import { UsuarioModel } from '../../models/Usuario.model';
import { PersonasModel } from '../../models/Personas.model';
import { AuthService } from '../../services/auth.service';
import { PersonasService } from '../../services/personas.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AlertasService } from '../../services/alertas.service';
import { ClientesService } from '../../services/clientes.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-seguimiento',
  templateUrl: './seguimiento.component.html',
  styleUrls: ['./seguimiento.component.css']
})
export class  SeguimientoComponent implements OnInit {

  @Input() numpedido: string;

  modal: NgbModalRef;
  modalOptions: NgbModalOptions;

  cliente: ClientesModel;

  constructor(
    private auth: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private clientesService: ClientesService
  ) 
  { 

  }

  ngOnInit() {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      centered: true,
      size: 'lg'
    }
    
    if(this.numpedido != "" ){
      this.seguimiento();
    }
  }


  cerrarmodalseguimiento(){
    this.modal.dismiss();
    this.router.navigate(['/homecliente']);
  }


  seguimiento() {
      document.getElementById('btmodalseguimiento').click();
  }

  seguimientoopen(content) {
    this.modal = this.modalService.open(content, this.modalOptions);
  }



}
