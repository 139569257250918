import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ClientesModel } from 'src/app/models/Clientes.model';
import { FiltrosModel } from 'src/app/models/Filtros.model';
import { ClientesService } from '../../services/clientes.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable } from 'rxjs';
import { ListasService } from '../../services/listas.service';

@Component({
  selector: 'app-clientesadmin',
  templateUrl: './clientesadmin.component.html',
  styleUrls: ['./clientesadmin.component.css']
})
export class ClientesadminComponent implements OnInit {

  @Output() menuAdmin: EventEmitter<any> = new EventEmitter();
  
  modal: NgbModalRef;
  modalOptions: NgbModalOptions;
  pagi: number = 1;
  nombrefiltrosel :string;

  clientesadmin: ClientesModel[];
  cliente: ClientesModel;
  filtros: FiltrosModel;

  
  idsel: string;
  emailsel: string;
  nombresel: string;
  telefonosel: string;
  fechasel: string;
  empresasel: string;
  CIFNIFsel: string;
  direccionenviosel: string;
  viaenviosel: string;
  numeroenviosel: string;
  pisoenviosel: string;
  localidadenviosel: string;
  provinciaenviosel: string;
  cpostalenviosel: string;
  direccionfacturacionsel: string;
  viafacturacionsel: string;
  numerofacturacionsel: string;
  pisofacturacionsel: string;
  localidadfacturacionsel: string;
  provinciafacturacionsel: string;
  cpostalfacturacionsel: string;
  paissel: string;
  public loading: boolean;

  constructor(
      private router: Router,
      private modalService: NgbModal,
      private clientesService: ClientesService
  ) 
  { 
    this.loading = false;
    this.clientesadmin = [];
    this.cliente = new ClientesModel();
    this.menuAdmin.emit();
  }

  ngOnInit() {
   

    this.filtros = new FiltrosModel();
    this.getclientesAdmin(this.nombrefiltrosel);

    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      centered: true,
      size: 'lg'
    }
  }

  ngOnDestroy() {
  }

  getclientesAdmin(nombre) {
    this.loading = true;
    this.clientesService.getClientesFilt(nombre)
    .subscribe( resp => {
  
        this.pagi = 1;
        this.clientesadmin = resp as ClientesModel[];
        setTimeout(() => {
        this.loading = false;
        }, 300);
    });
  }


  filtrar() {
    this.getclientesAdmin(this.nombrefiltrosel);
  }


  open(content, cli: ClientesModel ) {
    this.nombresel = cli.nombre;
    this.emailsel = cli.email;
    this.telefonosel = cli.telefono;
    this.fechasel = cli.fecha;
    this.empresasel = cli.empresa;
    this.CIFNIFsel = cli.CIFNIF;
    this.direccionenviosel = cli.direccionenvio;
    this.viaenviosel = cli.viaenvio;
    this.numeroenviosel = cli.numeroenvio;
    this.pisoenviosel = cli.pisoenvio;
    this.localidadenviosel = cli.localidadenvio;
    this.provinciaenviosel = cli.provinciaenvio;
    this.cpostalenviosel = cli.cpostalenvio;
    this.direccionfacturacionsel = cli.direccionfacturacion;
    this.viafacturacionsel = cli.viafacturacion;
    this.numerofacturacionsel = cli.numerofacturacion;
    this.pisofacturacionsel = cli.pisofacturacion;
    this.localidadfacturacionsel = cli.localidadfacturacion;
    this.provinciafacturacionsel = cli.provinciafacturacion;
    this.cpostalfacturacionsel = cli.cpostalfacturacion;
    this.paissel = cli.pais;
    

    this.modal = this.modalService.open(content, this.modalOptions);
  }

  cerrarmodal(){
    this.resetear();
    this.modal.dismiss();
  }


  resetear(){
    this.idsel = null; 
    this.nombresel = "";
    this.emailsel ="";
    this.telefonosel ="";
    this.empresasel = "";
    this.CIFNIFsel = "";
    this.direccionenviosel = "";
    this.viaenviosel = "";
    this.numeroenviosel = "";
    this.pisoenviosel = "";
    this.localidadenviosel = "";
    this.provinciaenviosel = "";
    this.cpostalenviosel = "";
    this.direccionenviosel = "";
    this.viaenviosel = "";
    this.numeroenviosel = "";
    this.pisoenviosel = "";
    this.localidadfacturacionsel = "";
    this.provinciafacturacionsel = "";
    this.cpostalfacturacionsel = "";
    this.paissel = "";

    this.cliente.id = null;
    this.cliente.nombre = "";
    this.cliente.email ="";
    this.cliente.telefono ="";
    this.cliente.empresa = "";
    this.cliente.CIFNIF = "";
    this.cliente.direccionenvio = "";
    this.cliente.viaenvio = "";
    this.cliente.numeroenvio = "";
    this.cliente.pisoenvio = "";
    this.cliente.localidadenvio = "";
    this.cliente.provinciaenvio = "";
    this.cliente.cpostalenvio = "";
    this.cliente.direccionenvio = "";
    this.cliente.viaenvio = "";
    this.cliente.numeroenvio = "";
    this.cliente.pisoenvio = "";
    this.cliente.localidadfacturacion = "";
    this.cliente.provinciafacturacion = "";
    this.cliente.cpostalfacturacion = "";
    this.cliente.pais = "";

  }


  
}
