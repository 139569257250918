import {
  Component,

  OnInit,

} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { resetCompiledComponents } from "@angular/core/src/render3/jit/module";
import { FacturasModel } from "src/app/models/Facturas.model";
import { DatoseasycopyModel } from "src/app/models/Datoseasycopy.model";
import { PedidosModel } from "src/app/models/Pedidos.model";
import { CartModel } from "src/app/models/Cart.model";
import { ListasService } from "../../services/listas.service";
import { ServiciosService } from "../../services/servicios.service";
import { AlertasService } from "../../services/alertas.service";
import { FacturasService } from "src/app/services/facturas.service";
import { ClientesService } from "src/app/services/clientes.service";
import { CartService } from "src/app/services/cart.service";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

@Component({
  selector: 'app-facturascliente',
  templateUrl: './facturascliente.component.html'
})
export class FacturasclienteComponent implements OnInit {


  datoseasycopy: DatoseasycopyModel[];
  envio = ["","","",""];
  lista = [];
  rutacodigo: string = "";
  factura: FacturasModel;
  pedido: PedidosModel;

  constructor(
    private ro: ActivatedRoute,
    private router: Router,

    private listasService: ListasService,
    private facturasService: FacturasService,
    private clientesService: ClientesService,
    private cartService: CartService

  ) { }

  ngOnInit() {

    this.getDatosEasycopy();
    this.rutacodigo = this.ro.snapshot.paramMap.get('codigo');
    
    if(this.rutacodigo != ""){
       this.sacarPDFCodigo(this.rutacodigo);
    }
    //window.location.href = 'https://easycopy.es';
    
  }

  getDatosEasycopy() {
      
    this.listasService.getDatoseasycopy().subscribe((resp: DatoseasycopyModel[]) => {
      if(resp){
        this.datoseasycopy = resp;
      }
    });
  }


  controlnll(res){
    if(res == null || res == undefined){
      res = "";
    }
    return res;
  }


  sacarPDFCodigo(codigo: string) {
    
    this.facturasService.getFacturaByCodigo(codigo).subscribe( (resp: FacturasModel)=> {
    if(resp != null){
      this.factura  = resp;
      this.cartService.getPedidofactura(this.factura.pedidos_id).subscribe( (resp2: PedidosModel)=> {
      
        if(resp2){
          this.pedido = resp2;
          let docDefinition = this.getDocDefinition(this.factura, this.pedido);
          let cre = pdfMake.createPdf(docDefinition);
          cre.open();
        }
        window.close();
      })
    }
  
  })
}


zfill(number, width) {
  var numberOutput = Math.abs(number); /* Valor absoluto del número */
  var length = number.toString().length; /* Largo del número */ 
  var zero = "0"; /* String de cero */  
  
  if (width <= length) {
      if (number < 0) {
           return ("-" + numberOutput.toString()); 
      } else {
           return numberOutput.toString(); 
      }
  } else {
      if (number < 0) {
          return ("-" + (zero.repeat(width - length)) + numberOutput.toString()); 
      } else {
          return ((zero.repeat(width - length)) + numberOutput.toString()); 
      }
  }
}


getDocDefinition(pe: FacturasModel, pedido: PedidosModel) {

  let fecha = new Date();
  let hoy = fecha.getUTCDate()+"/"+fecha.getUTCMonth()+"/"+fecha.getUTCFullYear();
  let factura = this.controlnll(pe.numerofactura) ;

  this.lista = [];

  //datos easycopy
  let nif_empresa = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('NIF')))[0].dato) ;
  let nombre_empresa = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('nombre')))[0].dato) ;
  let direccion_empresa = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('direccion')))[0].dato) ;
  let mail_empresa = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('mail')))[0].dato) ;
  let telefono_empresa = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('telefono')))[0].dato); 
  let localidad_empresa = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('localidad')))[0].dato); 
  let logo_empresa = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('logo64')))[0].dato); 
  let imagen1 = this.controlnll((this.datoseasycopy.filter( x => x.nombre.includes('imagen1')))[0].dato); 

  //datos cliente
  let nif_cliente = this.controlnll(pe.CIFNIF) ;
  let nombre_cliente = this.controlnll(pe.nombre);
  let direccion_cliente = this.controlnll(pe.direccionfacturacion) ; 
  let mail_cliente = this.controlnll(pe.email) ;
  let telefono_cliente = this.controlnll(pe.telefono); 
  let localidad_cliente = this.controlnll(pe.localidadfacturacion); 
  let cpostal_cliente = this.controlnll(pe. cpostalfacturacion) ;
  let provincia_cliente = this.controlnll(pe.provinciafacturacion) ; 

  //totales
  let totalneto = pedido.total - pedido.totalamt;
  let totalniva = pedido.totalamt ;
  let totalfactura = pedido.total;
  
  //cabeceras
  this.lista.push([ 
    { text: 'CÓDIGO', style: 'parrafo' },
    { text: 'CONCEPTO', style: 'parrafo' },
    { text: 'CANT.', style: 'parrafo' },
    { text: 'TOTAL', style: 'parrafo' }
  ])
  //pedido
  pedido.productospedidos.forEach( (el)=>{
     let productotipo = "impresión de documentos";
     if(el.productostipos_id != 1){
      productotipo = "producto de tienda";
     }
     let prodid = 'NNN' + this.zfill(el.id, 6);
     let vals = [ prodid , productotipo, 1, el.total ];
     this.lista.push(vals);
  })
  //envio
  let envioid = 'E'+this.zfill(pedido. tipoenvios_id, 6);
  this.envio = [  envioid, pedido.tipoenvio+" "+ pedido.descenvio, pedido.productospedidos.length.toString(), pedido.precioenvio ];
  this.lista.push(this.envio);


  return {
    background: function () {
      return {
        canvas: [
      {
        type: 'rect',
        x: 0, y: 0, w: 595.28, h: 841.89,
        color: '#efefef',
      }
    ]
    };
  },
    content: 
    [
      {
          columns: 
          [
            [
                {
                  image: logo_empresa,
                  width: 100,
                }
            ],
            [
                {
                  text: ""+hoy,
                  style: 'tit1'
                },
                {
                  text: "" + factura ,
                  style: 'tit1'
                },
                {
                  
                },
            ] 
          ],
      },
      {
        columns: 
        [
          [{
            text: "",
            style: 'hueco'
          }]
        ]
      },
      {      
        columns:    
        [
          [
              {
                text: 'DATOS EMPRESA',
                style: 'parrafo'
              },
              {
                text: nombre_empresa,
                style: 'parrafo'
              },
              {
                text: nif_empresa,
                style: 'parrafo'
              },
              {
                text: direccion_empresa,
                style: 'parrafo'
              },
              {
                text: localidad_empresa,
                style: 'parrafo'
              },
              {
                text: mail_empresa,
                style: 'parrafo'
              },
              {
                text: telefono_empresa,
                style: 'parrafo'
              }
          ],
          [
              {
                text: 'DATOS CLIENTE',
                style: 'parrafo'
              },
              {
                text: 'NOMBRE '+ nombre_cliente,
                style: 'parrafo'
              },
              {
                text: 'CIF/DNI ' + nif_cliente,
                style: 'parrafo'
              },
              {
                text: 'DIRECCIÓN ' + direccion_cliente,
                style: 'parrafo'
              },
              {
                text: '' + localidad_cliente,
                style: 'parrafo'
              },
              {
                text: '' + provincia_cliente,
                style: 'parrafo'
              },
              {
                text: 'TLF'+ telefono_cliente,
                style: 'parrafo'
              }
          ]
        ],
      },
      {
        columns: 
        [
          [{
            text: "",
            style: 'hueco'
          }]
        ]
      },
      {
        style: { color: "#065528" },
        layout: 'noBorders',
        table: {
          
          headerRows: 1,
          widths: [ '*' , '50%', '*' , '*' ],
  
          body: 
          this.lista
        }
      },
      {
        columns: 
        [
          [{
            text: "",
            style: 'hueco'
          }]
        ]
      },
      {
        style: { color: "#065528" },
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: [ '*' , '*' ,'50%', '*' ],
          body: 
          [
            [
            {
              text: '',
              style: ''
            },             
            {
              text: '',
              style: ''
            },             
            {
              text: 'TOTAL NETO',
              style: 'parrafo'
            },
            {
              text: totalneto,
              style: ''
            }
            ],
            [
            {
              text: '',
              style: ''
            },             
            {
              text: '',
              style: ''
            },             
            {
              text: 'TOTAL IVA(21%)',
              style: 'parrafo'
            },
            {
              text: totalniva,
              style: ''
            },
            ],
            [
            {
              text: '',
              style: ''
            },             
            {
              text: '',
              style: ''
            },             
            {
              text: 'TOTAL FACTURA',
              style: 'parrafo'
            },
            {
              text: totalfactura,
              style: 'parrafo'
            },
            ],
          ],
        }
      },
      {
        columns: 
        [
          [
              {
                text: '',
                style: ''
              },

          ],
          [
              {
                text: '',
                style: ''
              },

          ],
          [
              {
                text: '',
                style: ''
              },

          ],
          [
            
              {
                image: imagen1,
                width: 130,
              }
          ]
        ],
    },
      {
        columns: 
        [
          [{
            text: "",
            style: 'hueco'
          }]
        ]
      },
      {      
        columns:    
        [
          [
              {
                text: 'FECHA',
                style: 'parrafo'
              }
          ],
          [
              {
                text: 'WEB',
                style: 'parrafo'
              }
          ],
          [
              {
                text: 'MAIL',
                style: 'parrafo'
              },
          ],
        ],
      },
      {      
        columns:    
        [
          [
              {
                text: hoy,
                style: 'parrafo'
              }
          ],
          [
              {
                text: 'https://easycopy.es',
                style: 'parrafo'
              }
          ],
          [
              {
                text: 'hola@easycopy',
                style: 'parrafo'
              },
          ]
        ],
      },
      {
        columns: 
        [
          [{
            text: "",
            style: 'hueco'
          }]
        ]
      },
      {
        style: {
            margin: [0,0,200,0]
        },
        columns: 
        [
          [{
            text: "De acuerdo a la ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, EasyCopy garantiza la confidencialidad de los dato personales de sus clientes. Ahora sus datos forman parte de su base de datos con la única finalidad de prestarle los servicios que ha solicitado. Si desea conocer o ejercitar sus derechos, diríjase por correo electrónico a hola@easycopy.es",
            style: 'parrafoP'
          }]
        ]
      },
    ],
    styles: {
      fondo: {
        background: '#efefef'
      },
      tit1: {
        margin: [0, 1, 0, 1],
        fontSize: 24,
        color: '#065528',
        alignment: 'right',
      },
      parrafo: {
        margin: [0, 1, 0, 1],
        alignment: 'left',
        fontSize: 14,
        color: '#065528',
      },
      parrafoP: {
        margin: [0, 0, 0, 0],
        alignment: 'left',
        fontSize: 9,
        color: '#065528'
      },
      hueco: {
        margin: [0, 0, 0, 20]
      }
    }
  };


}




















}
