import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { AlertasService } from '../../services/alertas.service';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-homeadmin',
  templateUrl: './homeadmin.component.html',
  styleUrls: ['./homeadmin.component.css']
})
export class HomeadminComponent implements OnInit {
  
  public chart1: any = null;
  public chart2: any = null;
  public chart3: any = null;
  public chart4: any = null;

  public color_red: string;
  public color_yellow: string;
  public color_blue: string;
  public color_green: string;
  public color_purple: string;
  public color_orange: string;
  public color_gray: string;
  public color_black: string;
  public color_silver: string;

  public colorO_red: string;
  public colorO_yellow: string;
  public colorO_blue: string;
  public colorO_green: string;
  public colorO_purple: string;
  public colorO_orange: string;
  public colorO_gray: string;
  public colorO_black: string;
  public colorO_silver: string;
  loading : boolean;
  
  constructor(
      private router: Router,
      private alertasService: AlertasService
  ) 
  {  
    this.loading = false;
    let sld = localStorage.getItem("saludo");
    if(sld != "saludado"){
    this.alertasService.alertaPeq("Hola, "+ localStorage.getItem("nombre") +" Bienvenido a app easycopy ");
    localStorage.setItem('saludo', "saludado");
    }
  }



  ngOnInit() {
    

    this.color_red = '#ED9388' ;
    this.color_yellow = '#E4EA5E';
    this.color_blue = '#A5E8E8' ;
    this.color_green = '#8BED88' ;
    this.color_purple = '#D7A9E6' ;
    this.color_orange = '#FB9E31' ;
    this.color_gray = '#C5C4C4' ;
    this.color_black = '#5D5D5D' ;
    this.color_silver = '#EEEDED' ;

    this.colorO_red = '#DA897F' ;
    this.colorO_yellow = '#D8DD6C' ;
    this.colorO_blue = '#8AC2C2' ;
    this.colorO_green = '#6DBA6B' ;
    this.colorO_purple = '#9E7CA9' ;
    this.colorO_orange = '#CB8028' ;   
    this.colorO_gray = '#929292' ;
    this.colorO_black = '#3B3B3B' ; 
    this.colorO_silver = '#D6D5D5' ;

    
    
    this.chart1 = new Chart('citasdatos', {
      type: 'line',
      data: {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ],
        datasets: [{
            label: 'Online',
            backgroundColor: this.color_red,
            borderColor: this.colorO_red,
            data: [0, 10, 5, 2, 20, 30, 20, 45, 10, 5, 2, 20]
        },{
          label: 'En sala',
          backgroundColor: this.color_blue,
          borderColor: this.colorO_blue,
          data: [0, 15, 20, 65, 62, 10, 5, 2, 20, 20, 50, 5]
      }]
    },
        options: {
       tooltips: {
        enabled: false
       },
       legend: {
        display: true,
        position: 'bottom',
        labels: {
         fontColor: 'black'
        }
       },
       scales: {
         yAxes: [{
          ticks: {
           fontColor: "black"
          }
         }],
         xAxes: [{
        ticks: {
         fontColor: "black",
         beginAtZero: true
        }
         }]
       }
        }
     });
  


    this.chart2 = new Chart('cajadiariadatos', {
    type: 'line',
    data: {
      labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ],
      datasets: [{
          label: 'Local1',
          backgroundColor: this.color_purple,
          borderColor: this.colorO_purple,
          data: [0, 20, 15, 12, 20, 10, 5, 2, 20, 2, 3, 15]
      },{
        label: 'Local2',
        backgroundColor: this.color_blue,
        borderColor: this.colorO_blue,
        data: [4, 5, 10, 5, 2, 20, 35, 32, 20, 10, 20, 15]
      },{
        label: 'Local3',
        backgroundColor: this.color_green,
        borderColor: this.colorO_green,
        data: [4, 35, 32, 20, 10, 20, 15, 5, 10, 5, 2, 20]
        
    }]
  },
      options: {
     tooltips: {
      enabled: false
     },
     legend: {
      display: true,
      position: 'bottom',
      labels: {
       fontColor: 'black'
      }
     },
     scales: {
       yAxes: [{
        ticks: {
         fontColor: "black"
        }
       }],
       xAxes: [{
      ticks: {
       fontColor: "black",
       beginAtZero: true
      }
       }]
     }
      }
   });

    this.chart3 = new Chart('stockdatos', {
    type: 'line',
    data: {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ],
        datasets: [{
            label: 'local1',
            backgroundColor: this.color_orange,
            borderColor: this.colorO_orange,
            data: [4, 15, 20, 35, 12, 19, 10, 5, 2, 20, 10, 45]
          },{
            label: 'local2',
            backgroundColor: this.color_red,
            borderColor: this.colorO_red,
            data: [4, 19, 10, 5, 15, 20, 35, 12, 2, 20, 10, 45]
          },{
            label: 'local3',
            backgroundColor: this.color_yellow,
            borderColor: this.colorO_yellow,
            data: [4, 15, 20, 10, 5, 2, 20, 10, 45, 35, 12, 19]
       }]
    },
      options: {
     tooltips: {
      enabled: false
     },
     legend: {
      display: true,
      position: 'bottom',
      labels: {
       fontColor: 'black'
      }
     },
     scales: {
       yAxes: [{
        ticks: {
         fontColor: "black"
        }
       }],
       xAxes: [{
      ticks: {
       fontColor: "black",
       beginAtZero: true
      }
       }]
     }
      }
   });

    this.chart4 = new Chart('visitasdatos', {
    type: 'line',
    data: {
      labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ],
      datasets: [{
          label: 'Local 1',
          backgroundColor: this.color_silver,
          borderColor: this.colorO_silver,
          data: [0, 30, 35, 32, 22, 3, 20, 10, 5, 2, 20, 25]
      },{
        label: 'Local 2',
        backgroundColor: this.color_gray,
        borderColor: this.colorO_gray,
        data: [14, 25, 25, 10, 5, 2, 20, 22, 12, 22, 25, 20]
      },{
        label: 'Local 3',
        backgroundColor: this.color_black,
        borderColor: this.colorO_black,
        data: [14, 2, 20, 22, 25, 25, 10, 75, 22, 22, 25, 20]
    }]
  },
      options: {
     tooltips: {
      enabled: false
     },
     legend: {
      display: true,
      position: 'bottom',
      labels: {
       fontColor: 'black'
      }
     },
     scales: {
       yAxes: [{
        ticks: {
         fontColor: "black"
        }
       }],
       xAxes: [{
      ticks: {
       fontColor: "black",
       beginAtZero: true
      }
       }]
     }
      }
   });

 }

}
