import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './pages/shared/navbar/navbar.component';
import { RegistroComponent } from './components/registro/registro.component';
import { HomeclienteComponent } from './pages/homecliente/homecliente.component';
import { CheckoutclienteComponent } from './pages/checkoutcliente/checkoutcliente.component';
import { HistoricoclienteComponent } from './pages/historicocliente/historicocliente.component';
import { HomeadminComponent } from './pages/homeadmin/homeadmin.component';
import { ClientesadminComponent } from './pages/clientesadmin/clientesadmin.component';
import { ListaproductosComponent } from './components/listaproductos/listaproductos.component'
import { SpinnerComponent } from './pages/shared/spinner/spinner.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './components/login/login.component';
import { FooterComponent } from './pages/shared/footer/footer.component';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgDropFilesDirective } from './directives/ng-drop-files.directive';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { environment } from '../environments/environment';
import { CargaimagenesService } from './services/cargaimagenes.service';
import { CarritoComponent } from './components/carrito/carrito.component';
import { NubearribaComponent } from './components/nubearriba/nubearriba.component';
import { NubeabajoComponent } from './components/nubeabajo/nubeabajo.component';
import { CartadminComponent } from './pages/cartadmin/cartadmin.component';
import { HistoricopedidosComponent } from './components/historicopedidos/historicopedidos.component';
import { EnviosadminComponent } from './pages/enviosadmin/enviosadmin.component';
import { FacturasadminComponent } from './pages/facturasadmin/facturasadmin.component';
import { PreciosadminComponent } from './pages/preciosadmin/preciosadmin.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { ForbiddComponent } from './components/forbidd/forbidd.component';
import { SeguimientoComponent } from './components/seguimiento/seguimiento.component';
import { FacturasclienteComponent } from './pages/facturascliente/facturascliente.component';



registerLocaleData( localeEs , 'es');


@NgModule({
  declarations: [
    AppComponent,
    RegistroComponent,
    HomeclienteComponent,
    CheckoutclienteComponent,
    HistoricoclienteComponent,
    ClientesadminComponent,
    ListaproductosComponent,
    SpinnerComponent,
    HomeadminComponent,
    LoginComponent,
    NavbarComponent,
    FooterComponent,
    NgDropFilesDirective,
    CarritoComponent,
    NubearribaComponent,
    NubeabajoComponent,
    CartadminComponent,
    HistoricopedidosComponent,
    EnviosadminComponent,
    FacturasadminComponent,
    PreciosadminComponent,
    PerfilComponent,
    ForbiddComponent,
    SeguimientoComponent,
    FacturasclienteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    
  ],
  providers: [{provide: LOCALE_ID, useValue: 'es'}, CargaimagenesService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
